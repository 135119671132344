import { useCallback, useReducer, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { o } from 'ph-material-pickers/useCanAutoFocus-1eacc580';
import { isNoGqlAuthError } from '../../utils/checkErrorsForWidget';

const reducer = (current, { type, payload }) => {
  switch (type) {
    case 'updateField':
      const {
        idKey,
        data: { id, name, value },
      } = payload;

      return {
        ...current,
        items: current.items.map(item => {
          if (item[idKey] === id) {
            return {
              ...item,
              [name]: value,
            };
          }
          return item;
        }),
      };
    case 'setLoading':
      return {
        ...current,
        error: false,
        loading: payload,
      };
    case 'setData':
      const { items, total } = payload;

      return {
        items,
        total,
        loading: false,
        errors: false,
      };
    case 'onError':
      return {
        ...current,
        loading: false,
        error: true,
      };
    default:
      throw new Error();
  }
};

const useTableDataQuery = (
  query,
  idKey = 'id',
  initialValue = {
    loading: true,
    items: [],
    total: 0,
    error: false,
  },
  fetchPolicy = 'no-cache'
) => {
  const [fetchData, { refetch, error: fetchError }] = useLazyQuery(query, {
    fetchPolicy,
    onCompleted: ({ data } = {}) => {
      setData(data);
    },
    onError: () => {},
  });

  const [{ items, loading, total, error }, dispatch] = useReducer(
    reducer,
    initialValue
  );

  const setLoading = useCallback(
    payload => {
      dispatch({
        type: 'setLoading',
        payload,
      });
    },
    [dispatch]
  );

  const setData = useCallback(
    payload => {
      dispatch({
        type: 'setData',
        payload,
      });
    },
    [dispatch]
  );

  const updateField = useCallback(
    payload => {
      dispatch({
        type: 'updateField',
        payload,
      });
    },
    [dispatch]
  );

  const refetchRef = useRef(() => {});

  const refetchData = useCallback(
    (loader = true) => {
      if (loader) {
        setLoading(true);
      }

      return new Promise((resolve, reject) => {
        return refetchRef
          .current()
          .then(({ data: { data } }) => {
            setData(data);
            resolve(data);
          })
          .catch(err => {
            if (err && isNoGqlAuthError(err)) {
              reject(err);
            }
          });
      });
    },
    [setLoading, setData]
  );

  refetchRef.current = refetch;

  const innerUpdateField = useCallback(
    data => {
      updateField({ idKey, data });
    },
    [idKey, updateField]
  );

  return {
    fetchData,
    refetchData,
    error,
    fetchError,
    items,
    loading,
    total,
    updateField: innerUpdateField,
  };
};

export default useTableDataQuery;
