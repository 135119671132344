import { useCallback, useState } from 'react';
import StringField from '../../components/Fields/StringField/StringField';
import Table from '../../components/Table/Table';
import { useTableBulkContext } from '../../providers/TableBulkProvider';
import TournamentsConfigTable from './TournamentConfigTable';

const commonCellStyle = {
  padding: '0px 16px',
};

export const COLUMN = {
  CATEGORY: 'category',
  ID: 'id',
  SPORT: 'sport',
  TOURNAMENT: 'tournament',
  RM_RULES: 'rules_count',
};

export const columnsWidthConfig = {
  [COLUMN.ID]: 1,
  [COLUMN.SPORT]: '150px',
  [COLUMN.TOURNAMENT]: 1,
  [COLUMN.CATEGORY]: '200px',
  [COLUMN.RM_RULES]: '150px',
};

export const columnsOrderConfig = [
  COLUMN.ID,
  COLUMN.SPORT,
  COLUMN.CATEGORY,
  COLUMN.TOURNAMENT,
  COLUMN.RM_RULES,
];

export const initialToggledColumns = [
  COLUMN.SPORT,
  COLUMN.CATEGORY,
  COLUMN.TOURNAMENT,
  COLUMN.RM_RULES,
];

const TournamentsTable = ({
  toggledColumns: initialToggledColumns,
  order,
  onReorder,
  data,
  loading,
  rowActions,
}) => {
  const bulk = useTableBulkContext();
  const [expandedItems, setExpandedItems] = useState([]);

  const columns = [
    {
      label: 'Id',
      key: COLUMN.ID,
      style: commonCellStyle,
      render: data => {
        return <StringField>{data[COLUMN.ID]}</StringField>;
      },
    },
    {
      label: 'Sport',
      key: COLUMN.SPORT,
      style: commonCellStyle,
      render: data => {
        return <StringField>{data[COLUMN.SPORT]}</StringField>;
      },
    },
    {
      label: 'Category',
      key: COLUMN.CATEGORY,
      style: commonCellStyle,
      render: data => {
        return <StringField>{data[COLUMN.CATEGORY]}</StringField>;
      },
    },
    {
      label: 'Tournament',
      key: COLUMN.TOURNAMENT,
      style: commonCellStyle,
      render: data => {
        return <StringField>{data[COLUMN.TOURNAMENT]}</StringField>;
      },
    },
    {
      label: 'Manual RM Rules',
      key: COLUMN.RM_RULES,
      style: {
        ...commonCellStyle,
        justifyContent: 'center',
        textAlign: 'center',
      },
      render: data => {
        return <StringField>{data[COLUMN.RM_RULES]}</StringField>;
      },
    },
  ];

  const onExpand = useCallback(
    tournamentId => {
      if (expandedItems.includes(tournamentId)) {
        setExpandedItems(expandedItems.filter(id => id !== tournamentId));
      } else {
        setExpandedItems([...expandedItems, tournamentId]);
      }
    },
    [expandedItems, setExpandedItems]
  );

  const renderExpandedRow = useCallback(
    (rowIndex, rowData, onHeightChanged) => {
      return (
        <TournamentsConfigTable
          onHeightChanged={() => onHeightChanged(rowIndex)}
          key={rowData[COLUMN.ID]}
          tournamentId={rowData[COLUMN.ID]}
          onExpand={onExpand}
          isInitialExpanded={expandedItems.includes(rowData[COLUMN.ID])}
        />
      );
    },
    [onExpand, expandedItems]
  );

  return (
    <Table
      id={COLUMN.ID}
      data={data}
      columns={columns}
      order={order}
      initialToggledColumns={initialToggledColumns}
      columnsWidth={columnsWidthConfig}
      renderExpandedRow={renderExpandedRow}
      onReorder={onReorder}
      loading={loading}
      bulk={bulk}
      rowActions={rowActions}
      expandable
    />
  );
};

export default TournamentsTable;
