import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import BreadCrumbs from '../../../../../components/BreadCrumbs/BreadCrumbs';
import NotificationAlert, {
  AlertVariant,
} from '../../../../../components/Notifications/NotificationAlert';
import Page from '../../../../../components/Page/Page';
import createLineSettings from '../../../../../gql/sportbook-config/line-settings/mutations/createBrandSettings';
import { useToasts } from '../../../../../providers/ToastsProvider';
import LineSettingsForm from '../components/LineSettingsForm';
import ErrorWidget from '../../../../../components/ErrorWidget';
import { checkErrorsForWidget } from '../../../../../../utils/checkErrorsForWidget';

export const SETTINGS_FORM_MODE = {
  CREATE: 'Create',
  EDIT: 'Edit',
  DUPLICATE: 'Duplicate',
};

const defaultValues = async () => {
  return {
    name: '',
    brands: [],
    brandIds: [],
    marginId: null,
    sourcesId: null,
    coverageId: null,
  };
};

const LineSettingsCreatePage = () => {
  const [createLineSettingsCallback, { loading, error }] =
    useMutation(createLineSettings);

  const { showToast } = useToasts();
  const navigate = useNavigate();

  const onCreateFormSubmit = useCallback(
    formData => {
      createLineSettingsCallback({
        variables: {
          input: {
            name: formData.name,
            marginId: formData.marginId,
            sourcesId: formData.sourcesId,
            coverageId: formData.coverageId,
            brands: formData.brands,
          },
        },
      })
        .then(data => {
          if (data) {
            showToast(
              <NotificationAlert variant={AlertVariant.SUCCESS} timeout={5000}>
                Created line settings with name: "{formData.name}"
              </NotificationAlert>
            );
          }
          navigate('/line-settings/line');
        })
        .catch(() => {
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
              Something went wrong
            </NotificationAlert>
          );
        });
    },
    [createLineSettingsCallback, navigate, showToast]
  );

  if (checkErrorsForWidget([error])) {
    return <ErrorWidget />;
  }

  return (
    <Page title={'Create Line Settings'}>
      <BreadCrumbs
        crumbs={[
          {
            label: 'Line Settings',
            to: '/line-settings/line',
          },
          {
            label: 'New Line Settings',
          },
        ]}
      />

      <LineSettingsForm
        defaultValues={defaultValues}
        onSubmit={onCreateFormSubmit}
        formMode={SETTINGS_FORM_MODE.CREATE}
      />
    </Page>
  );
};

export default LineSettingsCreatePage;
