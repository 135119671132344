import { gql } from '@apollo/client';

const refreshTokenQuery = gql`
  mutation refreshToken($token: JwtToken!) {
    refreshToken(token: $token) {
      access_token
      refresh_token
    }
  }
`;

export default refreshTokenQuery;
