import { gql } from '@apollo/client';

const statModelFragment = gql`
  fragment StatModelFragment on StatModel {
    score {
      home
      away
    }
    period
    type
  }
`;

export default statModelFragment;
