import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TableActionCards from '../../../../components/TableActionCards/TableActionCards';
import TablePagination from '../../../../components/TablePagination/TablePagination';
import getCoverageTemplatesDiff from '../../../../gql/sportbook-config/coverage/queries/getCoverageTemplatesDiff';
import useSearchInput from '../../../../hooks/useSearchInput';
import useTablePagination from '../../../../hooks/useTablePagination';
import TemplateParentLink from '../../components/TemplateParentLink';
import CoverageTable from './CoverageTable';
import { tableNames } from '../../../../hooks/useLocalStorageTableConfig';
import ErrorWidget from '../../../../components/ErrorWidget';
import { checkErrorsForWidget } from '../../../../../utils/checkErrorsForWidget';

const CoverageTab = ({ templateId, showActions = true }) => {
  const { limit, setLimit, offset, setOffset } = useTablePagination({
    tableName: tableNames.CoverageTab,
    onChangeLimit: () => {},
  });

  const [parentTemplate, setParentTemplate] = useState();

  const renderParentLink = useCallback(() => {
    return (
      <TemplateParentLink name={parentTemplate.name} id={parentTemplate.id} />
    );
  }, [parentTemplate]);

  const { searchValue, searchInputCard } = useSearchInput({
    inline: true,
    label: 'Sport/category/tournament/event/market name or ID',
    metaData: parentTemplate && renderParentLink,
  });

  useEffect(() => {
    setOffset(0);
  }, [searchValue, setOffset]);

  const variables = useMemo(() => {
    return {
      templateId,
      limit,
      offset,
      searchQuery: searchValue,
    };
  }, [limit, offset, searchValue, templateId]);

  const {
    data: response,
    refetch,
    loading,
    error,
  } = useQuery(getCoverageTemplatesDiff, {
    variables,
    fetchPolicy: 'no-cache',
  });

  const items = useMemo(() => {
    const data = response?.data;

    if (!isEmpty(data)) {
      setParentTemplate(data.parent_template);
      return data.items;
    }
    return [];
  }, [response]);

  const total = useMemo(() => {
    return response?.data?.total || 1;
  }, [response]);

  const actions = useMemo(() => {
    return [searchInputCard];
  }, [searchInputCard]);

  if (checkErrorsForWidget([error])) {
    return <ErrorWidget />;
  }

  return (
    <>
      {showActions && <TableActionCards actions={actions} inline={true} />}
      <CoverageTable data={items} loading={loading} error={error} />
      <TablePagination
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        totalRows={total}
      />
    </>
  );
};

export default CoverageTab;
