import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import Select from '../Select/Select';

const DynamicSelect = ({
  onChange,
  value,
  initialValue = null,
  label = 'Select',
  disabled,
  required,
  multiple = false,
  labelKey = 'name',
  valueKey = 'id',
  innerOptionKey,
  gqlQuery,
  variables = {},
}) => {
  const { data, refetch, loading, error } = useQuery(gqlQuery, {
    fetchPolicy: 'no-cache',
    variables,
  });

  const items = useMemo(() => {
    return data?.data || [{ id: -1, label: 'No options' }];
  }, [data]);

  return (
    <Select
      onChange={onChange}
      value={value}
      initialValue={initialValue}
      label={label}
      disabled={disabled || loading}
      required={required}
      multiple={multiple}
      labelKey={labelKey}
      valueKey={valueKey}
      options={
        innerOptionKey
          ? items.map(value => value[innerOptionKey] ?? value)
          : items
      }
    />
  );
};

export default DynamicSelect;
