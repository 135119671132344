import TablePagination from '../../../../../components/TablePagination/TablePagination';
import useTablePresetPagination from '../../../../../hooks/useTablePresetPagination';
import GamingActivityTable from '../GamingActivity/GamingActivityTable';
import React from 'react';
import { useQuery } from '@apollo/client';
import getGamingActivityList from '../../../../../gql/Reports/queries/getGamingActivityList';
import Box from '@mui/material/Box';
import { GA_GROUP_BY } from '../GamingActivity/components/Inputs/GAGroupBySelect';
import useTableOrderByChange from '../../../../../hooks/useTableOrderByChange';
import useTablePagination from '../../../../../hooks/useTablePagination';
import { useIsOperator } from '../../../../../providers/OperatorProvider';
import getOperatorGamingActivityList from '../../../../../gql/Reports/queries/getOperatorGamingActivityList';
import { tableNames } from '../../../../../hooks/useLocalStorageTableConfig';
import ErrorWidget from '../../../../../components/ErrorWidget';
import { checkErrorsForWidget } from '../../../../../../utils/checkErrorsForWidget';

const EventPlayersTab = ({ eventId }) => {
  const isOperator = useIsOperator();
  const { limit, setLimit, offset, setOffset } = useTablePagination({
    tableName: tableNames.EventPlayersTab,
    onChangeLimit: () => {},
  });
  const { orderBy, orderByAscDesc, onChangeOrderBy } = useTableOrderByChange();

  const filters = {
    filterEventId: eventId,
    withTotals: true,
    groupBy: GA_GROUP_BY.playerId,
    orderBy,
    orderByAscDesc,
    limit,
    offset,
  };

  const {
    data: { data: gamingActivityData = {} } = {},
    loading,
    error,
  } = useQuery(
    isOperator ? getOperatorGamingActivityList : getGamingActivityList,
    {
      variables: {
        inputFilters: filters,
      },
    }
  );

  if (checkErrorsForWidget([error])) {
    return <ErrorWidget />;
  }

  return (
    <>
      <Box mt={2}>
        <GamingActivityTable
          filters={filters}
          data={gamingActivityData.items}
          summary={gamingActivityData.results && gamingActivityData.results[0]}
          orderBy={orderBy}
          onChangeOrderBy={onChangeOrderBy}
          loading={loading}
        />
      </Box>

      <TablePagination
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        totalRows={gamingActivityData.total}
      />
    </>
  );
};

export default EventPlayersTab;
