import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import IndexPage from '../containers/IndexPage';
import BaseList from '../containers/static/BaseList';
import DForm from '../containers/static/DForm';
import Documentation from '../containers/static/Documentation';
import FieldEditor from '../containers/static/FieldEditor/FieldEditor';
import Page404 from '../containers/static/Page404';
import StoryBook from '../containers/static/StoryBook';
import WebSocketProvider from '../providers/WebSocketProvider';
import { getPathForRouter } from '../utils/schemaHelper';
import AutoCreatedTournamentsPage from '../v2/pages/AutoCreatedTournamentsPage/AutoCreatedTournamentsPage';
import BetstreamPage from '../v2/pages/BetstreamPage/BetstreamPage';
import BonusCreatePage from '../v2/pages/BonusCreatePage';
import EventDetails from '../v2/pages/Events/EventDetails/EventDetails';
import EventManagementPage from '../v2/pages/Events/EventManagement/EventManagementPage';
import TradingEventsPage from '../v2/pages/TradingTool/TradingEventsPage';
import MarketsConfigPage from '../v2/pages/Events/MarketsConfigPage/MarketsConfigPage';
import FailedRequestsPage from '../v2/pages/FailedRequestsPage/FailedRequestsPage';
import GamingActivityPage from '../v2/pages/GamingActivityPage/GamingActivityPage';
import GraphqlDebugger from '../v2/pages/GraphqlDebugger';
import LineSettingsCreatePage from '../v2/pages/LineSettingsPage/FormPages/LineSettings/LineSettingsCreatePage/LineSettingsCreatePage';
import LineSettingsEditPage from '../v2/pages/LineSettingsPage/FormPages/LineSettings/LineSettingsEditPage/LineSettingsEditPage';
import TemplateCreatePage from '../v2/pages/LineSettingsPage/FormPages/Template/TemplateCreatePage/TemplateCreatePage';
import TemplateEditPage from '../v2/pages/LineSettingsPage/FormPages/Template/TemplateEditPage/TemplateEditPage';
import LineSettingsPage from '../v2/pages/LineSettingsPage/LineSettingsPage';
import OutcomesPage from '../v2/pages/OutcomesPage/OutcomesPage';
import PromoBannerCreatePage from '../v2/pages/PromoBanner/PromoBannerCreatePage';
import PromoBannerDuplicatePage from '../v2/pages/PromoBanner/PromoBannerDuplicatePage';
import PromoBannerEditPage from '../v2/pages/PromoBanner/PromoBannerEditPage';
import PromoBannersListPage from '../v2/pages/PromoBanner/PromoBannersListPage/PromoBannersListPage';
import RelatedContingenciesPage from '../v2/pages/RelatedContingenciesPage/RelatedContingenciesPage';
import RelatedContingencyPage from '../v2/pages/RelatedContingencyPage/RelatedContingencyPage';
import TableDesignerPage from '../v2/pages/TableDesignerPage/TableDesignerPage';
import TableuDashboardPage from '../v2/pages/TableuDashboardPage/TableuDashboardPage';
import TournamentsPage from '../v2/pages/TournamentsPage/TournamentsPage';
import V2MigratePage from '../v2/pages/V2MigratePage/V2MigratePage';
import MarginLadderPage from '../v2/pages/TradingTool/MarginLadderPage/MarginLadderPage';
import MarginLadderEditPage from '../v2/pages/TradingTool/MarginLadderEditPage/MarginLadderEditPage';
import ApolloClientProvider from '../v2/providers/ApolloClientProvider';
import OperatorProvider from '../v2/providers/OperatorProvider';
import PresetsProvider from '../v2/providers/PresetsProvider';
import SportsBookUserAccessRolesProvider from '../v2/providers/SportsBookUserAccessRolesProvider';
import { RouteWithParams } from './DynamicRoutes';
import GlobalLoading from './GlobalLoading';
import BetHistoryPage from '../v2/pages/BetHistoryPage/BetHistoryPage';
import ExportCSVProvider from '../v2/providers/ExportCSVProvider';
import ApplyRequiredProvider from '../v2/providers/ApplyRequiredProvider';
import { tableNames } from '../v2/hooks/useLocalStorageTableConfig';
import GlobalErrorBoundary from '../v2/components/GlobalErrorBoundary';

const SwaggerUI = lazy(() => import('../containers/static/SwaggerUI'));

const Main = ({ routes, user }) => {
  return (
    <GlobalErrorBoundary>
      <Suspense fallback={<GlobalLoading />}>
        <Routes>
          {user && <Route path="/" element={<IndexPage />} />}
          <Route path="/bonuses/create" element={<BonusCreatePage />} />
          <Route
            path="/tournaments"
            element={
              <ApolloClientProvider
                defaultEndpoint={'rm'}
                endpoints={{
                  rm: '/api/v2/risk-management',
                  autocomplete: '/api/v2/autocomplete',
                }}
                allowSubscription={false}
                key={'tournaments'}
              >
                <PresetsProvider
                  operationId={
                    'RiskManagementAdmin.tournaments_graphql_placeholder'
                  }
                >
                  <TournamentsPage />
                </PresetsProvider>
              </ApolloClientProvider>
            }
          />
          <Route
            path="/related-contingencies/:id"
            element={
              <ApolloClientProvider
                endpoints={{
                  rm: '/api/v2/risk-management',
                }}
                key={'related-contingency'}
                defaultEndpoint={'rm'}
              >
                <RelatedContingencyPage />
              </ApolloClientProvider>
            }
          />
          <Route
            path="/related-contingencies"
            element={
              <ApolloClientProvider
                endpoints={{
                  rm: '/api/v2/risk-management',
                }}
                defaultEndpoint={'rm'}
                key={'related-contingencies'}
              >
                <PresetsProvider
                  operationId={
                    'RiskManagementAdmin.related_contingency_placeholder'
                  }
                >
                  <RelatedContingenciesPage />
                </PresetsProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/betstream"
            element={
              <ApolloClientProvider
                defaultEndpoint={'betstream'}
                endpoints={{
                  betstream: '/api/v1/betstream-graphql',
                  autocomplete: '/api/v2/autocomplete',
                }}
                key={'betstream'}
              >
                <PresetsProvider operationId={'BetSlipsAdmin.bet_stream_list'}>
                  <OperatorProvider>
                    <BetstreamPage />
                  </OperatorProvider>
                </PresetsProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/banners/create"
            element={
              <>
                <ApolloClientProvider
                  defaultEndpoint={'promo'}
                  endpoints={{
                    promo: '/api/v2/promo',
                    rm: '/api/v2/risk-management',
                  }}
                  allowSubscription={false}
                >
                  <OperatorProvider>
                    <PromoBannerCreatePage />
                  </OperatorProvider>
                </ApolloClientProvider>
              </>
            }
          />

          <Route
            path="/banners/duplicate/:bannerId"
            element={
              <ApolloClientProvider
                defaultEndpoint={'promo'}
                endpoints={{
                  promo: '/api/v2/promo',
                  rm: '/api/v2/risk-management',
                }}
                allowSubscription={false}
              >
                <OperatorProvider>
                  <PromoBannerDuplicatePage />
                </OperatorProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/banners"
            element={
              <ApolloClientProvider
                defaultEndpoint={'promo'}
                endpoints={{
                  promo: '/api/v2/promo',
                  rm: '/api/v2/risk-management',
                }}
                allowSubscription={false}
              >
                <OperatorProvider>
                  <PresetsProvider
                    operationId={'PromoAdmin.banners_placeholder'}
                  >
                    <PromoBannersListPage />
                  </PresetsProvider>
                </OperatorProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/banners/:bannerId"
            element={
              <ApolloClientProvider
                defaultEndpoint={'promo'}
                endpoints={{
                  promo: '/api/v2/promo',
                  rm: '/api/v2/risk-management',
                }}
                allowSubscription={false}
              >
                <OperatorProvider>
                  <PromoBannerEditPage />
                </OperatorProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/outcomes/:eventId"
            element={
              <ApolloClientProvider
                endpoints={{
                  outcomes: '/api/v2/settlements-admin',
                }}
                defaultEndpoint={'outcomes'}
                key={'outcomes'}
              >
                <OutcomesPage />
              </ApolloClientProvider>
            }
          />

          <Route
            path="/events/:eventId"
            element={
              <ApolloClientProvider
                defaultEndpoint={'reports'}
                endpoints={{
                  rm: '/api/v2/risk-management',
                  reports: '/api/v2/reports',
                }}
                allowSubscription={false}
                key={'event-details'}
              >
                <OperatorProvider>
                  <EventDetails />
                </OperatorProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/trading-events"
            element={
              <ApolloClientProvider
                endpoints={{
                  tt: '/api/v2/trading-tool',
                }}
                wsEndpoint="/api/v2/trading-tool/ws"
                defaultEndpoint={'tt'}
                key={'trading-tool'}
              >
                <OperatorProvider>
                  <TradingEventsPage />
                </OperatorProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/trading-events/:eventId"
            element={
              <ApolloClientProvider
                endpoints={{
                  tt: '/api/v2/trading-tool',
                }}
                wsEndpoint="/api/v2/trading-tool/ws"
                defaultEndpoint={'tt'}
                key={'trading-tool'}
              >
                <OperatorProvider>
                  <TradingEventsPage />
                </OperatorProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/trading-events/create"
            element={
              <ApolloClientProvider
                endpoints={{
                  tt: '/api/v2/trading-tool',
                }}
                wsEndpoint="/api/v2/trading-tool/ws"
                defaultEndpoint={'tt'}
                key={'trading-tool'}
              >
                <OperatorProvider>
                  <TradingEventsPage isCreating />
                </OperatorProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/trading-sport-markets"
            element={
              <ApolloClientProvider
                endpoints={{
                  tt: '/api/v2/trading-tool',
                }}
                wsEndpoint="/api/v2/trading-tool/ws"
                defaultEndpoint={'tt'}
                key={'trading-tool'}
              >
                <OperatorProvider>
                  <MarketsConfigPage />
                </OperatorProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/events/:eventId/:tabKey"
            element={
              <ApolloClientProvider
                defaultEndpoint={'reports'}
                endpoints={{
                  rm: '/api/v2/risk-management',
                  reports: '/api/v2/reports',
                }}
                allowSubscription={false}
                key={'event-details'}
              >
                <OperatorProvider>
                  <EventDetails />
                </OperatorProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/reports/gaming-activity"
            element={
              <ApolloClientProvider
                defaultEndpoint={'reports'}
                endpoints={{
                  rm: '/api/v2/risk-management',
                  reports: '/api/v2/reports',
                }}
                allowSubscription={false}
                key={''}
              >
                <OperatorProvider>
                  <GamingActivityPage />
                </OperatorProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/failed_requests"
            element={
              <ApolloClientProvider
                defaultEndpoint={'brand-admin'}
                endpoints={{
                  rm: '/api/v2/risk-management',
                  'brand-admin': '/api/v2/brand-admin',
                }}
                allowSubscription={false}
                key={'brand-admin'}
              >
                <OperatorProvider>
                  <PresetsProvider
                    operationId={'OperatorAPI.failed_requests_placeholder'}
                  >
                    <FailedRequestsPage />
                  </PresetsProvider>
                </OperatorProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/line-settings"
            element={
              <ApolloClientProvider
                defaultEndpoint={'sportsbook-config'}
                endpoints={{
                  'sportsbook-config': '/api/v2/sportsbook-config',
                }}
                key={'line-settings'}
              >
                <SportsBookUserAccessRolesProvider>
                  <LineSettingsPage />
                </SportsBookUserAccessRolesProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/line-settings/:tabKey"
            element={
              <ApolloClientProvider
                defaultEndpoint={'sportsbook-config'}
                endpoints={{
                  'sportsbook-config': '/api/v2/sportsbook-config',
                }}
                key={'line-settings'}
              >
                <SportsBookUserAccessRolesProvider>
                  <LineSettingsPage />
                </SportsBookUserAccessRolesProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/line-settings/line/create"
            element={
              <ApolloClientProvider
                defaultEndpoint={'sportsbook-config'}
                endpoints={{
                  'sportsbook-config': '/api/v2/sportsbook-config',
                }}
                key={'line-settings'}
              >
                <SportsBookUserAccessRolesProvider>
                  <LineSettingsCreatePage />
                </SportsBookUserAccessRolesProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/line-settings/line/edit/:id"
            element={
              <ApolloClientProvider
                defaultEndpoint={'sportsbook-config'}
                endpoints={{
                  'sportsbook-config': '/api/v2/sportsbook-config',
                }}
                key={'line-settings'}
              >
                <SportsBookUserAccessRolesProvider>
                  <LineSettingsEditPage />
                </SportsBookUserAccessRolesProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/line-settings/template/create"
            element={
              <ApolloClientProvider
                defaultEndpoint={'sportsbook-config'}
                endpoints={{
                  'sportsbook-config': '/api/v2/sportsbook-config',
                }}
                key={'line-settings'}
              >
                <SportsBookUserAccessRolesProvider>
                  <TemplateCreatePage />
                </SportsBookUserAccessRolesProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/line-settings/template/edit/:id"
            element={
              <ApolloClientProvider
                defaultEndpoint={'sportsbook-config'}
                endpoints={{
                  'sportsbook-config': '/api/v2/sportsbook-config',
                }}
                key={'line-settings'}
              >
                <SportsBookUserAccessRolesProvider>
                  <TemplateEditPage />
                </SportsBookUserAccessRolesProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/event-management"
            element={
              <ApolloClientProvider
                defaultEndpoint={'events-groups'}
                endpoints={{
                  'events-groups': '/api/v2/events-groups',
                  'trading-tool': '/api/v2/trading-tool',
                  'risk-management': '/api/v2/risk-management',
                  promo: '/api/v2/promo',
                  autocomplete: '/api/v2/autocomplete',
                }}
                key={'events-groups'}
                allowSubscription={false}
              >
                <OperatorProvider>
                  <EventManagementPage />
                </OperatorProvider>
              </ApolloClientProvider>
            }
          />
          <Route
            path="/tableau-dashboard"
            element={
              <ApolloClientProvider
                defaultEndpoint={'events-groups'}
                endpoints={{
                  'events-groups': '/api/v2/events-groups',
                  'trading-tool': '/api/v2/trading-tool',
                  'risk-management': '/api/v2/risk-management',
                  promo: '/api/v2/promo',
                  autocomplete: '/api/v2/autocomplete',
                }}
                key={'events-groups'}
                allowSubscription={false}
              >
                <TableuDashboardPage />
              </ApolloClientProvider>
            }
          />

          <Route
            path="/auto-created-tournaments"
            element={
              <>
                <ApolloClientProvider
                  defaultEndpoint={'events-groups'}
                  endpoints={{
                    'events-groups': '/api/v2/events-groups',
                    'trading-tool': '/api/v2/trading-tool',
                    'risk-management': '/api/v2/risk-management',
                    promo: '/api/v2/promo',
                  }}
                  allowSubscription={false}
                >
                  <OperatorProvider>
                    <AutoCreatedTournamentsPage />
                  </OperatorProvider>
                </ApolloClientProvider>
              </>
            }
          />

          <Route
            path="/bet-history"
            element={
              <ApolloClientProvider
                endpoints={{
                  reports: '/api/v2/reports',
                }}
                defaultEndpoint={'reports'}
                key={'bet-history'}
                allowSubscription={false}
              >
                <OperatorProvider>
                  <PresetsProvider
                    operationId={'BetSlipsAdmin.get_slips_clickhouse'}
                    gql
                  >
                    <ExportCSVProvider
                      sectionKey={'reports-bethistory'}
                      fileName="Export Bethistory"
                    >
                      <ApplyRequiredProvider tableName={tableNames.BetHistory}>
                        <BetHistoryPage />
                      </ApplyRequiredProvider>
                    </ExportCSVProvider>
                  </PresetsProvider>
                </OperatorProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/trading-margin-ladders"
            element={
              <ApolloClientProvider
                endpoints={{
                  tt: '/api/v2/trading-tool',
                }}
                defaultEndpoint={'tt'}
                key={'trading-tool'}
                wsEndpoint="/api/v2/trading-tool/ws"
              >
                <OperatorProvider>
                  <MarginLadderPage />
                </OperatorProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/trading-margin-ladders/create"
            element={
              <ApolloClientProvider
                endpoints={{
                  tt: '/api/v2/trading-tool',
                }}
                defaultEndpoint={'tt'}
                key={'trading-tool'}
                wsEndpoint="/api/v2/trading-tool/ws"
              >
                <OperatorProvider>
                  <MarginLadderEditPage />
                </OperatorProvider>
              </ApolloClientProvider>
            }
          />

          <Route
            path="/trading-margin-ladders/edit/:ladderId"
            element={
              <ApolloClientProvider
                endpoints={{
                  tt: '/api/v2/trading-tool',
                }}
                defaultEndpoint={'tt'}
                key={'trading-tool'}
                wsEndpoint="/api/v2/trading-tool/ws"
              >
                <OperatorProvider>
                  <MarginLadderEditPage />
                </OperatorProvider>
              </ApolloClientProvider>
            }
          />

          <Route path="/graphql-debugger" element={<GraphqlDebugger />} />
          <Route path="/field-editor" element={<FieldEditor />} />
          <Route path="/dev/v2/migrate" element={<V2MigratePage />} />
          <Route
            path="/dev/v2/table-designer"
            element={<TableDesignerPage />}
          />
          <Route path="/dev/schema/swagger-ui" exact element={<SwaggerUI />} />
          <Route path="/dev/app/schema" exact element={<BaseList />} />
          <Route
            path="/dev/schema/documentation"
            exact
            element={<Documentation />}
          />
          <Route path="/dev/story-book" exact component={<StoryBook />} />
          <Route path="/dev/form/:type/:formId" exact element={<DForm />} />
          <Route path="/404" element={<Page404 />} />

          {routes &&
            Object.keys(routes)
              .sort()
              .map(path => (
                <Route
                  key={path}
                  path={getPathForRouter(path)}
                  exact
                  element={
                    <WebSocketProvider>
                      <RouteWithParams path={path} />
                    </WebSocketProvider>
                  }
                />
              ))}
        </Routes>
      </Suspense>
    </GlobalErrorBoundary>
  );
};

export default Main;
