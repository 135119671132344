import { gql } from '@apollo/client';

const updateEventStatusesMutation = gql`
  mutation updateEventStatuses($eventIds: [EventId!]!, $status: EventStatus!) {
    updateEventStatuses(events_ids: $eventIds, status: $status) {
      success {
        id
        status
        version
      }
      failed {
        id
        error
      }
    }
  }
`;

export default updateEventStatusesMutation;
