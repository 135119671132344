import { add, sub } from 'date-fns';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ConfirmationPromptContext } from '../../../../providers/ConfirmationPromptProvider';
import { getValueFromTimePicker } from '../../../../utils/datePickerUtils';
import ErrorWidget from '../../../components/ErrorWidget';
import NotificationAlert, {
  AlertVariant,
} from '../../../components/Notifications/NotificationAlert';
import Page from '../../../components/Page/Page';
import TablePagination from '../../../components/TablePagination/TablePagination';
import { useDialog } from '../../../hooks';
import {
  useGroups,
  useGroupsFilters,
  useMergerAPI,
} from '../../../hooks/event-management';
import useEventsBets from '../../../hooks/event-management/useEventsBets';
import { tableNames } from '../../../hooks/useLocalStorageTableConfig';
import { withoutEmpty } from '../../../hooks/usePresetFilters';
import useTablePagination from '../../../hooks/useTablePagination';
import DialogProvider from '../../../providers/DialogProvider';
import { useToasts } from '../../../providers/ToastsProvider';
import { mapProviderToHumanReadable } from '../../../utils/mappers';
import { getUtcDate } from '../../../utils/timeUtils';
import EventForm from '../components/EventForm';
import EventManagementConfirmationMessage from './ConfirmationModal/EventManagementConfirmationMessage';
import EventManagementConfirmationTitle from './ConfirmationModal/EventManagementConfirmationTitle';
import { confirmationTypes } from './ConfirmationModal/constants';
import EventManagementTable, {
  COLUMNS,
  initialToggledColumns,
} from './EventManagementTable';
import MergingPopup from './MergingPopup';
import { getGroupAffiliation } from './helpers';
import { checkErrorsForWidget } from '../../../../utils/checkErrorsForWidget';

const mergeGroupsWithBets = (groups, bets) => {
  if (groups) {
    if (bets) {
      return groups.map(item => {
        const id = item.booked?.desc.id;
        const betsForThisId = bets.find(b => b.eventId === id);

        if (betsForThisId) {
          return {
            ...item,
            betsCount: betsForThisId.betsCount,
          };
        } else {
          return item;
        }
      });
    } else {
      return groups;
    }
  } else {
    return [];
  }
};

const EventManagementPage = () => {
  const [visibleColumns, setVisibleColumns] = useState(initialToggledColumns);

  const defaultDateValue = useMemo(() => {
    return {
      rangeFrom: getValueFromTimePicker(sub(getUtcDate(), { hours: 2 })),
      rangeTo: getValueFromTimePicker(add(getUtcDate(), { hours: 24 })),
    };
  }, []);

  const [selectedGroups, setSelectedGroups] = useState({});
  const [highlightedGroup, setHighlightedGroup] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);
  const { open, close } = useDialog({ closeOnEsc: true });
  const [selectedFilters, setSelectedFilters] = useState({
    date: defaultDateValue,
  });

  const { showToast, removeToast, toasts } = useToasts();
  const { openConfirmation, closeConfirmation } = useContext(
    ConfirmationPromptContext
  );

  const { limit, setLimit, offset, setOffset } = useTablePagination({
    tableName: tableNames.EventManagement,
    onChangeLimit: () => {},
  });

  const { filters, loading: filtersLoading, error } = useGroupsFilters();
  const { createEvent, editEvent, mergeGroups } = useMergerAPI();

  const shouldBetsBeFetched =
    visibleColumns.includes(COLUMNS.BETS) ||
    visibleColumns.includes(COLUMNS.OPEN_BETS);

  const {
    groups,
    pageInfo,
    refetchGroups,
    loading: groupsLoading,
    error: groupsError,
  } = useGroups({
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      if (shouldBetsBeFetched && toasts.length === 0) {
        showToast(
          <NotificationAlert closable={false}>
            {`Bets are loading...`}
          </NotificationAlert>
        );
      }
    },
    variables: {
      limit,
      offset,
      ...withoutEmpty(selectedFilters, { removeEmptyArrays: true }),
    },
  });

  const { bets, error: betsError } = useEventsBets({
    skip: !groups || !shouldBetsBeFetched,
    onCompleted: () => {
      removeToast(toasts[0].key);
    },
    variables: {
      eventsIds: groups?.map(g => g.booked?.desc.id).filter(id => !!id),
    },
  });

  const selectedGroupsCount = useMemo(() => {
    return Object.values(selectedGroups).length;
  }, [selectedGroups]);

  const prepareSportsForFilter = useCallback(sports => {
    const prioritySportsIds = [
      '1', //Soccer
      '5', //Tennis
      '2', //Basketball
      '23', //Volleyball
      '4', //Ice hockey
      '21', //Cricket
      '3', //Baseball
      '111', //Dota 2
      '109', //Counter-Strike
      '110', //League of Legends
    ];

    const preparedSports = sports
      .filter(sport => sport.sport && sport.count > 0)
      .map(sport => ({
        label: sport.sport.name,
        value: sport.sport.id,
        caption: sport.count,
        defaultValue: false,
      }));

    const highPrioritySports = prioritySportsIds
      .map(id => preparedSports.find(sport => sport.value === id))
      .filter(v => !!v);

    const lowPrioritySports = preparedSports
      .filter(sport => !prioritySportsIds.includes(sport.value))
      .sort((a, b) => a.label.localeCompare(b.label));

    return [...highPrioritySports, ...lowPrioritySports];
  }, []);

  const prepareProvidersForFilter = useCallback(
    providers =>
      providers
        .map(provider => ({
          label: provider?.name || provider,
          value: provider?.id || provider,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
        .map(provider => ({
          label: mapProviderToHumanReadable(provider.label),
          value: provider.value,
        })),
    []
  );
  const highlightUpdatedGroup = id => {
    setHighlightedGroup(id);
    setTimeout(() => {
      setHighlightedGroup(null);
    }, 4000);
  };

  const highlightActiveGroup = id => {
    setActiveGroup(id);
  };

  const dehighlightActiveGroup = () => {
    setTimeout(() => {
      setActiveGroup(null);
    }, 2000);
  };

  const handleMergeButtonClick = useCallback(
    async ({ inverted }) => {
      const type = inverted
        ? confirmationTypes.invertedMerge
        : confirmationTypes.merge;

      const { data: firstGroupDetails, ...firstGroup } =
        Object.values(selectedGroups)[0];
      const { data: secondGroupDetails, ...secondGroup } =
        Object.values(selectedGroups)[1];

      openConfirmation({
        buttonLabel: 'Confirm',
        title: (
          <EventManagementConfirmationTitle
            type={type}
            onClearIconClick={closeConfirmation}
          />
        ),
        message: (
          <EventManagementConfirmationMessage
            type={type}
            firstAffiliation={getGroupAffiliation(firstGroupDetails)}
            secondAffiliation={getGroupAffiliation(secondGroupDetails)}
          />
        ),
        callback: async () => {
          try {
            const data = await mergeGroups({
              firstGroup,
              secondGroup,
              inverted,
            });
            highlightUpdatedGroup(data.groupId);
            setSelectedGroups({});
            refetchGroups();
          } catch (e) {
            showToast(
              <NotificationAlert variant={AlertVariant.ERROR}>
                {`An error occurred while merging groups: ${e.message}`}
              </NotificationAlert>
            );
          }
        },
      });
    },
    [
      selectedGroups,
      setSelectedGroups,
      mergeGroups,
      refetchGroups,
      showToast,
      openConfirmation,
      closeConfirmation,
    ]
  );

  const handleOpenModal = (data, isEditing = false) => {
    highlightActiveGroup(data.groupId);
    open({
      component: EventForm,
      props: {
        data,
        isEditing,
        close,
        onCancel: () => {
          close();
          dehighlightActiveGroup();
        },
        onSubmit: isEditing ? handleEditGroup : handleCreateGroup,
      },
      options: {
        title: isEditing ? 'Edit event' : 'Create event',
        closable: false,
      },
    });
  };

  const handleCreateGroup = data => {
    return createEvent(data)
      .then(() => {
        showToast(
          <NotificationAlert variant={AlertVariant.SUCCESS}>
            {`Event was successfully created`}
          </NotificationAlert>
        );
      })
      .then(() => refetchGroups())
      .then(close)
      .catch(e => {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred while creating event: ${e.message}`}
          </NotificationAlert>
        );
      })
      .finally(dehighlightActiveGroup);
  };

  const handleEditGroup = data => {
    return editEvent(data)
      .then(() => {
        showToast(
          <NotificationAlert variant={AlertVariant.SUCCESS}>
            {`Event was successfully updated`}
          </NotificationAlert>
        );
      })
      .then(() => refetchGroups())
      .then(close)
      .catch(e => {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred while updating event: ${e.message}`}
          </NotificationAlert>
        );
      })
      .finally(dehighlightActiveGroup);
  };

  if (checkErrorsForWidget([error, groupsError, betsError])) {
    return <ErrorWidget />;
  }

  return (
    <Page
      title={'Event Management'}
      filters={{
        loading: filtersLoading,
        selectedFilters,
        refetch: refetchGroups,
        callback: () => setOffset(0),
        onApply: appliedFilters => {
          const { sport, date, providers, status, advanced, type } =
            appliedFilters;

          const types = [
            type.match ? 'match' : undefined,
            type.outright ? 'outright' : undefined,
          ].filter(t => !!t);

          const newFilters = {
            date: date.range,
            sport: Object.keys(sport).filter(key => sport[key]),
            providers: Object.keys(providers).filter(key => providers[key]),
            status: Object.keys(status).filter(key => status[key]),
            ...(types.length === 1 && {
              isOutright: types[0] === 'outright',
            }),
            query: advanced.query,
          };

          const isTheSameFilters =
            JSON.stringify(selectedFilters) === JSON.stringify(newFilters);

          if (isTheSameFilters) {
            refetchGroups({
              limit,
              offset,
              ...withoutEmpty(selectedFilters, { removeEmptyArrays: true }),
            });
          } else {
            setSelectedFilters(newFilters);
          }
          setOffset(0);
        },
        filters: [
          {
            value: 'date',
            type: 'date',
            items: [
              {
                value: 'range',
                defaultValue: defaultDateValue,
              },
            ],
          },
          {
            value: 'sport',
            label: 'Sport',
            type: 'checkbox',
            allowToSelectAll: true,
            maxRows: 5,
            items: [
              ...(filters?.sports
                ? prepareSportsForFilter(filters.sports)
                : []),
            ],
          },
          {
            value: 'status',
            label: 'Status',
            type: 'checkbox',
            items: [
              {
                label: 'Auto',
                value: 'auto',
              },
              {
                label: 'Manual',
                value: 'manual',
              },
              {
                label: 'Creatable',
                value: 'creatable',
              },
              {
                label: 'Mergeable',
                value: 'mergeable',
              },
            ],
          },
          {
            value: 'providers',
            label: 'Source',
            type: 'checkbox',
            items: [
              ...(filters?.providers
                ? prepareProvidersForFilter(filters.providers)
                : []),
            ],
          },
          {
            value: 'type',
            label: 'Event type',
            type: 'checkbox',
            items: [
              {
                label: 'Match',
                value: 'match',
              },
              {
                label: 'Outright',
                value: 'outright',
              },
            ],
          },
          {
            value: 'advanced',
            label: 'Advanced',
            type: 'text',
            items: [
              {
                placeholder: 'Event ID, Source ID, Affiliation',
                value: 'query',
              },
            ],
          },
        ],
      }}
    >
      <EventManagementTable
        toggledColumns={visibleColumns}
        onToggleColumns={setVisibleColumns}
        highlightedRow={highlightedGroup}
        activeRow={activeGroup}
        loading={groupsLoading}
        items={mergeGroupsWithBets(groups, bets)}
        selectedGroups={selectedGroups}
        onSelectGroup={setSelectedGroups}
        onGroupCreate={handleOpenModal}
        onGroupEdit={handleOpenModal}
        isMergeCheckboxDisabled={selectedGroupsCount > 1}
        refetchGroups={refetchGroups}
        limit={limit}
      />
      <TablePagination
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        totalRows={pageInfo?.total}
      />
      {selectedGroupsCount >= 2 && (
        <MergingPopup
          count={selectedGroupsCount}
          onButtonClick={() => handleMergeButtonClick({ inverted: false })}
          onInvertedButtonClick={() =>
            handleMergeButtonClick({ inverted: true })
          }
          onCancelButtonClick={() => setSelectedGroups({})}
        />
      )}
    </Page>
  );
};

export default EventManagementPage;
