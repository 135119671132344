import { gql } from '@apollo/client';

const GetTournamentsNames = gql`
  query getTournamentsNames(
    $query: String!
    $limit: Int
    $offset: Int
    $sport_ids: [SportId!]
    $categories_ids: [CategoryId!]
    $tournaments_ids: [TournamentId!]
  ) @api(name: autocomplete) {
    data: getTournamentsNames(
      query: $query
      limit: $limit
      offset: $offset
      sportIds: $sport_ids
      categoriesIds: $categories_ids
      tournamentsIds: $tournaments_ids
    ) {
      name
      id
    }
  }
`;

export default GetTournamentsNames;
