import { useMutation, useQuery } from '@apollo/client';
import {
  default as React,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import BrandSelect from '../../components/Inputs/BrandSelect/BrandSelect';
import DateTimeUtcInput from '../../components/Inputs/DateTimeUtcInput';
import NumberField from '../../components/Inputs/NumberRangePicker/NumberField';
import Select from '../../components/Inputs/Select/Select';
import TextField from '../../components/Inputs/TextField';
import NotificationAlert, {
  AlertVariant,
} from '../../components/Notifications/NotificationAlert';
import Page from '../../components/Page/Page';
import TableActionCards from '../../components/TableActionCards/TableActionCards';
import TablePagination from '../../components/TablePagination/TablePagination';
import retryRequests from '../../gql/brand-admin/mutations/retryRequests';
import getFailedRequestsList from '../../gql/brand-admin/queries/getFailedRequestsList';
import usePresetFilters, { withoutEmpty } from '../../hooks/usePresetFilters';
import usePresetsActionCard from '../../hooks/usePresetsActionCard';
import useTableBulkFullSelection from '../../hooks/useTableBulkFullSelection';
import useTablePresetPagination from '../../hooks/useTablePresetPagination';
import TableBulkProvider from '../../providers/TableBulkProvider';
import { useToasts } from '../../providers/ToastsProvider';
import FailedRequestsRetryForm from './FailedRequestsRetryForm';
import FailedRequestsTable, {
  initialToggledColumns,
} from './FailedRequestsTable';
import RetryRequestsNotificationMessage from './components/RetryRequestsNotificationMessage';
import useDynamicQuery from '../../hooks/useDynamicQuery';
import { tableNames } from '../../hooks/useLocalStorageTableConfig';
import ErrorWidget from '../../components/ErrorWidget';
import { checkErrorsForWidget } from '../../../utils/checkErrorsForWidget';

const EXECUTED = {
  ALL: 'ALL',
  TRUE: 'TRUE',
  FALSE: 'FALSE',
};

const MARKER = {
  bet_refund: 'bet/refund',
  bet_win: 'bet/win',
  bet_commit: 'bet/commit',
  bet_settlement: 'bet/settlement',
  bet_cancel: 'bet/cancel',
  bet_rollback: 'bet/rollback',
  bet_discard: 'bet/discard',
  bet_lost: 'bet/lost',
};

const METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
};

const defaultFilters = {
  executed: EXECUTED.ALL,
  marker: null,
  method: null,
  transaction_id: '',
  datetime_from: null,
  datetime_to: null,
  last_attempt_from: null,
  last_attempt_to: null,
  brand_id: null,
  bet_id: null,
  player_id: null,
  ext_player_id: null,
  id: null,
};

const FailedRequestsPage = () => {
  const [visibleColumns, setVisibleColumns] = useState(initialToggledColumns);

  const presetsActionCard = usePresetsActionCard({
    tableName: tableNames.FailedRequests,
    spanMin: 1,
  });
  const refetchRequestsRef = useRef(() => {});
  const { limit, setLimit, offset, setOffset } = useTablePresetPagination({
    tableName: tableNames.FailedRequests,
    onChangeLimit: () => {},
  });

  const { showToast } = useToasts();

  const refetchRequests = useCallback(() => {
    return refetchRequestsRef.current();
  }, []);

  const { filtersCard, filters } = usePresetFilters({
    tableName: tableNames.FailedRequests,
    onResubmit: refetchRequests,
    resetOffset: setOffset,
    filters: [
      {
        name: 'executed',
        label: 'Executed',
        component: (
          <Select
            options={[
              {
                label: 'all',
                value: EXECUTED.ALL,
              },
              {
                label: 'true',
                value: EXECUTED.TRUE,
              },
              {
                label: 'false',
                value: EXECUTED.FALSE,
              },
            ]}
          />
        ),
        toggled: true,
      },
      {
        name: 'marker',
        label: 'Marker',
        component: (
          <Select
            options={[
              {
                label: MARKER.bet_refund,
                value: 'bet_refund',
              },
              {
                label: MARKER.bet_win,
                value: 'bet_win',
              },
              {
                label: MARKER.bet_commit,
                value: 'bet_commit',
              },
              {
                label: MARKER.bet_settlement,
                value: 'bet_settlement',
              },
              {
                label: MARKER.bet_cancel,
                value: 'bet_cancel',
              },
              {
                label: MARKER.bet_rollback,
                value: 'bet_rollback',
              },
              {
                label: MARKER.bet_discard,
                value: 'bet_discard',
              },
              {
                label: MARKER.bet_lost,
                value: 'bet_lost',
              },
            ]}
          />
        ),
        toggled: true,
      },
      {
        name: 'method',
        label: 'Method',
        component: (
          <Select
            options={[
              {
                label: METHOD.GET,
                value: METHOD.GET,
              },
              {
                label: METHOD.POST,
                value: METHOD.POST,
              },
              {
                label: METHOD.PUT,
                value: METHOD.PUT,
              },
            ]}
          />
        ),
        toggled: true,
      },
      {
        name: 'transaction_id',
        label: 'Transaction ID',
        fullWidth: false,
        component: <NumberField />,
        toggled: true,
      },
      {
        name: 'datetime_from',
        label: 'Datetime from',
        fullWidth: false,
        component: <DateTimeUtcInput />,
        toggled: true,
      },
      {
        name: 'datetime_to',
        label: 'Datetime to',
        fullWidth: false,
        component: <DateTimeUtcInput />,
        toggled: true,
      },
      {
        name: 'last_attempt_from',
        label: 'Last attempt from',
        fullWidth: false,
        component: <DateTimeUtcInput />,
        toggled: true,
      },
      {
        name: 'last_attempt_to',
        label: 'Last attempt to',
        fullWidth: false,
        component: <DateTimeUtcInput />,
        toggled: true,
      },
      {
        name: 'bet_id',
        label: 'Betslip ID',
        fullWidth: false,
        component: <NumberField />,
        toggled: true,
      },
      {
        name: 'brand_id',
        label: 'Brand Name',
        fullWidth: false,
        component: <BrandSelect withOperator={false} />,
        toggled: true,
      },
      {
        name: 'player_id',
        label: 'Player ID',
        fullWidth: false,
        component: <NumberField />,
        toggled: true,
      },
      {
        name: 'ext_player_id',
        label: 'Ext player ID',
        fullWidth: false,
        component: ({ brand_id, ...props }) => {
          return <TextField disabled={!brand_id} {...props} />;
        },
        deps: ['brand_id'],
        toggled: true,
      },
      {
        name: 'id',
        label: 'ID',
        fullWidth: false,
        component: <NumberField />,
        toggled: true,
      },
    ],
    spanMin: 1,
    spanMax: 4,
  });

  const { query } = useDynamicQuery('getFailedRequestsList', {
    fields: visibleColumns,
    variables: {
      filters: {
        type: 'FailRequestFilters',
        required: true,
      },
    },
    template: fields => [{ items: ['id', ...fields] }, 'total'],
  });

  const { data, refetch, loading, error } = useQuery(query, {
    fetchPolicy: 'no-cache',
    variables: {
      filters: {
        limit,
        offset,
        ...withoutEmpty(filters),
      },
    },
  });

  const [
    retryRequestsCb,
    { loading: retryRequestsLoading, error: retryError },
  ] = useMutation(retryRequests, {
    refetchQueries: ['getFailedRequestsList'],
  });
  refetchRequestsRef.current = refetch;

  const actions = useMemo(() => {
    return [presetsActionCard, filtersCard];
  }, [presetsActionCard, filtersCard]);

  const bulk = useTableBulkFullSelection(
    data?.getFailedRequestsList?.total || 1
  );

  const onSubmit = useCallback(() => {
    retryRequestsCb({
      variables: {
        input: {
          exclude: bulk.bulkState.exclude,
          request_ids: bulk.bulkState.ids,
          filters: {
            ...withoutEmpty(filters),
          },
        },
      },
    })
      .then(({ data: { data } = {} }) => {
        const itemsSelected = bulk.bulkState.itemsSelected;
        const ids = bulk.bulkState.ids;

        showToast(
          <NotificationAlert variant={AlertVariant.SUCCESS} timeout={10000}>
            <RetryRequestsNotificationMessage
              itemsSelected={itemsSelected}
              requestIds={ids}
              taskId={data}
            />
          </NotificationAlert>
        );

        bulk.reset();
      })
      .catch(() => {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
            Something went wrong ...
          </NotificationAlert>
        );
      });
  }, [bulk, filters, retryRequestsCb, showToast]);

  if (checkErrorsForWidget([error, retryError])) {
    return <ErrorWidget />;
  }

  return (
    <Page title={'Failed requests list'}>
      <TableActionCards animated actions={actions} />
      {(bulk.someToggled || bulk.everyToggled) && (
        <FailedRequestsRetryForm
          loading={retryRequestsLoading}
          selected={bulk.bulkState.itemsSelected}
          onSubmit={onSubmit}
        />
      )}
      <TableBulkProvider bulk={bulk}>
        <FailedRequestsTable
          data={data?.getFailedRequestsList?.items || []}
          loading={loading}
          onToggleColumns={setVisibleColumns}
        />
      </TableBulkProvider>
      <TablePagination
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        totalRows={data?.getFailedRequestsList?.total || 1}
      />
    </Page>
  );
};

export default FailedRequestsPage;
