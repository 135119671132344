import RelatedContingencyAutocomplete, {
  RelatedContingencySelectMode,
} from './RelatedContingencyAutocomplete';
import { useCallback, useMemo } from 'react';
// import GetTournamentsNames from '../../../gql/RiskManagement/queries/getTournamentsNames';
// TODO: remove OLD_, fix wrong autocomplete request on Related Contingencies page
import OLD_GetTournamentsNames from '../../../gql/RiskManagement/queries/OLD_getTournamentsNames';
import {
  FancySelectOption,
  FancySelectOptionRight,
} from '../FancySelect/FancySelect';
import Checkbox from '../Checkbox';

const TournamentAutocomplete = ({
  withSameOption,
  value,
  sports,
  categories,
  onChange,
  ...props
}) => {
  const params = useMemo(
    () => ({
      sport_ids: sports,
      categories_ids: categories,
    }),
    [sports, categories]
  );

  const mode = value && value.type;

  const onSameMode = useCallback(() => {
    onChange({
      value: [],
      type:
        value.type === RelatedContingencySelectMode.SAME
          ? RelatedContingencySelectMode.LIST
          : RelatedContingencySelectMode.SAME,
    });
  }, [value, onChange]);

  return (
    <RelatedContingencyAutocomplete
      extraOptions={
        withSameOption && (
          <>
            <FancySelectOption onClick={onSameMode}>
              Same Tournament{' '}
              <FancySelectOptionRight>
                <Checkbox value={mode === RelatedContingencySelectMode.SAME} />
              </FancySelectOptionRight>
            </FancySelectOption>
          </>
        )
      }
      allLabel={'All Tournaments'}
      sameLabel={'Same Tournament'}
      label={'Select Tournament'}
      gqlQuery={OLD_GetTournamentsNames}
      params={params}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

export default TournamentAutocomplete;
