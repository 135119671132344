import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';

const useAutocompleteQuery = (
  gqlQuery,
  params,
  prepareOptions,
  skip = false,
  ignoreSearchQuery = false
) => {
  const [query, setQuery] = useState('');

  const queryVariables = useMemo(() => {
    return {
      query: ignoreSearchQuery ? null : query,
      ...params,
    };
  }, [query, params, ignoreSearchQuery]);

  const { loading, data, previousData } = useQuery(gqlQuery, {
    fetchPolicy: 'no-cache',
    variables: queryVariables,
    skip,
  });

  return useMemo(
    () => ({
      loading,
      data: prepareOptions ? prepareOptions(data?.data || []) : data?.data,
      previousData: prepareOptions
        ? prepareOptions(previousData?.data || [])
        : previousData?.data,
      setQuery,
    }),
    [loading, data, setQuery, prepareOptions, previousData]
  );
};

export default useAutocompleteQuery;
