import React, { useCallback, useEffect, useState } from 'react';
import Page from '../../components/Page/Page';
import AutoCreatedTournamentsTable from './AutoCreatedTournamentsTable';
import TablePagination from '../../components/TablePagination/TablePagination';
import useTablePagination from '../../hooks/useTablePagination';
import {
  useMergerAPI,
  useAutocreatableTournamentsFilters,
  useAutocreatableTournaments,
} from '../../hooks/event-management';
import DialogProvider from '../../providers/DialogProvider';
import { useToasts } from '../../providers/ToastsProvider';
import { withoutEmpty } from '../../hooks/usePresetFilters';
import { Stack } from '@mui/material';
import Button from '../../components/Button/Button';
import NotificationAlert, {
  AlertVariant,
} from '../../components/Notifications/NotificationAlert';
import AutocreatedConfirmationModal from './ConfirmationModal';
import { useDialog } from '../../hooks';
import { mapProviderToHumanReadable } from '../../utils/mappers';
import { tableNames } from '../../hooks/useLocalStorageTableConfig';
import ErrorWidget from '../../components/ErrorWidget';
import { checkErrorsForWidget } from '../../../utils/checkErrorsForWidget';

const toAutocreatableAll = tournaments =>
  tournaments.map(tournament => ({ ...tournament, autocreatable: true }));

const AutoCreatedTournamentsPage = () => {
  const [autocreatableTournaments, setAutocreatableTournaments] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const { open, close } = useDialog({ closeOnEsc: true });

  const { showToast } = useToasts();
  const { limit, setLimit, offset, setOffset } = useTablePagination({
    tableName: tableNames.AutoCreatedTournaments,
    onChangeLimit: () => {},
  });

  const {
    filters,
    loading: filtersLoading,
    error,
  } = useAutocreatableTournamentsFilters();

  const { disableAutoCreatability } = useMergerAPI();
  const {
    tournaments,
    pageInfo,
    refetchTournaments,
    loading: groupsLoading,
    error: groupsError,
  } = useAutocreatableTournaments({
    fetchPolicy: 'cache-and-network',
    variables: {
      limit,
      offset,
      ...withoutEmpty(selectedFilters, { removeEmptyArrays: true }),
    },
  });

  useEffect(() => {
    if (tournaments) {
      setAutocreatableTournaments(toAutocreatableAll(tournaments));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournaments]);

  const prepareSportsForFilter = useCallback(sports => {
    const prioritySportsIds = [
      '1', //Soccer
      '5', //Tennis
      '2', //Basketball
      '23', //Volleyball
      '4', //Ice hockey
      '21', //Cricket
      '3', //Baseball
      '111', //Dota 2
      '109', //Counter-Strike
      '110', //League of Legends
    ];

    const preparedSports = sports
      .filter(sport => sport.sport && sport.count > 0)
      .map(sport => ({
        label: sport.sport.name,
        value: sport.sport.id,
        caption: sport.count,
        defaultValue: false,
      }));

    const highPrioritySports = prioritySportsIds
      .map(id => preparedSports.find(sport => sport.value === id))
      .filter(v => !!v);

    const lowPrioritySports = preparedSports
      .filter(sport => !prioritySportsIds.includes(sport.value))
      .sort((a, b) => a.label.localeCompare(b.label));

    return [...highPrioritySports, ...lowPrioritySports];
  }, []);

  const prepareProvidersForFilter = useCallback(
    providers =>
      providers
        .map(provider => ({
          label: provider?.name || provider,
          value: provider?.id || provider,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
        .map(provider => ({
          label: mapProviderToHumanReadable(provider.label),
          value: provider.value,
        })),
    []
  );

  const handleSaveChanges = () => {
    const tournamentsToChange = autocreatableTournaments
      .filter(t => !t.autocreatable)
      .map(({ provider, providerId }) => ({ provider, providerId }));

    disableAutoCreatability(tournamentsToChange).then(() => {
      showToast(
        <NotificationAlert variant={AlertVariant.SUCCESS}>
          Successfully
        </NotificationAlert>
      );
      refetchTournaments();
      close();
    });
  };

  const handleOpenModal = () => {
    open({
      component: AutocreatedConfirmationModal,
      props: {
        amount: autocreatableTournaments.filter(t => !t.autocreatable).length,
      },
      options: {
        title: 'Are you sure you want to save?',
        onSubmit: handleSaveChanges,
        onClose: close,
        ButtonsProps: {
          submitTitle: 'Confirm',
        },
      },
    });
  };

  const hasChanged = !autocreatableTournaments.some(t => !t.autocreatable);

  if (checkErrorsForWidget([error, groupsError])) {
    return <ErrorWidget />;
  }

  return (
    <Page
      title={'Auto-created Tournaments'}
      filters={{
        loading: filtersLoading,
        onApply: appliedFilters => {
          const { sports, providers, advanced } = appliedFilters;

          const newFilters = {
            sports: Object.keys(sports).filter(key => sports[key]),
            providers: Object.keys(providers).filter(key => providers[key]),
            query: advanced.query,
          };

          const isTheSameFilters =
            JSON.stringify(selectedFilters) === JSON.stringify(newFilters);

          if (isTheSameFilters) {
            refetchTournaments({
              limit,
              offset,
              ...withoutEmpty(selectedFilters, { removeEmptyArrays: true }),
            });
          } else {
            setSelectedFilters(newFilters);
          }
          setOffset(0);
        },
        filters: [
          {
            value: 'sports',
            label: 'Sport',
            type: 'checkbox',
            allowToSelectAll: true,
            maxRows: 5,
            items: [
              ...(filters?.sports
                ? prepareSportsForFilter(filters.sports)
                : []),
            ],
          },
          {
            value: 'providers',
            label: 'Source',
            type: 'checkbox',
            items: [
              ...(filters?.providers
                ? prepareProvidersForFilter(filters.providers)
                : []),
            ],
          },
          {
            value: 'advanced',
            label: 'Advanced',
            type: 'text',
            items: [
              {
                placeholder: 'Affiliation',
                value: 'query',
              },
            ],
          },
        ],
      }}
    >
      <Stack justifyContent={'space-between'} height={'100%'}>
        <AutoCreatedTournamentsTable
          loading={!autocreatableTournaments?.length && groupsLoading}
          items={autocreatableTournaments || []}
          onSelectTournament={setAutocreatableTournaments}
        />
        <Stack>
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            gap={'13px'}
            sx={{
              background: 'white',
              padding: '12px',
              margin: '25px 0 22px',
            }}
          >
            <Button
              disabled={hasChanged}
              onClick={() =>
                setAutocreatableTournaments(toAutocreatableAll(tournaments))
              }
            >
              Cancel
            </Button>
            <Button disabled={hasChanged} onClick={handleOpenModal}>
              Save
            </Button>
          </Stack>
          <TablePagination
            offset={offset}
            setOffset={setOffset}
            limit={limit}
            setLimit={setLimit}
            totalRows={pageInfo?.total}
          />
        </Stack>
      </Stack>
    </Page>
  );
};

export default AutoCreatedTournamentsPage;
