import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid/Grid';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import isNil from 'lodash/isNil';
import { FormProvider, useForm } from 'react-hook-form';
import { formatToDatetimeString } from '../../../../../../utils/datePickerUtils';
import Button from '../../../../../components/Button/Button';
import FormField from '../../../../../components/Form/FormField';
import FormGroup from '../../../../../components/Form/FormGroup';
import Checkbox from '../../../../../components/Inputs/Checkbox';
import DateTimeUtcInput from '../../../../../components/Inputs/DateTimeUtcInput';
import FancyAutocomplete from '../../../../../components/Inputs/FancyAutocomplete/FancyAutocomplete';
import NumberField from '../../../../../components/Inputs/NumberField';
import Select from '../../../../../components/Inputs/Select/Select';
import Switch from '../../../../../components/Inputs/Switch/Switch';
import NotificationAlert, {
  AlertVariant,
} from '../../../../../components/Notifications/NotificationAlert';
import TradingEventScoreboard from './TradingEventScoreboard';
import Tabs from '../../../../../components/Tabs/Tabs';
import TradingEventSpecifiersList from './TradingEventSpecifiersList';
import AddRobotMarketForm from './AddRobotMarketForm';
import CreateSpecifierForm from './CreateSpecifierForm';
import ChangeMarketNameForm from './ChangeMarketNameForm';
import TradingEventTimer from './TradingEventTimer';
import ExpandIcon from '../../../../../../icons/controls/ExpandIcon';
import CREATE_EVENT from '../../../../../gql/TradingTool/mutations/CREATE_EVENT';
import UPDATE_EVENT from '../../../../../gql/TradingTool/mutations/UPDATE_EVENT';
import enableEventMarketsMutation from '../../../../../gql/TradingTool/mutations/enableEventMarketsMutation';
import enableEventMarketMutation from '../../../../../gql/TradingTool/mutations/enableEventMarketMutation';
import enableMarketSpecifierMutation from '../../../../../gql/TradingTool/mutations/enableMarketSpecifierMutation';
import setOutcomeCoefMutation from '../../../../../gql/TradingTool/mutations/setOutcomeCoefMutation';
import addMarketSpecifierMutation from '../../../../../gql/TradingTool/mutations/addMarketSpecifierMutation';
import removeMarketSpecifierMutation from '../../../../../gql/TradingTool/mutations/removeMarketSpecifierMutation';
import calcEventOutcomesMutation from '../../../../../gql/TradingTool/mutations/calcEventOutcomesMutation';
import setMarketMainSpecifierMutation from '../../../../../gql/TradingTool/mutations/setMarketMainSpecifierMutation';
import changeMarketNameMutation from '../../../../../gql/TradingTool/mutations/changeMarketNameMutation';
import addMarketFreeSpecifierMutation from '../../../../../gql/TradingTool/mutations/addMarketFreeSpecifierMutation';
import CATEGORIES_AUTOCOMPLETE_QUERY from '../../../../../gql/TradingTool/queries/CATEGORIES_AUTOCOMPLETE_QUERY';
import COMPETITORS_AUTOCOMPLETE_QUERY from '../../../../../gql/TradingTool/queries/COMPETITORS_AUTOCOMPLETE_QUERY';
import SPORTS_AUTOCOMPLETE_QUERY from '../../../../../gql/TradingTool/queries/SPORTS_AUTOCOMPLETE_QUERY';
import TOURNAMENTS_AUTOCOMPLETE_QUERY from '../../../../../gql/TradingTool/queries/TOURNAMENTS_AUTOCOMPLETE_QUERY';
import marginLaddersAutocompleteQuery from '../../../../../gql/TradingTool/queries/marginLaddersAutocompleteQuery';
import { useToasts } from '../../../../../providers/ToastsProvider';
import {
  prefixSpecifierName,
  unprefixSpecifierName,
} from '../../TradingEventEditContainer';
import useEventMarketsDetails from '../../hooks/useEventMarketsDetails';
import TradingEventValidationSchema from './TradingEventValidationSchema';
import {
  ALL_MARKETS_TAB_VALUE,
  EVENT_STATUSES,
  EVENT_TABS,
  EVENT_TYPES,
} from '../../../constants';
import { Typography } from '@mui/material';
import { useDialog } from '../../../../../hooks';
import DialogProvider from '../../../../../providers/DialogProvider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import getMarginLadderDetailsQuery from '../../../../../gql/TradingTool/queries/getMarginLadderDetailsQuery';
import statsSubscription from '../../../../../gql/TradingTool/subscriptions/statsSubscription';
import versionSubscription from '../../../../../gql/TradingTool/subscriptions/versionSubscription';
import eventStateSubscription from '../../../../../gql/TradingTool/subscriptions/eventStateSubscription';
import eventModelFragment from '../../../../../gql/TradingTool/fragments/eventModelFragment';
import startMatchTimerMutation from '../../../../../gql/TradingTool/mutations/startMatchTimerMutation';
import stopMatchTimerMutation from '../../../../../gql/TradingTool/mutations/stopMatchTimerMutation';
import matchTimerFragment from '../../../../../gql/TradingTool/fragments/matchTimerFragment';
import setMatchTimerMutation from '../../../../../gql/TradingTool/mutations/setMatchTimerMutation';

const useStyles = makeStyles({
  allMarkets: {
    marginTop: 16,
  },
  market: {
    display: 'flex',
    flexDirection: 'column',
  },
  marketName: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 16,
    marginTop: 24,
  },
  marketNameMain: {
    marginLeft: 8,
    marginTop: 8,
  },
  marketNameInList: {
    marginTop: 8,
  },
  marketNameClickable: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  marketNameEdit: {
    cursor: 'pointer',
    marginLeft: 8,
    color: '#1581FF',
    fontWeight: 400,
  },
  expandIcon: {
    marginLeft: 8,
  },
  mainSpecifiersList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  formGroupMarketsTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
    marginLeft: 8,
  },
  formGroupMarketsSwitch: {
    marginRight: 8,
  },
  formGroupLabel: {
    fontWeight: 600,
    fontSize: 18,
  },
  formGroupLabelOnTop: {
    marginBottom: 16,
  },
  formButton: {
    minWidth: 'unset',
  },
  robotCalculateButton: {
    marginLeft: 24,
  },
  robotAddButton: {
    marginLeft: 16,
  },
});

const TradingEventEditForm = ({
  activeTab,
  setActiveTab,
  isCreating,
  defaultValues,
  eventId,
  eventData,
  onBackButtonClick,
  refetchEvents,
}) => {
  const classes = useStyles();
  const { showToast } = useToasts();
  const { open, close } = useDialog({ closeOnEsc: true });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [initialStatus, setInitialStatus] = useState(null);
  const [expandedMarkets, setExpandedMarkets] = useState([]);
  const [selectedMarketsTabId, setSelectedMarketsTabId] = useState(
    ALL_MARKETS_TAB_VALUE
  );
  const selectedMarket = eventData?.markets.find(
    ({ id }) => id === selectedMarketsTabId
  );

  const form = useForm({
    resolver: yupResolver(TradingEventValidationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
    resetOptions: {
      keepDirtyValues: false,
    },
  });

  const {
    handleSubmit,
    formState: { dirtyFields, errors, defaultValues: currentDefaultValues },
    watch,
    getFieldState,
    getValues,
    reset,
    resetField,
    setValue,
    clearErrors,
  } = form;

  const sport = watch('sport');
  const category = watch('category');
  const tournament = watch('tournament');
  const scheduled = watch('scheduled');
  const competitors = watch('competitors');
  const markets = watch('markets');
  const marginLadder = watch('marginLadder');
  const eventType = watch('type');

  //
  // const formData = watch();
  // console.log({
  //   formData: formData,
  //   currentDefaultValues: currentDefaultValues,
  // });
  //

  const { data: { data: { items: marginLadderValues = [] } = {} } = {} } =
    useQuery(getMarginLadderDetailsQuery, {
      variables: {
        ladderId: marginLadder?.id,
      },
      skip: !marginLadder?.id,
    });

  const [createEvent, { loading: createEventLoading }] =
    useMutation(CREATE_EVENT);

  const [updateEvent, { loading: updateEventLoading }] =
    useMutation(UPDATE_EVENT);

  const [enableEventMarkets] = useMutation(enableEventMarketsMutation, {
    update(cache, { data: { enableEventMarkets } }, { variables }) {
      cache.modify({
        id: `EventModel:${variables.eventId}`,
        fields: {
          blocked_markets: () => !variables.status,
          version: () => enableEventMarkets.version,
        },
      });
    },
  });

  const [enableEventMarket] = useMutation(enableEventMarketMutation, {
    update(cache, { data: { enableEventMarket } }, { variables }) {
      cache.modify({
        id: `EventTableRecord:${variables.eventId}`,
        fields: {
          blocked_markets: () => enableEventMarket.blocked_markets,
          version: () => enableEventMarket.version,
        },
      });
    },
  });

  const [enableMarketSpecifier] = useMutation(enableMarketSpecifierMutation, {
    update(cache, { data: { enableMarketSpecifier } }, { variables }) {
      cache.modify({
        id: `EventTableRecord:${variables.eventId}`,
        fields: {
          version: () => enableEventMarket.version,
        },
      });
    },
  });

  const [setOutcomeCoef, { loading: setOutcomeCoefLoading }] = useMutation(
    setOutcomeCoefMutation,
    {
      update(cache, { data: { setOutcomeCoef } }, { variables }) {
        cache.modify({
          id: `EventTableRecord:${variables.eventId}`,
          fields: {
            version: () => setOutcomeCoef.version,
          },
        });
      },
    }
  );

  const [addMarketSpecifier] = useMutation(addMarketSpecifierMutation, {
    update(cache, { data: { addMarketSpecifier } }, { variables }) {
      cache.modify({
        id: `EventTableRecord:${variables.eventId}`,
        fields: {
          version: () => addMarketSpecifier.version,
        },
      });
    },
  });

  const [addMarketFreeSpecifier] = useMutation(addMarketFreeSpecifierMutation, {
    update(cache, { data: { addMarketFreeSpecifier } }, { variables }) {
      cache.modify({
        id: `EventTableRecord:${variables.eventId}`,
        fields: {
          version: () => addMarketFreeSpecifier.version,
        },
      });
    },
  });

  const [removeMarketSpecifier] = useMutation(removeMarketSpecifierMutation, {
    update(cache, { data: { removeMarketSpecifier } }, { variables }) {
      cache.modify({
        id: `EventTableRecord:${variables.eventId}`,
        fields: {
          version: () => removeMarketSpecifier.version,
        },
      });
    },
  });

  const [setMarketMainSpecifier] = useMutation(setMarketMainSpecifierMutation, {
    update(cache, { data: { setMarketMainSpecifier } }, { variables }) {
      cache.modify({
        id: `EventTableRecord:${variables.eventId}`,
        fields: {
          version: () => setMarketMainSpecifier.version,
        },
      });
    },
  });

  const [calcEventOutcomes, { loading: calcEventOutcomesLoading }] =
    useMutation(calcEventOutcomesMutation, {
      update(cache, { data: { calcEventOutcomes } }, { variables }) {
        cache.modify({
          id: `EventTableRecord:${variables.eventId}`,
          fields: {
            blocked_markets: () => calcEventOutcomes.blocked_markets,
            version: () => calcEventOutcomes.version,
          },
        });
      },
    });

  const [changeMarketName] = useMutation(changeMarketNameMutation, {
    update(cache, { data: { changeMarketName } }, { variables }) {
      cache.modify({
        id: `EventTableRecord:${variables.eventId}`,
        fields: {
          version: () => changeMarketName.version,
        },
      });
    },
  });

  const [startMatchTimer] = useMutation(startMatchTimerMutation);
  const [stopMatchTimer] = useMutation(stopMatchTimerMutation);
  const [setMatchTimer] = useMutation(setMatchTimerMutation);

  const {
    getOutcomeName,
    getMarketName,
    isSpecifierEditable,
    isFreeTextMarket,
    getSpecifiersList,
    isSupportMarketModel,
    refetchDescriptionEventMarkets,
  } = useEventMarketsDetails({
    eventId,
    competitors,
  });

  useSubscription(statsSubscription, {
    variables: { eventId },
    onData: ({ data }) => {
      if (data?.data?.Stats?.stats.length) {
        const { home, away } = data.data.Stats.stats[0].score;

        setValue('score.home', home, {
          shouldDirty: false,
          shouldTouch: false,
        });
        resetField(`score.home`, {
          defaultValue: home,
        });

        setValue('score.away', away, {
          shouldDirty: false,
          shouldTouch: false,
        });
        resetField(`score.away`, {
          defaultValue: away,
        });
      }
    },
    onError: e => console.log(e),
    skip: !eventId,
  });

  useSubscription(versionSubscription, {
    variables: { eventId },
    onData: ({ data, client }) => {
      const { version } = data.data.Version;

      const fragmentFields = {
        fragment: eventModelFragment,
        fragmentName: 'EventModelFragment',
      };

      const oldData = client.readFragment({
        id: `EventModel:${eventId}`,
        ...fragmentFields,
      });
      const newData = {
        ...oldData,
        version,
      };

      client.writeFragment({
        id: `EventModel:${eventId}`,
        ...fragmentFields,
        data: newData,
      });
      client.writeFragment({
        id: `EventTableRecord:${eventId}`,
        ...fragmentFields,
        data: newData,
      });
    },
    onError: e => console.log(e),
    skip: !eventId,
  });

  useSubscription(eventStateSubscription, {
    variables: { eventId },
    onData: ({ data, client }) => {
      const matchTime = data.data.State.state.match_timer.match_time;

      setValue('matchTime', matchTime, {
        shouldDirty: false,
        shouldTouch: false,
      });
      resetField(`matchTime`, {
        defaultValue: matchTime,
      });

      const fragmentFields = {
        fragment: eventModelFragment,
        fragmentName: 'EventModelFragment',
      };

      const oldData = client.readFragment({
        id: `EventModel:${eventId}`,
        ...fragmentFields,
      });
      const newData = {
        ...oldData,
        state: { ...data.data.State.state },
      };

      client.writeFragment({
        id: `EventModel:${eventId}`,
        ...fragmentFields,
        data: newData,
      });
      client.writeFragment({
        id: `EventTableRecord:${eventId}`,
        ...fragmentFields,
        data: newData,
      });
    },
    onError: e => console.log(e),
    skip: !eventId,
  });

  const resetForm = useCallback(async () => {
    const data = await defaultValues();
    reset(data);
  }, [reset, defaultValues]);

  const prepareCustomInput = useCallback(
    value => ({
      name: value,
    }),
    []
  );

  const onCreate = useCallback(
    async ({ data, isPrepareNew = false }) => {
      const {
        sport,
        category,
        tournament,
        scheduled,
        competitors,
        status,
        marginLadder,
        type,
      } = data;

      try {
        const res = await createEvent({
          variables: {
            fixture: {
              sport,
              category,
              tournament,
              scheduled: formatToDatetimeString(scheduled),
              competitors,
              type,
            },
            status,
            ladderId: marginLadder?.id,
          },
        });

        if (res.data?.createEvent?.id) {
          const { category, tournament } = res.data.createEvent.fixture;

          setValue('category', category, {
            shouldDirty: false,
            shouldTouch: false,
          });
          setValue('tournament', tournament, {
            shouldDirty: false,
            shouldTouch: false,
          });

          if (isPrepareNew) {
            setValue('competitors', [null, null], {
              shouldDirty: false,
              shouldTouch: false,
            });
          } else {
            navigate({
              pathname: `/trading-events/${res.data.createEvent.id}`,
              search: searchParams.toString(),
            });
            setActiveTab(EVENT_TABS.trading);
          }
          refetchEvents();
          showToast(
            <NotificationAlert variant={AlertVariant.SUCCESS}>
              {`Event was successfully created`}
            </NotificationAlert>
          );
        }
      } catch (e) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred on event saving: ${e.message}`}
          </NotificationAlert>
        );
      }
    },
    [
      createEvent,
      setValue,
      navigate,
      searchParams,
      setActiveTab,
      refetchEvents,
      showToast,
    ]
  );

  const onUpdate = useCallback(
    async ({ data, isPrepareNew = false }) => {
      const {
        sport,
        category,
        tournament,
        scheduled,
        competitors,
        status,
        score,
        marginLadder,
        type,
      } = data;

      try {
        const res = await updateEvent({
          variables: {
            id: eventId,
            fixture: {
              sport,
              category,
              tournament,
              scheduled: formatToDatetimeString(scheduled),
              competitors,
              type,
            },
            ladderId: marginLadder?.id,
            status,
            stats: [
              {
                period: 'p1', //hardcoded for v1
                type: 'goals', //hardcoded for v1
                score,
              },
            ],
            version: eventData.version,
          },
        });

        if (res.data?.updateEvent?.id) {
          if (isPrepareNew) {
            setValue('competitors', [null, null], {
              shouldDirty: false,
              shouldTouch: false,
            });
            navigate({
              pathname: `/trading-events/create`,
              search: searchParams.toString(),
            });
          } else if (
            [EVENT_STATUSES.finished, EVENT_STATUSES.canceled].includes(status)
          ) {
            navigate({
              pathname: `/trading-events`,
              search: searchParams.toString(),
            });
          } else {
            resetForm();
          }
          refetchEvents();
          showToast(
            <NotificationAlert variant={AlertVariant.SUCCESS}>
              {`Event was successfully updated`}
            </NotificationAlert>
          );
        }
      } catch (e) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred on event updating: ${e.message}`}
          </NotificationAlert>
        );
      }
    },
    [
      updateEvent,
      eventId,
      setValue,
      navigate,
      searchParams,
      resetForm,
      refetchEvents,
      showToast,
      eventData,
    ]
  );

  const onUpdateScore = useCallback(
    async (data, key) => {
      const { score } = data;
      const {
        sport,
        category,
        tournament,
        scheduled,
        competitors,
        status,
        marginLadder,
        type,
      } = currentDefaultValues;

      try {
        const res = await updateEvent({
          variables: {
            id: eventId,
            fixture: {
              sport,
              category,
              tournament,
              scheduled: formatToDatetimeString(scheduled),
              competitors,
              type,
            },
            ladderId: marginLadder?.id,
            status,
            stats: [
              {
                period: 'p1', //hardcoded for v1
                type: 'goals', //hardcoded for v1
                score: { ...currentDefaultValues.score, [key]: score[key] },
              },
            ],
            version: eventData.version,
          },
        });

        if (res.data?.updateEvent?.id) {
          resetField(`score.${key}`, {
            defaultValue: score[key],
          });
        }
      } catch (e) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred on score updating: ${e.message}`}
          </NotificationAlert>
        );
      }
    },
    [
      currentDefaultValues,
      updateEvent,
      eventId,
      resetField,
      showToast,
      eventData,
    ]
  );

  const onCalcOutcomes = useCallback(async () => {
    try {
      const res = await calcEventOutcomes({
        variables: {
          eventId,
          version: eventData.version,
        },
      });

      if (res.data?.calcEventOutcomes?.id) {
        resetForm();
        showToast(
          <NotificationAlert variant={AlertVariant.SUCCESS}>
            {`Outcomes recalculated`}
          </NotificationAlert>
        );
      }
    } catch (e) {
      showToast(
        <NotificationAlert variant={AlertVariant.ERROR}>
          {`An error occurred on calculating outcomes: ${e.message}`}
        </NotificationAlert>
      );
    }
  }, [calcEventOutcomes, eventId, resetForm, showToast, eventData]);

  const onToggleMarkets = useCallback(
    async status => {
      try {
        const res = await enableEventMarkets({
          variables: {
            eventId,
            status,
            version: eventData.version,
          },
        });

        if (res) {
          await resetForm();
        }
      } catch (e) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred on markets updating: ${e.message}`}
          </NotificationAlert>
        );
      }
    },
    [eventId, enableEventMarkets, showToast, eventData, resetForm]
  );

  const onToggleMarket = useCallback(
    async (data, marketId, status) => {
      const { markets } = data;

      try {
        const res = await enableEventMarket({
          variables: {
            eventId,
            marketId,
            status,
            version: eventData.version,
          },
        });

        if (res) {
          await resetForm();
        }
      } catch (e) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred on market updating: ${e.message}`}
          </NotificationAlert>
        );
      }
    },
    [eventId, enableEventMarket, showToast, eventData, resetForm]
  );

  const onToggleSpecifier = useCallback(
    async (data, marketId, specifier, status) => {
      const { markets } = data;

      try {
        const res = await enableMarketSpecifier({
          variables: {
            eventId,
            marketId,
            specifier: unprefixSpecifierName(specifier),
            status,
            version: eventData.version,
          },
        });

        if (res) {
          await resetForm();
        }
      } catch (e) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred on specifier updating: ${e.message}`}
          </NotificationAlert>
        );
      }
    },
    [eventId, enableMarketSpecifier, showToast, eventData, resetForm]
  );

  const onUpdateOutcome = useCallback(
    async (
      data,
      marketFieldName,
      outcomeFieldName,
      marketId,
      outcomeId,
      specifier
    ) => {
      const changedOutcome = { ...getValues(outcomeFieldName) };
      const coef = changedOutcome.k;

      try {
        const res = await setOutcomeCoef({
          variables: {
            eventId,
            marketId,
            specifier: unprefixSpecifierName(specifier),
            outcomeId,
            coef,
            version: eventData.version,
          },
        });

        if (res) {
          // IF margin ladder is applied and this market's specifiers has 2 outcomes - reset the whole market value,
          // otherwise reset only changed outcome value
          const changedMarket = res.data.setOutcomeCoef.markets.find(
            ({ id }) => id === marketId
          );
          const numberOfOutcomes = Object.values(
            Object.values(changedMarket.outcomes)[0]
          ).length;

          if (!!marginLadder && numberOfOutcomes === 2) {
            const changedMarketOutcomes = Object.keys(
              changedMarket.outcomes
            ).reduce((acc, specifier) => {
              acc[prefixSpecifierName(specifier)] =
                changedMarket.outcomes[specifier];
              return acc;
            }, {});

            resetField(marketFieldName, {
              defaultValue: {
                ...changedMarket,
                outcomes: changedMarketOutcomes,
              },
            });
          } else {
            resetField(outcomeFieldName, {
              defaultValue: { ...changedOutcome },
            });
          }
        }
      } catch (e) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred on outcome updating: ${e.message}`}
          </NotificationAlert>
        );
      }
    },
    [
      eventId,
      setOutcomeCoef,
      getValues,
      resetField,
      showToast,
      eventData,
      marginLadder,
    ]
  );

  const onCreateSpecifier = useCallback(
    async (marketId, specifiers, specifierValues) => {
      const newSpecifierName = specifiers
        .map(
          specifier => `${specifier}=${specifierValues[marketId][specifier]}`
        )
        .join('|');

      try {
        const res = await addMarketSpecifier({
          variables: {
            eventId,
            marketId,
            specifier: newSpecifierName,
            version: eventData.version,
          },
        });

        if (res) {
          await resetForm();
        }
      } catch (e) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred on specifier creating: ${e.message}`}
          </NotificationAlert>
        );
      }
    },
    [eventId, addMarketSpecifier, resetForm, showToast, eventData]
  );

  const onCreateFreeSpecifier = useCallback(
    async (marketId, specifierValues) => {
      try {
        const res = await addMarketFreeSpecifier({
          variables: {
            eventId,
            marketId,
            outcomeMame: specifierValues[marketId]['freeSpecifier'],
            version: eventData.version,
          },
        });

        if (res) {
          await refetchDescriptionEventMarkets();
          await resetForm();
        }
      } catch (e) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred on specifier creating: ${e.message}`}
          </NotificationAlert>
        );
      }
    },
    [
      eventId,
      addMarketFreeSpecifier,
      refetchDescriptionEventMarkets,
      resetForm,
      showToast,
      eventData,
    ]
  );

  const onRemoveSpecifier = useCallback(
    async (marketId, specifier) => {
      try {
        const res = await removeMarketSpecifier({
          variables: {
            eventId,
            marketId,
            specifier,
            version: eventData.version,
          },
        });

        if (res) {
          await resetForm();
        }
      } catch (e) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred on specifier removing: ${e.message}`}
          </NotificationAlert>
        );
      }
    },
    [eventId, removeMarketSpecifier, resetForm, showToast, eventData]
  );

  const onSetMainSpecifier = useCallback(
    async (marketId, specifier) => {
      try {
        const res = await setMarketMainSpecifier({
          variables: {
            eventId,
            marketId,
            specifier,
            version: eventData.version,
          },
        });

        if (res) {
          await resetForm();
        }
      } catch (e) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred on main market updating: ${e.message}`}
          </NotificationAlert>
        );
      }
    },
    [eventId, setMarketMainSpecifier, resetForm, showToast, eventData]
  );

  const onChangeMarketName = useCallback(
    async (marketId, value) => {
      try {
        const res = await changeMarketName({
          variables: {
            eventId,
            marketId,
            name: value,
            version: eventData.version,
          },
        });

        if (res) {
          await refetchDescriptionEventMarkets();
          await resetForm();
        }
      } catch (e) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred on market name change: ${e.message}`}
          </NotificationAlert>
        );
      }
    },
    [
      eventId,
      changeMarketName,
      refetchDescriptionEventMarkets,
      resetForm,
      showToast,
      eventData,
    ]
  );

  const onStartTimer = useCallback(async () => {
    try {
      await startMatchTimer({
        variables: {
          eventId,
          version: eventData.version,
        },
      });
    } catch (e) {
      showToast(
        <NotificationAlert variant={AlertVariant.ERROR}>
          {`An error occurred on timer start: ${e.message}`}
        </NotificationAlert>
      );
    }
  }, [eventId, eventData, startMatchTimer, showToast]);

  const onStopTimer = useCallback(async () => {
    try {
      await stopMatchTimer({
        variables: {
          eventId,
          version: eventData.version,
        },
      });
    } catch (e) {
      showToast(
        <NotificationAlert variant={AlertVariant.ERROR}>
          {`An error occurred on timer stop: ${e.message}`}
        </NotificationAlert>
      );
    }
  }, [eventId, eventData, stopMatchTimer, showToast]);

  const onSetTimer = useCallback(
    async value => {
      try {
        const res = await setMatchTimer({
          variables: {
            eventId,
            version: eventData.version,
            matchTime: value,
          },
        });

        if (res) {
          resetField('matchTime', {
            defaultValue: value,
          });
        }
      } catch (e) {
        showToast(
          <NotificationAlert variant={AlertVariant.ERROR}>
            {`An error occurred on timer set: ${e.message}`}
          </NotificationAlert>
        );
      }
    },
    [eventId, eventData, resetField, setMatchTimer, showToast]
  );

  const onAddRobotMarketClick = () => {
    open({
      component: AddRobotMarketForm,
      props: {
        markets: Object.values(markets).filter(({ id }) =>
          isSupportMarketModel({ marketId: id })
        ),
        onCancel: close,
        onSubmit: (marketId, specifier) => {
          onSetMainSpecifier(marketId, specifier);
          close();
        },
      },
      options: {
        title: 'Robot market setting',
        onClose: close,
      },
    });
  };

  const onAddOutcomeClick = ({ marketId, specifiers }) => {
    open({
      component: CreateSpecifierForm,
      props: {
        onCancel: close,
        onSubmit: specifierValues => {
          onCreateSpecifier(marketId, specifiers, specifierValues);
          close();
        },
        marketId,
        specifiers,
        isFreeSpecifier: false,
        form,
      },
      options: {
        title: 'Outcome adding',
        onClose: close,
      },
    });
  };

  const onAddMarketFreeSpecifierClick = ({ marketId }) => {
    open({
      component: CreateSpecifierForm,
      props: {
        onCancel: close,
        onSubmit: specifierValues => {
          onCreateFreeSpecifier(marketId, specifierValues);
          close();
        },
        marketId,
        isFreeSpecifier: true,
        form,
      },
      options: {
        title: 'Free Specifier adding',
        onClose: close,
      },
    });
  };

  const onExpandMarketButtonClick = useCallback(
    marketId => {
      if (expandedMarkets.includes(marketId)) {
        setExpandedMarkets(expandedMarkets.filter(v => v !== marketId));
      } else {
        setExpandedMarkets([...expandedMarkets, marketId]);
      }
    },
    [expandedMarkets, setExpandedMarkets]
  );

  const onEditMarketNameClick = marketId => {
    open({
      component: ChangeMarketNameForm,
      props: {
        onCancel: close,
        onSubmit: value => {
          onChangeMarketName(marketId, value);
          close();
        },
      },
      options: {
        title: 'Market name change',
        onClose: close,
      },
    });
  };

  const categoryHint =
    category && category.name && !category.id
      ? `Will be added as new Category.`
      : null;

  const tournamentHint =
    tournament && tournament.name && !tournament.id
      ? `Will be added as new Tournament.`
      : null;

  const getCompetitorHint = i =>
    competitors[i] && competitors[i].name && !competitors[i].id
      ? `Will be added as new Competitor.`
      : null;

  const isMainFieldsDirty = Object.keys(dirtyFields).some(field =>
    [
      'sport',
      'category',
      'tournament',
      'scheduled',
      'status',
      'competitors',
      'score',
      'marginLadder',
      'type',
    ].includes(field)
  );

  const isOutcomeDirty = outcomeFieldName => {
    return getFieldState(outcomeFieldName).isDirty;
  };

  const isTimerDirty = getFieldState('matchTime').isDirty;
  const isTimerRunning = eventData?.state.match_timer?.started;

  const isShowRevertButton =
    !isCreating &&
    isMainFieldsDirty &&
    initialStatus !== EVENT_STATUSES.finished;

  const isActionButtonDisabled =
    !isMainFieldsDirty || createEventLoading || updateEventLoading;
  !(
    sport &&
    category &&
    tournament &&
    scheduled &&
    competitors.every(c => !!c)
  );

  // TODO: delete isShowScore and score inputs after 22.07,2024 if still no needed
  // const isShowScore =
  //   status === EVENT_STATUSES.inProgress || status === EVENT_STATUSES.finished;
  const isShowScore = false;

  const isEventUntradable =
    initialStatus === EVENT_STATUSES.finished ||
    initialStatus === EVENT_STATUSES.canceled;

  const statusSelectOptions = useMemo(() => {
    const creationFlowOptions = [
      {
        label: 'Draft',
        value: EVENT_STATUSES.draft,
      },
      {
        label: 'Prematch',
        value: EVENT_STATUSES.prematch,
      },
      {
        label: 'In progress',
        value: EVENT_STATUSES.inProgress,
      },
    ];

    const editingFlowOptions = [
      ...creationFlowOptions,
      {
        label: 'Finished',
        value: EVENT_STATUSES.finished,
      },
      {
        label: 'Canceled',
        value: EVENT_STATUSES.canceled,
      },
    ];

    return isCreating ? creationFlowOptions : editingFlowOptions;
  }, [isCreating]);

  const typeSelectOptions = [
    {
      label: 'Match',
      value: EVENT_TYPES.match,
    },
    {
      label: 'Stage',
      value: EVENT_TYPES.stage,
    },
    {
      label: 'Season',
      value: EVENT_TYPES.season,
    },
    {
      label: 'Tournament',
      value: EVENT_TYPES.tournament,
    },
  ];

  useEffect(() => {
    defaultValues().then(data => {
      setInitialStatus(data.status);
    });
  }, [defaultValues]);

  useEffect(() => {
    resetForm();
  }, [eventId, resetForm]);

  return (
    <form noValidate>
      <FormProvider {...form}>
        {activeTab === EVENT_TABS.settings && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormGroup large>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className={classes.formGroupLabel}>
                      Event settings
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <FormField name={'sport'}>
                      <FancyAutocomplete
                        gqlQuery={SPORTS_AUTOCOMPLETE_QUERY}
                        label="Sport"
                        valueKey="id"
                        labelKey="name"
                        multiple={false}
                        disabled={isEventUntradable}
                        checkValueOptions={false}
                      />
                    </FormField>
                  </Grid>

                  <Grid item xs={12}>
                    <FormField name={'category'} deps={{ sport: 'sport' }}>
                      {({ sport = {} }) => (
                        <FancyAutocomplete
                          gqlQuery={CATEGORIES_AUTOCOMPLETE_QUERY}
                          params={{ sportId: sport?.id }}
                          skipQuery={!sport?.id}
                          label="Category"
                          valueKey="id"
                          labelKey="name"
                          multiple={false}
                          allowCustomValueInput
                          prepareCustomInput={prepareCustomInput}
                          disabled={isEventUntradable}
                          hint={categoryHint}
                        />
                      )}
                    </FormField>
                  </Grid>

                  <Grid item xs={12}>
                    <FormField
                      name={'tournament'}
                      deps={{ category: 'category' }}
                    >
                      {({ category = {} }) => (
                        <FancyAutocomplete
                          gqlQuery={TOURNAMENTS_AUTOCOMPLETE_QUERY}
                          params={{ categoryId: category?.id }}
                          skipQuery={!category?.id}
                          label="Tournament"
                          valueKey="id"
                          labelKey="name"
                          multiple={false}
                          allowCustomValueInput
                          prepareCustomInput={prepareCustomInput}
                          disabled={isEventUntradable}
                          hint={tournamentHint}
                        />
                      )}
                    </FormField>
                  </Grid>

                  <Grid item xs={12}>
                    <FormField name={'scheduled'}>
                      <DateTimeUtcInput
                        required
                        fullWidth
                        error={!!errors.date}
                        label={'Date and Time'}
                        helperText={errors.date?.message}
                        variant="outlined"
                        disabled={isEventUntradable}
                        withStartAdornment
                      />
                    </FormField>
                  </Grid>

                  <Grid item xs={12}>
                    <FormField
                      name={'status'}
                      onChange={v => {
                        if (v !== EVENT_STATUSES.prematch) {
                          clearErrors('scheduled');
                        }
                      }}
                    >
                      <Select
                        label={'Status'}
                        disabled={isEventUntradable}
                        disableClearable
                        options={statusSelectOptions}
                      />
                    </FormField>
                  </Grid>

                  <Grid item xs={12}>
                    <FormField name={'type'}>
                      <Select
                        label={'Event Type'}
                        disabled={isEventUntradable || !isCreating}
                        disableClearable
                        options={typeSelectOptions}
                      />
                    </FormField>
                  </Grid>

                  <Grid item xs={12}>
                    <FormField name={'marginLadder'} deps={{ sport: 'sport' }}>
                      {({ sport = {} }) => (
                        <FancyAutocomplete
                          gqlQuery={marginLaddersAutocompleteQuery}
                          params={{ sportId: sport?.id }}
                          label="Margin Ladders"
                          valueKey="id"
                          labelKey="name"
                          multiple={false}
                          disabled={isEventUntradable}
                          alwaysShowClearButton
                        />
                      )}
                    </FormField>
                  </Grid>
                </Grid>
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <FormGroup large>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item xs={12}>
                    <Typography className={classes.formGroupLabel}>
                      Competitors
                    </Typography>
                  </Grid>

                  <Grid item xs={isShowScore ? 10 : 12}>
                    <FormField name={'competitors.0'} deps={{ sport: 'sport' }}>
                      {({ sport = {} }) => (
                        <FancyAutocomplete
                          gqlQuery={COMPETITORS_AUTOCOMPLETE_QUERY}
                          params={{ sportId: sport?.id }}
                          skipQuery={!sport?.id}
                          label="Competitor 1"
                          valueKey="id"
                          labelKey="name"
                          uniqueKey={['id', 'name']}
                          multiple={false}
                          allowCustomValueInput
                          prepareCustomInput={prepareCustomInput}
                          disabled={isEventUntradable}
                          hint={getCompetitorHint(0)}
                        />
                      )}
                    </FormField>
                  </Grid>

                  {isShowScore && (
                    <Grid item xs={2}>
                      <FormField name={'score.home'}>
                        <NumberField
                          step={1}
                          min={0}
                          disabled={isEventUntradable}
                        />
                      </FormField>
                    </Grid>
                  )}

                  <Grid item xs={isShowScore ? 10 : 12}>
                    <FormField name={'competitors.1'} deps={{ sport: 'sport' }}>
                      {({ sport = {} }) => (
                        <FancyAutocomplete
                          gqlQuery={COMPETITORS_AUTOCOMPLETE_QUERY}
                          params={{ sportId: sport?.id }}
                          skipQuery={!sport?.id}
                          label="Competitor 2"
                          valueKey="id"
                          labelKey="name"
                          uniqueKey={['id', 'name']}
                          multiple={false}
                          allowCustomValueInput
                          prepareCustomInput={prepareCustomInput}
                          disabled={isEventUntradable}
                          hint={getCompetitorHint(1)}
                        />
                      )}
                    </FormField>
                  </Grid>

                  {isShowScore && (
                    <Grid item xs={2}>
                      <FormField name={'score.away'}>
                        <NumberField
                          step={1}
                          min={0}
                          disabled={isEventUntradable}
                        />
                      </FormField>
                    </Grid>
                  )}
                </Grid>
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <FormGroup large>
                <Grid
                  container
                  spacing={1}
                  justifyContent="end"
                  alignItems="center"
                >
                  <Grid item>
                    <Button
                      color={'lightBlue'}
                      onClick={onBackButtonClick}
                      className={classes.formButton}
                    >
                      Cancel
                    </Button>
                  </Grid>

                  {isShowRevertButton && (
                    <Grid item>
                      <Button
                        color={'lightBlue'}
                        onClick={resetForm}
                        className={classes.formButton}
                      >
                        Revert
                      </Button>
                    </Grid>
                  )}

                  <Grid item>
                    <Button
                      type={'submit'}
                      color={'lightBlue'}
                      onClick={e => {
                        handleSubmit(
                          isCreating
                            ? data => onCreate({ data, isPrepareNew: true })
                            : data => onUpdate({ data, isPrepareNew: true })
                        )(e);
                      }}
                      disabled={isActionButtonDisabled}
                      loading={createEventLoading || updateEventLoading}
                      className={classes.formButton}
                    >
                      Save & create new
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      type={'submit'}
                      color={'primary'}
                      onClick={e => {
                        handleSubmit(
                          isCreating
                            ? data => onCreate({ data })
                            : data => onUpdate({ data })
                        )(e);
                      }}
                      disabled={isActionButtonDisabled}
                      loading={createEventLoading || updateEventLoading}
                      className={classes.formButton}
                    >
                      {isCreating ? 'Create' : 'Update'}
                    </Button>
                  </Grid>
                </Grid>
              </FormGroup>
            </Grid>
          </Grid>
        )}

        {activeTab === EVENT_TABS.trading && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {eventType === EVENT_TYPES.match && (
                <FormGroup large>
                  <Grid container spacing={4}>
                    {/*<Grid item xs={4}>*/}
                    {/*  <Typography*/}
                    {/*    className={clsx(*/}
                    {/*      classes.formGroupLabel,*/}
                    {/*      classes.formGroupLabelOnTop*/}
                    {/*    )}*/}
                    {/*  >*/}
                    {/*    Event Status*/}
                    {/*  </Typography>*/}
                    {/*  <Select*/}
                    {/*    label={'Event Status'}*/}
                    {/*    disableClearable*/}
                    {/*    initialValue={{*/}
                    {/*      label: '1sh half',*/}
                    {/*      value: '1sh half',*/}
                    {/*    }}*/}
                    {/*    options={[*/}
                    {/*      {*/}
                    {/*        label: '1sh half',*/}
                    {/*        value: '1sh half',*/}
                    {/*      },*/}
                    {/*      {*/}
                    {/*        label: '2nd half',*/}
                    {/*        value: '2nd half',*/}
                    {/*      },*/}
                    {/*      {*/}
                    {/*        label: 'placeholder',*/}
                    {/*        value: 'placeholder',*/}
                    {/*      },*/}
                    {/*    ]}*/}
                    {/*    disabled={*/}
                    {/*      currentDefaultValues?.status !==*/}
                    {/*      EVENT_STATUSES.inProgress*/}
                    {/*    }*/}
                    {/*  />*/}
                    {/*</Grid>*/}
                    <Grid item xs={8}>
                      <Typography
                        className={clsx(
                          classes.formGroupLabel,
                          classes.formGroupLabelOnTop
                        )}
                      >
                        Timer
                      </Typography>
                      <FormField name={'matchTime'}>
                        <TradingEventTimer
                          onStart={onStartTimer}
                          onStop={onStopTimer}
                          disabled={
                            currentDefaultValues?.status !==
                            EVENT_STATUSES.inProgress
                          }
                          isRunning={isTimerRunning}
                          isShowSetButton={isTimerDirty && !isTimerRunning}
                          onSetButtonClick={onSetTimer}
                        />
                      </FormField>
                    </Grid>
                  </Grid>
                </FormGroup>
              )}

              <FormGroup large>
                <Grid item xs={12}>
                  <Typography className={classes.formGroupLabel}>
                    Scoreboard
                  </Typography>
                </Grid>
                <TradingEventScoreboard
                  form={form}
                  isDisabled={isEventUntradable}
                  onUpdateScore={onUpdateScore}
                />
              </FormGroup>

              <FormGroup large>
                <Grid item xs={12} className={classes.formGroupMarketsTitle}>
                  <Typography className={classes.formGroupLabel}>
                    Robot
                  </Typography>
                  <Button
                    color={'primary'}
                    onClick={onCalcOutcomes}
                    loading={calcEventOutcomesLoading}
                    className={clsx(
                      classes.formButton,
                      classes.robotCalculateButton
                    )}
                  >
                    Calculate
                  </Button>
                  <Button
                    color={'lightBlue'}
                    onClick={onAddRobotMarketClick}
                    className={clsx(classes.formButton, classes.robotAddButton)}
                  >
                    Set Market
                  </Button>
                </Grid>

                {eventData && (
                  <Grid item xs={12}>
                    <div className={classes.mainSpecifiersList}>
                      {eventData.markets
                        .filter(({ main_specifier }) => !isNil(main_specifier))
                        .map(market => {
                          const mainSpecifier = market.main_specifier;

                          return (
                            <div className={classes.market}>
                              <div
                                className={clsx(
                                  classes.marketName,
                                  classes.marketNameMain
                                )}
                              >
                                {getMarketName({ marketId: market.id })}
                              </div>
                              <TradingEventSpecifiersList
                                market={market}
                                markets={markets}
                                specifiers={{
                                  [mainSpecifier]:
                                    market.outcomes[mainSpecifier],
                                }}
                                form={form}
                                getSpecifiersList={getSpecifiersList}
                                isEventUntradable={isEventUntradable}
                                getOutcomeName={getOutcomeName}
                                isOutcomeDirty={isOutcomeDirty}
                                onToggleSpecifier={onToggleSpecifier}
                                onUpdateOutcome={onUpdateOutcome}
                                isUpdateOutcomeLoading={setOutcomeCoefLoading}
                                onRemoveSpecifier={onRemoveSpecifier}
                                onAddOutcomeClick={onAddOutcomeClick}
                                onAddMarketFreeSpecifierClick={
                                  onAddMarketFreeSpecifierClick
                                }
                                isSpecifierEditable={isSpecifierEditable}
                                isFreeTextMarket={isFreeTextMarket}
                                isMain
                                marginLadderValues={marginLadderValues}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </Grid>
                )}
              </FormGroup>

              <FormGroup large>
                <Grid item xs={12} className={classes.formGroupMarketsTitle}>
                  <Switch
                    onChange={onToggleMarkets}
                    value={!eventData?.blocked_markets}
                    className={classes.formGroupMarketsSwitch}
                  />
                  <Typography className={classes.formGroupLabel}>
                    Markets
                  </Typography>
                </Grid>

                {eventData && (
                  <>
                    <Grid item xs={12}>
                      <Tabs
                        activeTabKey={selectedMarketsTabId}
                        onChangeTab={key => setSelectedMarketsTabId(key)}
                        tabs={[
                          {
                            key: ALL_MARKETS_TAB_VALUE,
                            label: 'All markets',
                          },
                          ...eventData.markets.map(market => {
                            return {
                              key: market.id,
                              label: getMarketName({ marketId: market.id }),
                              content: (
                                <div className={classes.marketName}>
                                  <FormField
                                    name={`markets.${[market.id]}.enabled`}
                                    onChange={e =>
                                      handleSubmit(data =>
                                        onToggleMarket(data, market.id, e)
                                      )(e)
                                    }
                                  >
                                    <Checkbox disabled={isEventUntradable} />
                                  </FormField>
                                  {`${getMarketName({
                                    marketId: market.id,
                                  })}`}
                                  {isFreeTextMarket({
                                    marketId: market.id,
                                  }) && (
                                    <div
                                      className={classes.marketNameEdit}
                                      onClick={() =>
                                        onEditMarketNameClick(market.id)
                                      }
                                    >
                                      Change name
                                    </div>
                                  )}
                                </div>
                              ),
                            };
                          }),
                        ]}
                        withoutSpacing
                      />
                    </Grid>

                    {selectedMarketsTabId === ALL_MARKETS_TAB_VALUE ? (
                      <div className={classes.allMarkets}>
                        {eventData.markets.map(market => {
                          return (
                            <div className={classes.market}>
                              <div
                                className={clsx(
                                  classes.marketName,
                                  classes.marketNameInList
                                )}
                              >
                                <FormField
                                  name={`markets.${[market.id]}.enabled`}
                                  onChange={e =>
                                    handleSubmit(data =>
                                      onToggleMarket(data, market.id, e)
                                    )(e)
                                  }
                                >
                                  <Checkbox disabled={isEventUntradable} />
                                </FormField>
                                <div
                                  className={classes.marketNameClickable}
                                  onClick={() =>
                                    onExpandMarketButtonClick(market.id)
                                  }
                                >
                                  {`${getMarketName({
                                    marketId: market.id,
                                  })}`}
                                  <ExpandIcon
                                    className={classes.expandIcon}
                                    expanded={expandedMarkets.includes(
                                      market.id
                                    )}
                                  />
                                </div>
                                {isFreeTextMarket({
                                  marketId: market.id,
                                }) && (
                                  <div
                                    className={classes.marketNameEdit}
                                    onClick={() =>
                                      onEditMarketNameClick(market.id)
                                    }
                                  >
                                    Change name
                                  </div>
                                )}
                              </div>
                              {expandedMarkets.includes(market.id) && (
                                <TradingEventSpecifiersList
                                  market={market}
                                  markets={markets}
                                  specifiers={market.outcomes}
                                  form={form}
                                  getSpecifiersList={getSpecifiersList}
                                  isEventUntradable={isEventUntradable}
                                  getOutcomeName={getOutcomeName}
                                  isOutcomeDirty={isOutcomeDirty}
                                  onToggleSpecifier={onToggleSpecifier}
                                  onUpdateOutcome={onUpdateOutcome}
                                  isUpdateOutcomeLoading={setOutcomeCoefLoading}
                                  onRemoveSpecifier={onRemoveSpecifier}
                                  onAddOutcomeClick={onAddOutcomeClick}
                                  onAddMarketFreeSpecifierClick={
                                    onAddMarketFreeSpecifierClick
                                  }
                                  isSpecifierEditable={isSpecifierEditable}
                                  isFreeTextMarket={isFreeTextMarket}
                                  marginLadderValues={marginLadderValues}
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      selectedMarket && (
                        <TradingEventSpecifiersList
                          market={selectedMarket}
                          markets={markets}
                          specifiers={selectedMarket.outcomes}
                          form={form}
                          getSpecifiersList={getSpecifiersList}
                          isEventUntradable={isEventUntradable}
                          getOutcomeName={getOutcomeName}
                          isOutcomeDirty={isOutcomeDirty}
                          onToggleSpecifier={onToggleSpecifier}
                          onUpdateOutcome={onUpdateOutcome}
                          isUpdateOutcomeLoading={setOutcomeCoefLoading}
                          onRemoveSpecifier={onRemoveSpecifier}
                          isSpecifierEditable={isSpecifierEditable}
                          onAddOutcomeClick={onAddOutcomeClick}
                          onAddMarketFreeSpecifierClick={
                            onAddMarketFreeSpecifierClick
                          }
                          isFreeTextMarket={isFreeTextMarket}
                          marginLadderValues={marginLadderValues}
                        />
                      )
                    )}
                  </>
                )}
              </FormGroup>
              <DialogProvider />
            </Grid>
          </Grid>
        )}
      </FormProvider>
    </form>
  );
};

export default TradingEventEditForm;
