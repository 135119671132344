import Box from '@mui/material/Box';

const TabContent = ({ children, sx = {} }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        borderRadius: '4px',
        padding: '24px 16px',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default TabContent;
