import { gql } from '@apollo/client';
import matchTimerFragment from '../fragments/matchTimerFragment';

const eventStateSubscription = gql`
  ${matchTimerFragment}
  subscription State($eventId: EventId!) {
    State(event_id: $eventId) {
      event_id
      state {
        status
        match_timer {
          ...MatchTimerFragment
        }
      }
    }
  }
`;

export default eventStateSubscription;
