import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import TextField from '../../../../components/Inputs/TextField';
import ArrowRightSmall from '../../../../../icons/controls/ArrowRightSmall';
import Highlighted from '../../../../components/Highlighted/Highlighed';
import TOURNAMENTS_FILTER_AUTOCOMPLETE_QUERY from '../../../../gql/TradingTool/queries/TOURNAMENTS_FILTER_AUTOCOMPLETE_QUERY';
import TradingToolSidebarTournament from './TradingToolSidebarTournament';
import sortByEventsCount from '../../utils/sortByEventsCount';

const useStyles = makeStyles({
  tradingToolSidebarCategory: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 6,
  },
  categoryLine: {
    padding: '12px 0 12px 24px',
    fontSize: 12,
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowRight: {
    color: '#1581FF',
    width: 12,
    height: 12,
    transition: 'transform 0.1s linear',
  },
  arrowRightOpened: {
    transform: 'rotate(90deg)',
  },
  categoryName: {
    marginLeft: 4,
    marginRight: 4,
    fontWeight: 600,
  },
  selectedLine: {
    backgroundColor: '#E8F2FF',
    transition: 'backgroundColor 0.1s linear',
    borderRadius: 4,
  },
  tournamentSearchField: {
    marginTop: 6,
    marginBottom: 6,
  },
});

const TradingToolSidebarCategory = ({
  openSport,
  sport,
  category,
  categorySearchValue,
  selectedCategory,
  setSelectedCategory,
  selectedTournament,
  setSelectedTournament,
  onCategorySelected,
}) => {
  const classes = useStyles();
  const [isOpened, setOpened] = useState(false);
  const [tournamentSearchValue, setTournamentSearchValue] = useState('');

  const { data: { data: tournaments = [] } = {} } = useQuery(
    TOURNAMENTS_FILTER_AUTOCOMPLETE_QUERY,
    {
      variables: {
        sportId: sport.id,
        categoryId: category.id,
      },
      fetchPolicy: 'cache-and-network',
      skip: !isOpened || !openSport,
    }
  );

  const handleCategorySelected = useCallback(() => {
    if (category.id !== selectedCategory?.id) {
      onCategorySelected();
      setSelectedCategory({ id: category.id, name: category.name });

      if (!isOpened) {
        setOpened(true);
      }
    } else {
      setSelectedCategory(null);
    }

    setSelectedTournament(null);
  }, [
    category,
    selectedCategory,
    setSelectedCategory,
    setSelectedTournament,
    onCategorySelected,
    isOpened,
    setOpened,
  ]);

  const onTournamentSelected = useCallback(() => {
    onCategorySelected();
    setSelectedCategory({ id: category.id, name: category.name });
  }, [category, setSelectedCategory, onCategorySelected]);

  return (
    <Box className={classes.tradingToolSidebarCategory}>
      <span
        className={clsx(classes.categoryLine, {
          [classes.selectedLine]: category.id === selectedCategory?.id,
        })}
        onClick={handleCategorySelected}
      >
        <ArrowRightSmall
          className={clsx(classes.arrowRight, {
            [classes.arrowRightOpened]: isOpened,
          })}
          onClick={e => {
            e.stopPropagation();
            setOpened(!isOpened);
          }}
        />
        <span className={classes.categoryName}>
          <Highlighted highlight={categorySearchValue}>
            {category.name}
          </Highlighted>
        </span>
        {`(${category.active_events_count})`}
      </span>
      {isOpened && (
        <>
          <TextField
            label="Tournament"
            value={tournamentSearchValue}
            onChange={e => setTournamentSearchValue(e.target.value)}
            withSearchIcon
            clearButton
            onClear={() => setTournamentSearchValue('')}
            className={classes.tournamentSearchField}
          />
          {sortByEventsCount(tournaments)
            .filter(({ name }) =>
              name.toLowerCase().includes(tournamentSearchValue.toLowerCase())
            )
            .map((tournament, index) => (
              <TradingToolSidebarTournament
                key={`${tournament.id}-${index}`}
                tournament={tournament}
                tournamentSearchValue={tournamentSearchValue}
                selectedTournament={selectedTournament}
                setSelectedTournament={setSelectedTournament}
                onTournamentSelected={onTournamentSelected}
              />
            ))}
        </>
      )}
    </Box>
  );
};

export default TradingToolSidebarCategory;
