import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import * as yup from 'yup';
import FancyTable from '../../components/Table/FancyTable';
import StringField from '../../components/Fields/StringField/StringField';
import TableHeaderRow from '../../components/Table/TableHeaderRow';
import useTableGrid from '../../hooks/useTableGrid';
import useTableColumnToggle from '../../hooks/useTableColumnToggle';
import useTableColumnsReorder from '../../hooks/useTableColumnsReorder';
import useTableRowsRender from '../../hooks/useTableRowsRender';
import useTableColumns from '../../hooks/useTableColumns';
import TextField from '../../components/Inputs/TextField';
import Button from '@mui/material/Button';
import LinkField from '../../components/Fields/LinkField/LinkField';
import Select from '../../components/Inputs/Select/Select';
import InlineFormField from '../../components/Form/InlineFormField';
import { tableNames } from '../../hooks/useLocalStorageTableConfig';
import Tabs from '../../components/Tabs/Tabs';
import TabContent from '../../components/Tabs/TabContent';
import Grid from '@mui/material/Grid';
import LabeledField from '../../components/Fields/LabeledField/LabeledField';
import EmptyField from '../../components/Fields/EmptyField/EmptyField';
import InfiniteTable from '../../components/Table/InfiniteTable';
import VirtualizedTable from '../../components/Table/VirtualizedTable';
import ExpandableTable from '../../components/Table/ExpandableTable';
import TableStickyHeader from '../../components/Table/TableStickyHeader';
import TableHeaderColumn from '../../components/Table/TableHeaderColumn';
import { expandCellStyles } from '../../components/Table/TableExpandCell';
import TableConfigMenu from '../../components/Table/TableConfigMenu';
import TableRow from '../../components/Table/TableRow';
import TableCell from '../../components/Table/TableCell';
import pick from 'lodash/pick';
import { omit } from 'lodash';
import CommandsTable from './CommandsTable';

const useStyles = makeStyles(() => ({
  resultSelect: {
    height: 30,
  },
  applyButton: {
    fontSize: 11,
    padding: '8px 24px',
    height: 30,
    minWidth: 'min(100%, 66px)',
  },
}));

const commonCellStyle = {
  padding: '0px 4px',
};
const centeredCellStyle = {
  ...commonCellStyle,
  justifyContent: 'center',
  textAlign: 'center',
};

export const COLUMN = {
  EVENT: 'event_id',
  MARKET_ID: 'market_id',
  SPORT: 'sport_id',
  MARKET_NAME: 'market_name',
  OUTCOME_NAME: 'outcome_name',
  SPECIFIERS: 'specifiers',
  OPEN_BETS: 'open_bets',
  OPEN_SINGLE_BETS: 'open_single_bets',
  RESULT: 'result',
  APPLY: 'apply',
  DEAD_HEAT_FACTOR: 'dead_heat_factor',
};

export const columnsWidthConfig = {
  [COLUMN.EVENT]: 1,
  [COLUMN.MARKET_ID]: 1,
  [COLUMN.SPORT]: 1,
  [COLUMN.MARKET_NAME]: 1,
  [COLUMN.OUTCOME_NAME]: 1,
  [COLUMN.SPECIFIERS]: 1,
  [COLUMN.OPEN_BETS]: 1,
  [COLUMN.OPEN_SINGLE_BETS]: 1,
  [COLUMN.RESULT]: 1,
  [COLUMN.APPLY]: 1,
  [COLUMN.DEAD_HEAT_FACTOR]: 1,
};

export const columnsOrderConfig = [
  COLUMN.EVENT,
  COLUMN.MARKET_ID,
  COLUMN.SPORT,
  COLUMN.MARKET_NAME,
  COLUMN.OUTCOME_NAME,
  COLUMN.SPECIFIERS,
  COLUMN.OPEN_BETS,
  COLUMN.OPEN_SINGLE_BETS,
  COLUMN.RESULT,
  COLUMN.APPLY,
  COLUMN.DEAD_HEAT_FACTOR,
];

export const initialToggledColumns = [
  COLUMN.EVENT,
  COLUMN.MARKET_ID,
  COLUMN.SPORT,
  COLUMN.MARKET_NAME,
  COLUMN.OUTCOME_NAME,
  COLUMN.SPECIFIERS,
  COLUMN.OPEN_BETS,
  COLUMN.OPEN_SINGLE_BETS,
  COLUMN.RESULT,
  COLUMN.APPLY,
  COLUMN.DEAD_HEAT_FACTOR,
];

const validationSchema = yup.object().shape({
  [COLUMN.RESULT]: yup.string(),
  [COLUMN.DEAD_HEAT_FACTOR]: yup.string().nullable(),
});

const resultSelectOptions = [
  { label: 'Win', value: 'win' },
  { label: 'Half-Win', value: 'half_win' },
  { label: 'Lost', value: 'lost' },
  { label: 'Half-Lost', value: 'half_lost' },
  { label: 'Refund', value: 'refund' },
  { label: 'Open', value: 'open' },
];

export const allOptions = [
  ...resultSelectOptions,
  { label: 'Canceled', value: 'canceled' },
];

const uniqueIdParts = [
  'outcome_id',
  'outcome_name',
  'market_id',
  'market_name',
  'specifiers',
];

const getUniqueOutcomeId = item => {
  return uniqueIdParts.reduce((acc, idPart) => {
    return `${acc}${item[idPart]}`;
  }, '');
};

const OutcomesTable = forwardRef(
  (
    {
      items,
      loading,
      tableOffsetTop,
      eventName,
      sportName,
      onUpdate,
      initialOrderDirection,
      orderBy,
      onChangeOrderBy = () => {},
    },
    ref
  ) => {
    const classes = useStyles();

    const [changedOutcomes, setChangedOutcomes] = useState({});

    const [toggledColumns, toggleColumn] = useTableColumnToggle(
      initialToggledColumns,
      null,
      null,
      tableNames.Outcomes
    );

    const [columnsOrder, onReorderColumns] =
      useTableColumnsReorder(columnsOrderConfig);

    const { template, changeColumnWidth } = useTableGrid({
      columns: columnsWidthConfig,
      order: columnsOrder,
      toggledColumns,
      onChangeColumnsWidth: () => {},
      expandable: true,
      rowActions: false,
    });

    const isShowApplyButton = useCallback(
      data => {
        return (
          !!changedOutcomes[data.outcome_id] &&
          !changedOutcomes[data.outcome_id].submitted
        );
      },
      [changedOutcomes]
    );

    const isDeadHeatFactorDisabled = useCallback(
      data => {
        const initialResult = data[COLUMN.RESULT];
        const changedResult =
          changedOutcomes[getUniqueOutcomeId(data)]?.[COLUMN.RESULT];

        return !resultSelectOptions.find(
          ({ value }) => value === (changedResult || initialResult)
        );
      },
      [changedOutcomes]
    );

    const { availableColumns, visibleColumns } = useTableColumns({
      columns: [
        {
          label: 'Event',
          key: COLUMN.EVENT,
          style: commonCellStyle,
          render: data => {
            return (
              <LinkField href={`/events/${data[COLUMN.EVENT]}`}>
                {eventName}
              </LinkField>
            );
          },
          resizable: true,
        },
        {
          label: 'Market Id',
          key: COLUMN.MARKET_ID,
          style: centeredCellStyle,
          render: data => {
            return <StringField>{data[COLUMN.MARKET_ID]}</StringField>;
          },
          resizable: true,
          sortkey: 'market_id',
        },
        {
          label: 'Sport',
          key: COLUMN.SPORT,
          style: commonCellStyle,
          render: () => {
            return <StringField>{sportName}</StringField>;
          },
          resizable: true,
          sortkey: 'sport_id',
        },
        {
          label: 'Market Name',
          key: COLUMN.MARKET_NAME,
          style: commonCellStyle,
          render: data => {
            return <StringField>{data[COLUMN.MARKET_NAME]}</StringField>;
          },
          resizable: true,
          sortkey: 'market_id',
        },
        {
          label: 'Outcome Name',
          key: COLUMN.OUTCOME_NAME,
          style: commonCellStyle,
          render: data => {
            return <StringField>{data[COLUMN.OUTCOME_NAME]}</StringField>;
          },
          resizable: true,
        },
        {
          label: 'Specifiers',
          key: COLUMN.SPECIFIERS,
          style: commonCellStyle,
          render: data => {
            return (
              <StringField>
                {data[COLUMN.SPECIFIERS] || 'No specifiers'}
              </StringField>
            );
          },
          resizable: true,
        },
        {
          label: 'Open Bets',
          key: COLUMN.OPEN_BETS,
          style: centeredCellStyle,
          render: data => {
            return (
              <LinkField
                href={`/api/v1/ReporterAPI/report/outcome/detail/${data.event_id}/${data.market_id}/${data.outcome_id}/get`}
              >
                {data[COLUMN.OPEN_BETS]}
              </LinkField>
            );
          },
          resizable: true,
          sortkey: 'open_bets',
        },
        {
          label: 'Open Single Bets',
          key: COLUMN.OPEN_SINGLE_BETS,
          style: centeredCellStyle,
          render: data => {
            return (
              <LinkField
                href={`/api/v1/ReporterAPI/report/outcome/detail/${data.event_id}/${data.market_id}/${data.outcome_id}/get`}
              >
                {data[COLUMN.OPEN_SINGLE_BETS]}
              </LinkField>
            );
          },
          resizable: true,
          sortkey: 'open_single_bets',
        },
        {
          label: 'Result',
          key: COLUMN.RESULT,
          style: {
            ...commonCellStyle,
            minWidth: 120,
          },
          render: data => {
            return (
              <Select
                label={null}
                multiple={false}
                options={resultSelectOptions}
                className={classes.resultSelect}
                size="small"
                disableClearable
                onChange={v => {
                  const isDifferentValue = v !== data.result;

                  if (isDifferentValue) {
                    setChangedOutcomes(prevState => ({
                      ...prevState,
                      [data.outcome_id]: {
                        ...prevState[data.outcome_id],
                        result: v,
                        submitted: false,
                      },
                    }));
                  } else {
                    setChangedOutcomes(prevState =>
                      omit(prevState, data.outcome_id)
                    );
                  }
                }}
                initialValue={{
                  label: allOptions.find(
                    option => option.value === data[COLUMN.RESULT]
                  )?.label,
                  value: data[COLUMN.RESULT],
                }}
              />
            );
          },
          resizable: true,
        },
        {
          label: 'Confirmation',
          isLabelHidden: true,
          key: COLUMN.APPLY,
          style: commonCellStyle,
          render: data => {
            return (
              isShowApplyButton(data) && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.applyButton}
                  onClick={() =>
                    onUpdate(
                      {
                        ...data,
                        result:
                          changedOutcomes[data.outcome_id]?.result ||
                          data.result,
                        dead_heat_factor:
                          changedOutcomes[data.outcome_id]?.dead_heat_factor ||
                          data.dead_heat_factor,
                      },
                      () =>
                        setChangedOutcomes(prevState => ({
                          ...prevState,
                          [data.outcome_id]: {
                            ...prevState[data.outcome_id],
                            submitted: true,
                          },
                        }))
                    )
                  }
                >
                  Apply
                </Button>
              )
            );
          },
          resizable: true,
        },
        {
          label: 'Dead Heat Factor',
          key: COLUMN.DEAD_HEAT_FACTOR,
          style: {
            ...centeredCellStyle,
            minWidth: 100,
          },
          render: data => {
            return (
              <TextField
                disabled={isDeadHeatFactorDisabled(data)}
                label={null}
                type="number"
                size="small"
                inputProps={{
                  min: '0',
                  step: '0.1',
                  max: '1',
                }}
                value={
                  changedOutcomes?.[data.outcome_id]?.dead_heat_factor ||
                  data.dead_heat_factor
                }
                onChange={e => {
                  const value = e.target.value;
                  const isDifferentValue = value !== data.dead_heat_factor;

                  if (isDifferentValue) {
                    setChangedOutcomes(prevState => ({
                      ...prevState,
                      [data.outcome_id]: {
                        ...prevState[data.outcome_id],
                        ...(value && {
                          dead_heat_factor: value,
                          submitted: false,
                        }),
                      },
                    }));
                  } else {
                    setChangedOutcomes(prevState =>
                      omit(prevState, data.outcome_id)
                    );
                  }
                }}
              />
            );
          },
          resizable: true,
        },
      ],
      order: columnsOrder,
      toggledColumns,
    });

    const renderCells = useCallback(
      rowData => {
        return availableColumns.map((column, index) => {
          return (
            <TableCell
              isLast={index === availableColumns.length - 1}
              key={column.key}
              deps={column.deps}
              rowData={rowData}
              cellClassName={column.cellClassName}
              render={column.render}
              style={column.style}
              keepMinWidth={column.keepMinWidth}
            />
          );
        });
      },
      [availableColumns]
    );

    const recomputeRowHeight = useCallback(
      () => ref && ref.current && ref.current.recalculateSizes(),
      [ref]
    );

    const renderRow = useCallback(
      ({ rowIndex, rowData, rowId, isVisible }) => {
        return (
          <TableRow
            gridTemplate={template}
            rowId={rowId}
            rowIndex={rowIndex}
            rowData={rowData}
            renderCells={renderCells}
            isVisible={isVisible}
            bulk={false}
            recomputeRowHeight={recomputeRowHeight}
          />
        );
      },
      [template, recomputeRowHeight, renderCells]
    );

    const renderExpandedRow = useCallback((rowIndex, data) => {
      const filters = {
        event_id: data.event_id,
        market_id: data.market_id,
        outcome_id: data.outcome_id,
      };

      const contentStyles = {
        background: 'transparent',
        padding: '0 15px 10px',
      };

      return (
        <Tabs
          tabs={[
            {
              label: 'Applied Commands',
              content: (
                <TabContent sx={contentStyles}>
                  <CommandsTable type={'applied'} filters={filters} />
                </TabContent>
              ),
            },
            {
              label: 'Received Commands',
              disabled: true,
              content: (
                <TabContent sx={contentStyles}>
                  <CommandsTable type={'received'} filters={filters} />
                </TabContent>
              ),
            },
          ]}
        />
      );
    }, []);

    const isRowLoaded = useCallback(
      ({ index }) => {
        return !!items[index];
      },
      [items]
    );

    const loadRows = useCallback((offset, limit) => {
      // setPagination(curr => ({
      //   ...curr,
      //   offset,
      // }));
    }, []);

    const renderHeaderRow = useMemo(() => {
      return (
        <>
          <TableStickyHeader
            className={'tableHeaderRow'}
            style={{
              gridTemplateColumns: template,
            }}
          >
            <TableHeaderColumn style={expandCellStyles} />
            <>
              {availableColumns.map(
                (
                  { label, keepMinWidth, resizable, style, key, onWidthChange },
                  index
                ) => {
                  return (
                    <TableHeaderColumn
                      key={key}
                      columnKey={key}
                      label={label}
                      style={style}
                      isLast={index === availableColumns.length - 1}
                      resizable={resizable}
                      onResize={changeColumnWidth}
                      keepMinWidth={keepMinWidth}
                      onWidthChange={onWidthChange}
                    />
                  );
                }
              )}
            </>
            <TableHeaderColumn style={commonCellStyle}>
              {(() => (
                <TableConfigMenu
                  columns={availableColumns}
                  onToggleColumn={toggleColumn}
                  toggledColumns={toggledColumns}
                  onReorder={onReorderColumns}
                />
              ))()}
            </TableHeaderColumn>
          </TableStickyHeader>
        </>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      availableColumns,
      toggleColumn,
      changeColumnWidth,
      onReorderColumns,
      template,
      toggledColumns,
    ]);

    return (
      <ExpandableTable
        id={['outcome_id', 'market_id', 'specifiers']}
        loading={loading}
        renderHeaderRow={renderHeaderRow}
        renderRow={renderRow}
        // getRowClassName={getRowClassName}
        renderExpandedRow={renderExpandedRow}
        data={items}
        tableOffsetTop={tableOffsetTop}
        persistToggledOnListChange
      >
        <InfiniteTable
          loadRows={loadRows}
          isRowLoaded={isRowLoaded}
          rowCount={1000}
        >
          <VirtualizedTable />
        </InfiniteTable>
      </ExpandableTable>
    );
  }
);

export default OutcomesTable;
