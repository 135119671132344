import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Button from '../../../../components/Button/Button';
import { EVENT_STATUSES, EVENT_STATUSES_OPTIONS } from '../../constants';
import updateEventStatusesMutation from '../../../../gql/TradingTool/mutations/updateEventStatusesMutation';
import { useToasts } from '../../../../providers/ToastsProvider';
import NotificationAlert, {
  AlertVariant,
} from '../../../../components/Notifications/NotificationAlert';

const useStyles = makeStyles(theme => ({
  eventsStatusChangeModal: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    width: '100%',
  },
  eventsStatusChangeModalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 8,
  },
}));

const EventsStatusChangeModal = ({
  chosenIds,
  onClose,
  bulkReset,
  apolloClient,
  className,
}) => {
  const classes = useStyles();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { showToast } = useToasts();

  const [updateEventStatuses, { loading }] = useMutation(
    updateEventStatusesMutation,
    {
      client: apolloClient,
    }
  );

  const onConfirm = async status => {
    try {
      const { data: { updateEventStatuses: statuses = {} } = {} } =
        await updateEventStatuses({
          variables: {
            eventIds: chosenIds,
            status: status,
          },
        });

      const successCount = statuses.success.length;
      if (successCount) {
        showToast(
          <NotificationAlert variant={AlertVariant.SUCCESS}>
            {`${successCount} event${
              successCount > 1 ? 's' : ''
            } successfully changed status`}
          </NotificationAlert>
        );
      }

      if (statuses.failed.length) {
        statuses.failed.forEach(({ id, error }) => {
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR}>
              {`An error occurred while changing event ${id} status: ${error}`}
            </NotificationAlert>
          );
        });
      }
      onClose();
      bulkReset();
    } catch (e) {
      showToast(
        <NotificationAlert variant={AlertVariant.ERROR}>
          {`An error occurred while changing events status: ${e.message}`}
        </NotificationAlert>
      );
    }
    close();
  };

  return (
    <div className={clsx(classes.eventsStatusChangeModal, className)}>
      {`Change the status of ${chosenIds.length} selected event${
        chosenIds.length > 1 ? 's' : ''
      } to:`}
      <RadioGroup
        onChange={(_, v) => {
          setSelectedStatus(v);
        }}
        value={selectedStatus}
        name="status"
        sx={{
          maxHeight: '260px',
          gap: '10px',
        }}
      >
        {Object.values(EVENT_STATUSES_OPTIONS).map(({ label, value }) => (
          <FormControlLabel
            disabled={loading}
            label={label}
            value={value}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
      <div className={classes.eventsStatusChangeModalButtons}>
        <Button color={'lightBlue'} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant={'contained'}
          disabled={!selectedStatus || loading}
          onClick={() => onConfirm(selectedStatus)}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default EventsStatusChangeModal;
