import { gql } from '@apollo/client';
import eventModelFragment from '../fragments/eventModelFragment';

const startMatchTimerMutation = gql`
  ${eventModelFragment}
  mutation startMatchTimer($eventId: EventId!, $version: Version!) {
    startMatchTimer(event_id: $eventId, version: $version) {
      ...EventModelFragment
    }
  }
`;

export default startMatchTimerMutation;
