import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePresets } from '../providers/PresetsProvider';
import useLocalStorageTableConfig, {
  tableConfigKeys,
  tableNames,
} from './useLocalStorageTableConfig';

const withoutDisabled = (columns = [], disabledColumns = []) => {
  if (disabledColumns) {
    return columns.filter(column => disabledColumns.indexOf(column) === -1);
  }
  return columns;
};

const useTableColumnToggle = (
  initial,
  onToggleColumns = () => {},
  disabledColumns,
  tableName
) => {
  const { activePreset } = usePresets();
  const { getTableConfigValue, setTableConfigValue } =
    useLocalStorageTableConfig(tableName);
  const presetToggledColumns = activePreset?.data?.toggled_columns;

  const getDefaultToggledColumns = useCallback(() => {
    const localStorageDefaultValue = getTableConfigValue(
      tableConfigKeys.toggledColumns
    );

    if (presetToggledColumns?.length) {
      return presetToggledColumns;
    }

    if (tableName && localStorageDefaultValue?.length) {
      return localStorageDefaultValue;
    }

    return initial;
  }, [initial, getTableConfigValue, presetToggledColumns, tableName]);

  const [toggledColumns, setToggledColumns] = useState(
    getDefaultToggledColumns()
  );

  useEffect(() => {
    setToggledColumns(
      withoutDisabled(getDefaultToggledColumns(), disabledColumns)
    );
  }, [disabledColumns, getDefaultToggledColumns]);

  useEffect(() => {
    const filteredValues = withoutDisabled(
      presetToggledColumns?.length > 0 ? presetToggledColumns : initial,
      disabledColumns
    );

    tableName &&
      setTableConfigValue(tableConfigKeys.toggledColumns, filteredValues);
    setToggledColumns(filteredValues);
  }, [
    presetToggledColumns,
    tableName,
    initial,
    setTableConfigValue,
    disabledColumns,
  ]);

  const toggleColumn = useCallback(
    newToggledColumns => {
      const sortedColumns = initial.sort((a, b) => a.localeCompare(b));
      const sortedNewColumns = newToggledColumns.sort((a, b) =>
        a.localeCompare(b)
      );

      const hasChanged = sortedColumns.join(':') !== sortedNewColumns.join(':');

      if (hasChanged) {
        const filteredValues = withoutDisabled(
          newToggledColumns,
          disabledColumns
        );

        onToggleColumns && onToggleColumns(filteredValues);
        setToggledColumns(filteredValues);
        tableName &&
          setTableConfigValue(tableConfigKeys.toggledColumns, filteredValues);
      }
    },
    [onToggleColumns, disabledColumns, setTableConfigValue, tableName, initial]
  );

  return useMemo(() => {
    return [toggledColumns, toggleColumn];
  }, [toggledColumns, toggleColumn]);
};

export default useTableColumnToggle;
