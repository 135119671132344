import React from 'react';
import Page from '../../components/Page/Page';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import Box from '@mui/material/Box';
import FormGroup from '../../components/Form/FormGroup';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack/Stack';
import Button from '../../components/Button/Button';
import { styled } from '@mui/system';
import Switch from '../../components/Inputs/Switch/Switch';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import RelatedContingencyFields, {
  relatedContingencyItemSchema,
} from '../RelatedContingenciesPage/RelatedContingencyFields';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useMutation, useQuery, useApolloClient } from '@apollo/client';
import RelatedContingencyRuleType from '../RelatedContingenciesPage/constants/RelatedContingencyRuleType';
import getRelatedContingency from '../../gql/queries/getRelatedContingency';
import { useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import RelatedContingencyRuleTypeLabel from '../RelatedContingenciesPage/utils/RelatedContingencyRuleTypeLabel';
import LinkButton from '../../components/Button/LinkButton';
import updateRelatedContingency from '../../gql/mutations/updateRelatedContingency';
import { convertUnixTimeToUtc } from '../../utils/timeUtils';
import ErrorWidget from '../../components/ErrorWidget';
import { checkErrorsForWidget } from '../../../utils/checkErrorsForWidget';

const UpdatedBy = styled('div')({
  color: '#1581FF',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '17px',
  letterSpacing: '0.2px',
});

const UpdatedDate = styled('div')({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '17px',
  letterSpacing: '0.2px',
});

const schema = yup.object().shape({
  left: relatedContingencyItemSchema,
  right: relatedContingencyItemSchema,
  rule_type: yup
    .string()
    .oneOf(Object.values(RelatedContingencyRuleType))
    .required(),
  active: yup.boolean().required(),
});

const defaultValues = async () => ({
  active: false,
});

const RelatedContingencyPage = () => {
  const { id } = useParams();
  const [isUpdated, setUpdated] = useState(false);

  const client = useApolloClient();

  const {
    loading,
    data: response,
    error,
  } = useQuery(getRelatedContingency, {
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  });

  const [update, { loading: isUpdating, error: updateError }] = useMutation(
    updateRelatedContingency
  );

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    reset,
    control,
  } = methods;

  useEffect(() => {
    const data = response?.data;

    if (data) {
      reset({
        left: data.left,
        right: data.right,
        rule_type: data.rule_type,
        active: data.active,
      });
    }
  }, [reset, response]);

  const onSubmit = useCallback(
    values => {
      update({
        variables: {
          id,
          input: values,
        },
      })
        .then(() => {
          setUpdated(true);
          client.refetchQueries({
            include: [getRelatedContingency],
          });
        })
        .catch(() => {});
    },
    [update, id, client]
  );

  if (checkErrorsForWidget([error || updateError])) {
    return <ErrorWidget />;
  }

  return (
    <Page title={'Edit Related Contingencies'}>
      <BreadCrumbs
        crumbs={[
          {
            label: 'Related Contingencies',
            to: '/related-contingencies',
          },
          {
            label: 'Edit',
          },
        ]}
      />

      <Box mt={0.5}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormGroup>
                  <RelatedContingencyFields
                    prefix={'left'}
                    withSameCompetitorOption
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <RelatedContingencyFields prefix={'right'} />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <Grid
                    container
                    justifyContent={'space-between'}
                    spacing={3}
                    alignItems={'center'}
                  >
                    <Grid item>
                      <Stack spacing={3} direction={'row'}>
                        <UpdatedBy>
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={160}
                              component={'span'}
                            />
                          ) : (
                            response?.data?.user_email
                          )}
                        </UpdatedBy>
                        <UpdatedDate>
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={130}
                              component={'span'}
                            />
                          ) : (
                            response?.data &&
                            convertUnixTimeToUtc(response?.data.update_date)
                          )}
                        </UpdatedDate>
                      </Stack>
                    </Grid>

                    <Grid item>
                      <Stack spacing={2} direction={'row'}>
                        <Controller
                          name={'rule_type'}
                          control={control}
                          render={({ field }) => {
                            return (
                              <FormControlLabel
                                sx={{
                                  mr: 0,
                                }}
                                control={
                                  <Switch
                                    value={
                                      field.value ===
                                      RelatedContingencyRuleType.INCLUDE
                                    }
                                    onChange={checked => {
                                      field.onChange(
                                        checked
                                          ? RelatedContingencyRuleType.INCLUDE
                                          : RelatedContingencyRuleType.EXCLUDE
                                      );
                                    }}
                                  />
                                }
                                label={
                                  field.value ? (
                                    RelatedContingencyRuleTypeLabel[field.value]
                                  ) : (
                                    <Skeleton
                                      variant="rectangular"
                                      width={64}
                                      component={'span'}
                                    />
                                  )
                                }
                              />
                            );
                          }}
                        />

                        <Controller
                          name={'active'}
                          control={control}
                          render={({ field }) => {
                            return (
                              <FormControlLabel
                                sx={{
                                  mr: 0,
                                }}
                                control={
                                  <Switch
                                    value={field.value}
                                    onChange={field.onChange}
                                  />
                                }
                                label={
                                  loading ? (
                                    <Skeleton
                                      variant="rectangular"
                                      width={46}
                                      component={'span'}
                                    />
                                  ) : field.value ? (
                                    'Active'
                                  ) : (
                                    'Inactive'
                                  )
                                }
                              />
                            );
                          }}
                        />

                        <Button
                          color={'lightBlue'}
                          disabled={loading || !isDirty || isUpdating}
                          onClick={() => reset()}
                        >
                          Revert
                        </Button>
                        <Button
                          type={'submit'}
                          color={'lightBlue'}
                          disabled={
                            loading || !isValid || !isDirty || isUpdating
                          }
                        >
                          Save
                        </Button>
                        <LinkButton
                          color={'lightBlue'}
                          to="/related-contingencies"
                        >
                          {isUpdated ? 'Close' : 'Cancel'}
                        </LinkButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Page>
  );
};

export default RelatedContingencyPage;
