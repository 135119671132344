import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import Page from '../../../components/Page/Page';
import MarketsConfigPanel from './MarketsConfigPanel';
import { useMutation, useQuery } from '@apollo/client';
import SPORTS_AUTOCOMPLETE_QUERY from '../../../gql/TradingTool/queries/SPORTS_AUTOCOMPLETE_QUERY';
import getSportMarkets from '../../../gql/TradingTool/queries/getSportMarkets';
import addSportMarketMutation from '../../../gql/TradingTool/mutations/addSportMarketMutation';
import removeSportMarketMutation from '../../../gql/TradingTool/mutations/removeSportMarketMutation';
import ErrorWidget from '../../../components/ErrorWidget';
import { checkErrorsForWidget } from '../../../../utils/checkErrorsForWidget';

const MarketsConfigPage = () => {
  const [selectedSportId, setSelectedSportId] = useState(null);
  const [sportSearchValue, setSportSearchValue] = useState(null);
  const [marketSearchValue, setMarketSearchValue] = useState(null);
  const onSportSearchChange = debounce(setSportSearchValue, 600);
  const onMarketSearchChange = debounce(setMarketSearchValue, 600);

  const { data: { data: sports = [] } = {}, error: sportsError } = useQuery(
    SPORTS_AUTOCOMPLETE_QUERY,
    {
      variables: {
        query: sportSearchValue,
        limit: -1,
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const { data: { getSportMarkets: markets = [] } = {}, error: marketsError } =
    useQuery(getSportMarkets, {
      variables: {
        sportId: selectedSportId,
        search: marketSearchValue,
      },
      skip: !selectedSportId,
      fetchPolicy: 'cache-and-network',
    });

  const [addSportMarket, { error: addSportMarketError }] = useMutation(
    addSportMarketMutation
  );
  const [removeSportMarket, { error: removeSportMarketError }] = useMutation(
    removeSportMarketMutation
  );

  const onMarketToggle = (isEnabled, marketId) => {
    const mutationToUse = isEnabled ? addSportMarket : removeSportMarket;

    mutationToUse({
      variables: {
        sportId: selectedSportId,
        marketId,
      },
      optimisticResponse: () => {
        const updatedMarkets = markets.map(market => {
          if (market.id === marketId) {
            return {
              ...market,
              enabled: !market.enabled,
            };
          } else {
            return market;
          }
        });

        return {
          [isEnabled ? 'addSportMarket' : 'removeSportMarket']: updatedMarkets,
        };
      },
    });
  };

  useEffect(() => {
    if (!selectedSportId && sports && sports.length) {
      setSelectedSportId(sports[0].id);
    }
  }, [selectedSportId, setSelectedSportId, sports]);

  if (
    checkErrorsForWidget([
      marketsError,
      sportsError,
      addSportMarketError,
      removeSportMarketError,
    ])
  ) {
    return <ErrorWidget />;
  }

  return (
    <div>
      <Page title={'Markets settings'}>
        {sportsError || marketsError ? (
          <div>Something went wrong while loading markets settings</div>
        ) : (
          <MarketsConfigPanel
            sports={sports}
            selectedSportId={selectedSportId}
            setSelectedSportId={setSelectedSportId}
            onSportSearchChange={onSportSearchChange}
            markets={markets}
            onMarketToggle={onMarketToggle}
            onMarketSearchChange={onMarketSearchChange}
          />
        )}
      </Page>
    </div>
  );
};

export default MarketsConfigPage;
