import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { isSelect } from 'react-jsonschema-form/lib/utils';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import getDefaultValue, {
  isDateArray,
  isDateTimeArray,
} from '../../utils/prepareSchema/getDefaultValue';
import { isMultiSelect } from '../../v2/components/Inputs/Select/Select';

const isFilterVisible = (filter, query) => {
  return (
    filter.alwaysVisible ||
    (filter.initialVisible &&
      (Array.isArray(query[filter.name])
        ? query[filter.name].length
        : filter.name in query))
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_FILTER':
      return {
        visibleFilters: action.payload.mappedFilters.filter(f => {
          return (
            f.name === action.payload.filter.name ||
            state.visibleFilters.find(filter => filter.name === f.name)
          );
        }),
        availableFilters: state.availableFilters.filter(
          f => f.name !== action.payload.filter.name
        ),
      };
    case 'HIDE_FILTER':
      return {
        visibleFilters: state.visibleFilters.filter(
          f => f.name !== action.payload.filter.name
        ),
        availableFilters: [...state.availableFilters, action.payload.filter],
      };
    case 'RESET':
      return {
        ...action.payload,
      };
    default:
      throw new Error();
  }
};

const getType = (schema, uiSchema) =>
  get(uiSchema, 'ui:widget') ||
  schema.format ||
  (isDateArray(schema)
    ? 'date'
    : isDateTimeArray(schema)
    ? 'date-time'
    : isMultiSelect(schema, uiSchema) || isSelect(schema)
    ? 'select'
    : schema.type);

export const getObjectFilters = (parentName, schema, uiSchema) => {
  return Object.keys(schema.properties).map(propertyName => {
    const propertySchema = schema.properties[propertyName];
    const propertyUiSchema = uiSchema[propertyName];

    return {
      name: `${parentName}.${propertyName}`,
      title: propertySchema.title,
      type: getType(propertySchema, propertyUiSchema),
      uiSchema: propertyUiSchema,
      schema: propertySchema,
      initialVisible: true,
      alwaysVisible: true,
    };
  });
};

const useAvailableAndVisibleFilters = (filters, query) => {
  const [toggledFilters, setToggledFilters] = useState([]);

  const mappedFilters = useMemo(() => {
    return filters.map(f => {
      return {
        name: f.name,
        title: f.schema.title,
        type: getType(f.schema, f['x-ui-schema']),
        uiSchema: f['x-ui-schema'],
        schema: f.schema,
        initialVisible:
          get(query, f.name, getDefaultValue(f.schema)) !== '' &&
          !isEqual(
            get(query, f.name, getDefaultValue(f.schema)),
            getDefaultValue(f.schema)
          ),
        alwaysVisible: get(f, 'x-ui-schema.ui:visible'),
      };
    });
  }, [filters, query]);

  const [{ visibleFilters, availableFilters }, dispatch] = useReducer(reducer, {
    visibleFilters: mappedFilters.filter(f => {
      return isFilterVisible(f, query);
    }),
    availableFilters: mappedFilters.filter(f => !isFilterVisible(f, query)),
  });

  const showFilter = useCallback(
    filter => {
      setToggledFilters(prevState => [...prevState, filter.name]);
      return dispatch({
        type: 'SHOW_FILTER',
        payload: {
          filter,
          mappedFilters,
        },
      });
    },
    [dispatch, mappedFilters, setToggledFilters]
  );

  const hideFilter = useCallback(
    filter => {
      setToggledFilters(prevState => prevState.filter(f => f !== filter.name));
      return dispatch({ type: 'HIDE_FILTER', payload: { filter } });
    },
    [dispatch, setToggledFilters]
  );

  useEffect(() => {
    dispatch({
      type: 'RESET',
      payload: {
        visibleFilters: mappedFilters.filter(f => {
          return toggledFilters.includes(f.name) || isFilterVisible(f, query);
        }),
        availableFilters: mappedFilters.filter(
          f => !toggledFilters.includes(f.name) && !isFilterVisible(f, query)
        ),
      },
    });
  }, [query, mappedFilters, toggledFilters]);

  return useMemo(
    () => ({
      visibleFilters,
      availableFilters,
      showFilter,
      hideFilter,
    }),
    [visibleFilters, availableFilters, showFilter, hideFilter]
  );
};

export default useAvailableAndVisibleFilters;
