import React, { useCallback, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Button from '../../../components/Button/Button';
import TextField from '../../../components/Inputs/TextField';
import TradingToolStatusFilter from './components/TradingToolStatusFilter';
import useDebounced from '../../../hooks/useDebounced';

const useStyles = makeStyles({
  tradingToolTableActionsPanel: {
    background: '#fff',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    padding: 12,
  },
  actionsPanelButtons: {
    flex: 2,
    display: 'flex',
    alignItems: 'center',
  },
  actionsPanelButton: {
    marginRight: 8,
    textTransform: 'none',
  },
  eventSearchField: {
    flex: '1',
  },
  eventSearchFieldInput: {
    paddingRight: 32,
  },
});

const TradingToolTableActionsPanel = ({
  filters,
  onSelectStatus,
  onSearchEvent,
  onCreateButtonClick,
  chosenIds,
  onBulkChangeStatusClick,
}) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState(filters.id);
  const debouncedSearchEvent = useDebounced(onSearchEvent, 600);

  const handleSearch = useCallback(
    value => {
      setSearchValue(value);
      debouncedSearchEvent(value);
    },
    [debouncedSearchEvent]
  );

  return (
    <Box className={classes.tradingToolTableActionsPanel}>
      <Box className={classes.actionsPanelButtons}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={onCreateButtonClick}
          className={classes.actionsPanelButton}
        >
          Create event
        </Button>
        {!!chosenIds.length && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onBulkChangeStatusClick}
            className={classes.actionsPanelButton}
          >
            {`Change status (${chosenIds.length})`}
          </Button>
        )}
        <TradingToolStatusFilter
          selectedStatuses={filters.statuses}
          onSelectStatus={onSelectStatus}
        />
      </Box>
      <TextField
        label="Search event"
        value={searchValue}
        onChange={({ target: { value } }) => handleSearch(value)}
        withSearchIcon
        clearButton
        onClear={() => handleSearch('')}
        className={classes.eventSearchField}
        extraClasses={{
          input: classes.eventSearchFieldInput,
        }}
      />
    </Box>
  );
};

export default TradingToolTableActionsPanel;
