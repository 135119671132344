import { gql } from '@apollo/client';
import statModelFragment from './statModelFragment';
import matchTimerFragment from './matchTimerFragment';

const eventModelFragment = gql`
  ${statModelFragment}
  ${matchTimerFragment}
  fragment EventModelFragment on EventModel {
    id
    fixture {
      sport {
        id
        name
      }
      category {
        id
        name
      }
      tournament {
        id
        name
      }
      scheduled
      competitors {
        id
        name
      }
      type
    }
    state {
      status
      match_timer {
        ...MatchTimerFragment
      }
    }
    markets {
      id
      name
      enabled
      outcomes
      main_specifier
    }
    stats {
      ...StatModelFragment
    }
    version
    blocked_markets
    margin_ladder
  }
`;

export default eventModelFragment;
