import { gql } from '@apollo/client';
import eventModelFragment from '../fragments/eventModelFragment';

const stopMatchTimerMutation = gql`
  ${eventModelFragment}
  mutation stopMatchTimer($eventId: EventId!, $version: Version!) {
    stopMatchTimer(event_id: $eventId, version: $version) {
      ...EventModelFragment
    }
  }
`;

export default stopMatchTimerMutation;
