import Table from '../../../../components/Table/Table';
import StringField from '../../../../components/Fields/StringField/StringField';
import {
  ActivatePeriod,
  BannerState,
  BannerStateLabel,
  BannerTypeLabelMap,
  BannerViewLabelMap,
} from '../../components/BannerForm/BannerValidationSchema';
import EmptyField from '../../../../components/Fields/EmptyField/EmptyField';
import BooleanField from '../../../../components/Fields/BooleanField/BooleanField';
import LinkField from '../../../../components/Fields/LinkField/LinkField';
import CopyField from '../../../../components/Fields/CopyField/CopyField';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { ConfirmationPromptContext } from '../../../../../providers/ConfirmationPromptProvider';
import { useContext, useMemo } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import deletePromoBanner from '../../../../gql/Promo/mutations/deletePromoBanner';
import { useToasts } from '../../../../providers/ToastsProvider';
import NotificationAlert, {
  AlertVariant,
} from '../../../../components/Notifications/NotificationAlert';
import useTablePresetColumnToggle from '../../../../hooks/useTablePresetColumnToggle';
import getBannersList from '../../../../gql/Promo/queries/getBannersList';
import deactivateBanner from '../../../../gql/Promo/mutations/deactivateBanner';
import activateExistDraft from '../../../../gql/Promo/mutations/activateExistDraft';
import ErrorWidget from '../../../../components/ErrorWidget';
import { checkErrorsForWidget } from '../../../../../utils/checkErrorsForWidget';

const commonCellStyle = {
  padding: '0px 16px',
};

const centeredCellStyle = {
  ...commonCellStyle,
  justifyContent: 'center',
  textAlign: 'center',
};

export const COLUMN = {
  BANNER: 'banner',
  TYPE: 'type',
  VIEW: 'view',
  PERMANENTLY: 'permanently',
  // PRIORITY: 'priority',
  ACTIVATE_DURATION: 'activate_duration',
  BRAND: 'brand',
  STATE: 'state',
};

export const columnsWidthConfig = {
  [COLUMN.BANNER]: 2.5,
  [COLUMN.TYPE]: 2,
  [COLUMN.VIEW]: 1.8,
  [COLUMN.PERMANENTLY]: 1.8,
  // [COLUMN.PRIORITY]: 1.5,
  [COLUMN.ACTIVATE_DURATION]: 4.5,
  [COLUMN.BRAND]: 3,
  [COLUMN.STATE]: 2,
};

export const columnsOrderConfig = [
  COLUMN.BANNER,
  COLUMN.TYPE,
  COLUMN.VIEW,
  COLUMN.PERMANENTLY,
  // COLUMN.PRIORITY,
  COLUMN.ACTIVATE_DURATION,
  COLUMN.BRAND,
  COLUMN.STATE,
];

export const initialToggledColumns = [
  COLUMN.BANNER,
  COLUMN.TYPE,
  COLUMN.VIEW,
  COLUMN.PERMANENTLY,
  // COLUMN.PRIORITY,
  COLUMN.ACTIVATE_DURATION,
  COLUMN.BRAND,
  COLUMN.STATE,
];

const PromoBannersTable = ({ data, loading, onChangeColumnsWidth }) => {
  const navigate = useNavigate();

  const client = useApolloClient();

  const { openConfirmation } = useContext(ConfirmationPromptContext);

  const [toggledColumns, onToggleColumns] = useTablePresetColumnToggle(
    initialToggledColumns
  );

  const { showToast } = useToasts();

  const [removeBanner, { loading: removeLoading, error: removeError }] =
    useMutation(deletePromoBanner);

  const [deactivatePromo, { error: deactivateError }] =
    useMutation(deactivateBanner);
  const [activatePromo, { error: activateError }] =
    useMutation(activateExistDraft);

  const rowActions = useMemo(
    () => [
      {
        key: 'duplicate',
        label: 'Duplicate',
        onClick: ({ id }) => {
          navigate(`/banners/duplicate/${id}`);
        },
      },
      {
        key: 'edit',
        label: 'Edit',
        onClick: ({ id }) => {
          navigate(`/banners/${id}`);
        },
      },
      ({ state }) =>
        (state === BannerState.ACTIVE || state === BannerState.SCHEDULED) && {
          key: 'deactivate',
          label: 'Deactivate',
          onClick: async ({ id }) => {
            try {
              await deactivatePromo({
                variables: {
                  id,
                },
              });

              showToast(
                <NotificationAlert variant={AlertVariant.SUCCESS}>
                  Banner deactivated successfully
                </NotificationAlert>
              );

              client.refetchQueries({
                include: [getBannersList],
              });
            } catch (e) {
              showToast(
                <NotificationAlert variant={AlertVariant.ERROR}>
                  An error occurred while deactivating the banner
                </NotificationAlert>
              );
            }
          },
        },
      ({ state }) =>
        state === BannerState.DRAFT && {
          key: 'activate',
          label: 'Activate',
          onClick: async ({ id }) => {
            try {
              await activatePromo({
                variables: {
                  id,
                },
              });

              showToast(
                <NotificationAlert variant={AlertVariant.SUCCESS}>
                  Banner activated successfully
                </NotificationAlert>
              );
            } catch (e) {
              navigate(`/banners/${id}?validate=true`);

              showToast(
                <NotificationAlert variant={AlertVariant.ERROR}>
                  Cannot activate. Required fields are missing.
                </NotificationAlert>
              );
            }
          },
        },
      ({ state }) =>
        state === BannerState.DRAFT && {
          key: 'delete',
          label: 'Delete',
          onClick: ({ id }) => {
            openConfirmation({
              buttonLabel: 'Delete',
              title: 'Are you going to remove the banner?',
              message: `Note, that you cannot undo this action.`,
              destructive: true,
              callback: async () => {
                removeBanner({
                  variables: {
                    id,
                  },
                })
                  .then(res => {
                    showToast(
                      <NotificationAlert variant={AlertVariant.SUCCESS}>
                        Banner deleted successfully
                      </NotificationAlert>
                    );

                    client.refetchQueries({
                      include: [getBannersList],
                    });
                  })
                  .catch(e => {
                    showToast(
                      <NotificationAlert variant={AlertVariant.ERROR}>
                        An error occurred while deleting the banner
                      </NotificationAlert>
                    );
                  });
              },
            });
          },
          destructive: true,
        },
    ],
    [
      client,
      navigate,
      openConfirmation,
      removeBanner,
      showToast,
      deactivatePromo,
      activatePromo,
    ]
  );

  if (checkErrorsForWidget([removeError, deactivateError, activateError])) {
    return <ErrorWidget />;
  }

  return (
    <Table
      initialToggledColumns={toggledColumns}
      onToggleColumns={onToggleColumns}
      rowActions={rowActions}
      loading={loading}
      data={data}
      columnsWidth={columnsWidthConfig}
      order={columnsOrderConfig}
      columns={[
        {
          label: 'Banner',
          key: COLUMN.BANNER,
          style: commonCellStyle,
          render: ({ id, name }) => {
            return (
              <CopyField value={id} title={name}>
                <LinkField href={`/banners/${id}`}>{name}</LinkField>
              </CopyField>
            );
          },
          resizable: true,
        },
        {
          label: 'Type',
          key: COLUMN.TYPE,
          style: commonCellStyle,
          render: ({ banner_type }) => {
            return banner_type ? (
              <StringField>{BannerTypeLabelMap[banner_type]}</StringField>
            ) : (
              <EmptyField />
            );
          },
          resizable: true,
        },
        {
          label: 'View',
          key: COLUMN.VIEW,
          style: commonCellStyle,
          render: ({ view_type }) => {
            return view_type ? (
              <StringField>{BannerViewLabelMap[view_type]}</StringField>
            ) : (
              <EmptyField />
            );
          },
          resizable: true,
        },
        {
          label: 'Permanently',
          key: COLUMN.PERMANENTLY,
          style: centeredCellStyle,
          render: ({ activate }) => {
            if (!activate || !activate.type) {
              return <EmptyField />;
            }

            return (
              <BooleanField
                active={activate.type === ActivatePeriod.PERMANENTLY}
              />
            );
          },
          resizable: true,
        },
        // {
        //   label: 'Priority',
        //   key: COLUMN.PRIORITY,
        //   style: centeredCellStyle,
        //   render: data => {
        //     return <StringField>Brand</StringField>;
        //   },
        // },
        {
          label: 'Activate Period',
          key: COLUMN.ACTIVATE_DURATION,
          style: commonCellStyle,
          render: ({ activate }) => {
            if (!activate || !activate.period) {
              return <EmptyField />;
            }

            const from = dayjs(activate.period.range_from);
            const to = dayjs(activate.period.range_to);

            return (
              <StringField>
                {from.utc().format('DD.MM.YYYY, HH:mm')}—
                {to.utc().format('DD.MM.YYYY, HH:mm')}
              </StringField>
            );
          },
          resizable: true,
        },
        {
          label: 'Brand',
          key: COLUMN.BRAND,
          style: commonCellStyle,
          render: ({ brand }) => {
            return brand ? (
              <CopyField value={brand.id} title={brand.name}>
                <StringField>{brand.name}</StringField>
              </CopyField>
            ) : (
              <EmptyField />
            );
          },
          resizable: true,
        },
        {
          label: 'State',
          key: COLUMN.STATE,
          style: commonCellStyle,
          render: ({ state }) => {
            return state ? (
              <StringField>{BannerStateLabel[state]}</StringField>
            ) : (
              <EmptyField />
            );
          },
          resizable: true,
        },
      ]}
      onChangeColumnsWidth={onChangeColumnsWidth}
    />
  );
};

export default PromoBannersTable;
