import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import useResizeObserver from 'use-resize-observer';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { pickBy } from 'lodash';
import Page from '../../components/Page/Page';
import TablePagination from '../../components/TablePagination/TablePagination';
import EVENTS_QUERY from '../../gql/TradingTool/queries/EVENTS_QUERY';
import enableEventMarketsMutation from '../../gql/TradingTool/mutations/enableEventMarketsMutation';
import useFilters from '../../hooks/useFilters';
import { withoutEmpty } from '../../hooks/usePresetFilters';
import useTablePagination from '../../hooks/useTablePagination';
import TradingEventsTable from './TradingEventsTable';
import TradingToolSidebar from './TradingToolSidebar/TradingToolSidebar';
import TradingToolTableActionsPanel from './TradingToolTableActionsPanel/TradingToolTableActionsPanel';
import TradingEventDetails from './TradingEventEditPage/TradingEventDetails';
import EventsStatusChangeModal from './TradingToolTableActionsPanel/components/EventsStatusChangeModal';
import useTableOrderByChange from '../../hooks/useTableOrderByChange';
import { OrderDirection } from '../../components/Table/TableHeaderColumn';
import { tableNames } from '../../hooks/useLocalStorageTableConfig';
import { EVENT_STATUSES } from './constants';
import TableBulkProvider from '../../providers/TableBulkProvider';
import { useDialog, useTableBulkSelection } from '../../hooks';

const useStyles = makeStyles({
  tradingEventsPage: {
    // minHeight: '100%',
  },
});

const defaultFilters = {
  id: null,
  sport_id: null,
  category_id: null,
  tournament_id: null,
  statuses: [
    EVENT_STATUSES.draft,
    EVENT_STATUSES.prematch,
    EVENT_STATUSES.inProgress,
  ],
  date_from: null,
  date_to: null,
};

const TradingEventsPage = ({ isCreating = false }) => {
  const classes = useStyles();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { open, close } = useDialog({ closeOnEsc: true });
  const apolloClient = useApolloClient();

  const [tableOffsetTop, setTableOffsetTop] = useState(0);

  const [isShowTradePage, setShowTradePage] = useState(!!eventId || isCreating);
  const [currentTradeEventId, setCurrentTradeEventId] = useState(eventId);
  const [creationFlowInitialData, setCreationFlowInitialData] = useState({
    sport: null,
    category: null,
    tournament: null,
  });

  const actionCardsRef = useRef(null);

  const { orderBy, orderByAscDesc, onChangeOrderBy } = useTableOrderByChange({
    initialOrderBy: 'fixture_scheduled',
    initialOrderByRotation: OrderDirection.DESC,
  });

  const { limit, setLimit, offset, setOffset } = useTablePagination({
    tableName: tableNames.TradingEvents,
    onChangeLimit: () => {},
  });

  useResizeObserver({
    ref: actionCardsRef,
    onResize: rect => {
      setTableOffsetTop(rect.height);
    },
  });

  const { filters, setFiltersQuery } = useFilters({
    tableName: tableNames.TradingEvents,
    resetOffset: setOffset,
    defaultValues: defaultFilters,
    hidePresets: true,
  });

  const queryFilters = useMemo(() => {
    return { ...withoutEmpty(filters) };
  }, [filters]);

  const {
    data: { data: { items = [], total = 0 } = {} } = {},
    loading,
    refetch: refetchEvents,
  } = useQuery(EVENTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: {
        ...queryFilters,
        limit,
        offset,
        order_by: orderBy,
        order_by_asc_desc: orderByAscDesc,
      },
    },
  });

  const [enableEventMarkets] = useMutation(enableEventMarketsMutation, {
    update(cache, { data: { enableEventMarkets } }, { variables }) {
      cache.modify({
        id: `EventTableRecord:${variables.eventId}`,
        fields: {
          blocked_markets: () => {
            return !variables.status;
          },
        },
      });
    },
  });

  const onChangeActiveStatus = useCallback(
    ({ eventId, activeStatus, version }) => {
      return enableEventMarkets({
        variables: {
          eventId,
          status: activeStatus,
          version,
        },
        optimisticResponse: {
          enableEventMarkets: true,
        },
      });
    },
    [enableEventMarkets]
  );

  const onSidebarValuesChange = useCallback(
    ({ sport, category, tournament }) => {
      setCreationFlowInitialData({ sport, category, tournament });

      const newFilters = {
        ...filters,
        sport_id: sport?.id ?? null,
        category_id: category?.id ?? null,
        tournament_id: tournament?.id ?? null,
      };

      setFiltersQuery({ ...newFilters });
      setOffset(0);
    },
    [setCreationFlowInitialData, filters, setFiltersQuery, setOffset]
  );

  const onSidebarDateChange = useCallback(
    ({ date_from, date_to }) => {
      const newFilters = {
        ...filters,
        date_from,
        date_to,
      };

      setFiltersQuery({ ...newFilters });
      setOffset(0);
    },
    [filters, setFiltersQuery, setOffset]
  );

  const onSelectStatus = useCallback(
    (value, isSelected) => {
      const newFilters = {
        ...filters,
        statuses: isSelected
          ? [...new Set([...(filters?.statuses || []), value])]
          : filters.statuses.filter(f => f !== value),
      };

      setFiltersQuery({ ...newFilters });
      setOffset(0);
    },
    [filters, setFiltersQuery, setOffset]
  );

  const onSearchEvent = useCallback(
    value => {
      const newFilters = {
        ...filters,
        id: value, // TODO: change to use search string instead if eventId after backend implementation
      };

      setFiltersQuery({ ...newFilters });
      setOffset(0);
    },
    [filters, setFiltersQuery, setOffset]
  );

  const onCreateButtonClick = useCallback(() => {
    navigate({
      pathname: `/trading-events/create`,
      search: searchParams.toString(),
    });
  }, [navigate, searchParams]);

  const onBackButtonClick = useCallback(() => {
    navigate({
      pathname: `/trading-events`,
      search: searchParams.toString(),
    });
  }, [navigate, searchParams]);

  const bulk = useTableBulkSelection(items, 'id', true);
  const { chosenItems, reset: bulkReset } = bulk;
  const chosenIds = Object.keys(pickBy(chosenItems, value => value));

  const handleBulkChangeStatusClick = useCallback(() => {
    open({
      component: EventsStatusChangeModal,
      options: {
        title: 'Change Events Status',
        onClose: close,
      },
      props: {
        chosenIds,
        onClose: close,
        bulkReset,
        apolloClient,
      },
    });
  }, [open, close, chosenIds, bulkReset, apolloClient]);

  useEffect(() => {
    if (!!eventId) {
      setCurrentTradeEventId(eventId);
      setShowTradePage(true);
    } else if (isCreating) {
      setCurrentTradeEventId(null);
      setShowTradePage(true);
    } else {
      setCurrentTradeEventId(null);
      setShowTradePage(false);
    }
  }, [eventId, isCreating, setCurrentTradeEventId, setShowTradePage]);

  return (
    <Page title={'Trading tool'} extraClass={classes.tradingEventsPage}>
      <Grid container spacing={2} wrap="nowrap" height="100%">
        <Grid item>
          <TradingToolSidebar
            filters={filters}
            onChange={onSidebarValuesChange}
            onDateChange={onSidebarDateChange}
          />
        </Grid>

        {isShowTradePage ? (
          <Grid item flexGrow={1} minWidth="0">
            <TradingEventDetails
              isCreating={isCreating}
              eventId={currentTradeEventId}
              creationFlowInitialData={creationFlowInitialData}
              onBackButtonClick={onBackButtonClick}
              refetchEvents={refetchEvents}
            />
          </Grid>
        ) : (
          <Grid item flexGrow={1}>
            <TradingToolTableActionsPanel
              filters={filters}
              onSelectStatus={onSelectStatus}
              onSearchEvent={onSearchEvent}
              onCreateButtonClick={onCreateButtonClick}
              chosenIds={chosenIds}
              onBulkChangeStatusClick={handleBulkChangeStatusClick}
            />

            <Box mt={2}>
              <TableBulkProvider bulk={bulk}>
                <TradingEventsTable
                  loading={!items.length && loading}
                  items={items}
                  tableOffsetTop={tableOffsetTop}
                  onChangeActiveStatus={onChangeActiveStatus}
                  orderBy={orderBy}
                  onChangeOrderBy={onChangeOrderBy}
                />
              </TableBulkProvider>
            </Box>

            <TablePagination
              offset={offset}
              setOffset={setOffset}
              limit={limit}
              setLimit={setLimit}
              totalRows={total}
            />
          </Grid>
        )}
      </Grid>
    </Page>
  );
};

export default TradingEventsPage;
