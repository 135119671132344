import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import NumberField from '../../../../../components/Inputs/NumberField';
import FormField from '../../../../../components/Form/FormField';
import Button from '../../../../../components/Button/Button';
import CheckMarkIcon from '../../../../../icons/CheckMarkIcon.svg';

const useStyles = makeStyles({
  scoreboardTableCell: {
    padding: 8,
    fontSize: 14,
    fontWeight: 500,
    maxWidth: 80,
  },
  scoreboardTableCompetitorCell: {
    paddingRight: 30,
    maxWidth: 'unset',
  },
  scoreboardTableInputCell: {
    minWidth: 120,
    maxWidth: 120,
    position: 'relative',
  },
  specifierOutcomeButton: {
    borderRadius: 2,
    padding: 0,
    position: 'absolute',
    minWidth: 'unset',
    width: 24,
    height: 24,
    right: 34,
    top: 16,
    zIndex: 1,
  },
  checkMarkIcon: {
    backgroundImage: `url(${CheckMarkIcon})`,
    backgroundRepeat: 'no-repeat',
    height: 24,
    width: 24,
  },
});

const TradingEventScoreboard = ({
  form,
  isDisabled,
  onUpdateScore,
  className,
}) => {
  const classes = useStyles();
  const { handleSubmit, watch, getFieldState } = form;
  const competitors = watch('competitors');

  const isShowHomeConfirm = getFieldState('score.home').isDirty;
  const isShowAwayConfirm = getFieldState('score.away').isDirty;

  return (
    <table className={className}>
      <thead>
        <tr>
          <th className={classes.scoreboardTableCell} />
          <th className={classes.scoreboardTableCell}>Score</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th
            align="left"
            className={clsx(
              classes.scoreboardTableCell,
              classes.scoreboardTableCompetitorCell
            )}
          >
            {competitors?.[0]?.name}
          </th>
          <th
            className={clsx(
              classes.scoreboardTableCell,
              classes.scoreboardTableInputCell
            )}
          >
            <FormField name={'score.home'}>
              <NumberField step={1} min={0} disabled={isDisabled} strictRules />
            </FormField>
            {isShowHomeConfirm && (
              <Button
                type={'submit'}
                color={'primary'}
                onClick={e => {
                  handleSubmit(data => onUpdateScore(data, 'home'))(e);
                }}
                className={classes.specifierOutcomeButton}
              >
                <div className={classes.checkMarkIcon} />
              </Button>
            )}
          </th>
        </tr>
        <tr>
          <th
            align="left"
            className={clsx(
              classes.scoreboardTableCell,
              classes.scoreboardTableCompetitorCell
            )}
          >
            {competitors?.[1]?.name}
          </th>
          <th
            className={clsx(
              classes.scoreboardTableCell,
              classes.scoreboardTableInputCell
            )}
          >
            <FormField name={'score.away'}>
              <NumberField step={1} min={0} disabled={isDisabled} strictRules />
            </FormField>
            {isShowAwayConfirm && (
              <Button
                type={'submit'}
                color={'primary'}
                onClick={e => {
                  handleSubmit(data => onUpdateScore(data, 'away'))(e);
                }}
                className={classes.specifierOutcomeButton}
              >
                <div className={classes.checkMarkIcon} />
              </Button>
            )}
          </th>
        </tr>
      </tbody>
    </table>
  );
};

export default TradingEventScoreboard;
