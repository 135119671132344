import { gql } from '@apollo/client';

const RECEIVED_COMMANDS_QUERY = gql`
  query getEventSettlementsReceivedCommands(
    $filters: SettlementsCommandsFilters!
  ) {
    data: getEventSettlementsReceivedCommands(filters: $filters) {
      items {
        timestamp
        provider
        type
        result
        void_factor
      }
      total
    }
  }
`;

export default RECEIVED_COMMANDS_QUERY;
