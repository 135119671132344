import { gql } from '@apollo/client';
import statModelFragment from '../fragments/statModelFragment';

const statsSubscription = gql`
  ${statModelFragment}
  subscription Stats($eventId: EventId!) {
    Stats(event_id: $eventId) {
      event_id
      stats {
        ...StatModelFragment
      }
    }
  }
`;

export default statsSubscription;
