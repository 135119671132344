import makeStyles from '@mui/styles/makeStyles';
import classnames from 'clsx';
import React, { useContext } from 'react';
import usePaletteColor from '../../hooks/usePaletteColor';
import { UiOptionsContext } from './UiOptionsProvider';
import marked from 'marked';
import { DataContext } from '../../providers/DataProvider';

const useStyles = makeStyles(theme => ({
  belowZero: {
    color: theme.palette.error.main,
  },
  aboveZero: {
    color: theme.palette.success.main,
  },
  bold: {
    fontWeight: 600,
  },
  code: {
    whiteSpace: 'pre',
    fontFamily: 'monospace',
  },
  number: {
    // fontFamily: `'Iosevka SS11', monospace`,
    // fontFeatureSettings: `'ss04' on`,
  },
  htmlString: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const FormattedValueText = ({ color: textColor, children }) => {
  const { value, colored } = useContext(UiOptionsContext);
  const classes = useStyles();
  const { format, fontWeight, type, formatArgs = {} } = value.label;
  const { color } = value;
  const { path } = useContext(DataContext);

  const text = format
    ? format(parseFloat(value.label.text), formatArgs)
    : value.label.text;

  const className = classnames({
    [classes.belowZero]: colored && value.data < 0,
    [classes.aboveZero]: colored && value.data > 0,
    [classes.bold]: fontWeight === 'bold',
    [classes.code]: type === 'code',
    [classes.number]: type === 'number',
  });

  const valueTextColor = usePaletteColor(textColor || color);

  // TODO: remove this flag and dangerouslySetInnerHTML after BT-18576
  const isRenderHtmlString = !!path?.includes('/api/v1/LiabilityAdmin');

  const renderHtmlString = () => (
    <div
      dangerouslySetInnerHTML={{
        __html: marked(children || text || ''),
      }}
      className={classes.htmlString}
    />
  );

  return (
    <div
      className={className}
      style={{
        color: valueTextColor,
      }}
    >
      {isRenderHtmlString ? renderHtmlString() : children || text}
    </div>
  );
};

export default FormattedValueText;
