import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import ExpandIcon from '../../../icons/controls/ExpandIcon';
import ExpandIconUp from '../../../icons/controls/ExpandIconUp';

const useStyles = makeStyles({
  timePickerInput: {
    height: 40,
    boxSizing: 'border-box',
    appearance: 'textfield',

    '&::-webkit-inner-spin-button': {
      appearance: 'none',
    },
    '&::-webkit-inner-outer-button': {
      appearance: 'none',
    },
  },

  numberInputControls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 36,
    color: '#1581FF',

    '& svg': {
      cursor: 'pointer',
    },
  },

  timePickerField: {
    width: 60,
  },
  timePickerFieldInactive: {
    pointerEvents: 'none',
  },
});

const TimeNumberField = forwardRef(
  (
    {
      min,
      max,
      formatedValue,
      onComplete,
      step = 1,
      value,
      onChange,
      disabled,
      inactive,
    },
    ref
  ) => {
    const classes = useStyles();

    const [innerValue, setInnerValue] = useState(formatedValue);

    useEffect(() => {
      setInnerValue(formatedValue);
    }, [setInnerValue, formatedValue]);

    const handleChange = useCallback(
      value => {
        if (!value || value < min) {
          onChange(min);
        } else if (value > max) {
          onChange(max);
        } else {
          onChange(value);
        }
      },
      [onChange, min, max]
    );

    const handleInnerChange = useCallback(
      value => {
        setInnerValue(value);

        if (value.length && value.length > 1) {
          onComplete && onComplete();
        }
      },
      [onComplete]
    );

    const handleStepUp = useCallback(() => {
      handleChange(value + step);
    }, [value, step, handleChange]);

    const handleStepDown = useCallback(() => {
      handleChange(value - step);
    }, [value, step, handleChange]);

    return (
      <TextField
        inputProps={{
          min,
          max,
          step,
          ref,
        }}
        classes={{
          root: clsx(classes.timePickerField, {
            [classes.timePickerFieldInactive]: inactive,
          }),
        }}
        InputProps={{
          classes: {
            input: classes.timePickerInput,
          },
          endAdornment: !disabled && !inactive && (
            <div className={classes.numberInputControls}>
              <ExpandIconUp onClick={handleStepUp} />
              <ExpandIcon onClick={handleStepDown} />
            </div>
          ),
        }}
        value={innerValue}
        type={'number'}
        margin={'dense'}
        variant="outlined"
        onChange={e => handleInnerChange(e.target.value)}
        onBlur={e => handleChange(e.target.value)}
        onFocus={() => {}}
        disabled={disabled}
      />
    );
  }
);

export default TimeNumberField;
