import { gql } from '@apollo/client';

const matchTimerFragment = gql`
  fragment MatchTimerFragment on MatchTimer {
    started
    match_time
    updated_at
  }
`;

export default matchTimerFragment;
