import { gql } from '@apollo/client';

const versionSubscription = gql`
  subscription Version($eventId: EventId!) {
    Version(event_id: $eventId) {
      event_id
      version
    }
  }
`;

export default versionSubscription;
