import React, { useState, useEffect, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useQuery } from '@apollo/client';
import BetTypeSelect, {
  betTypeSelectOptions,
} from '../../../../../components/Inputs/BetTypeSelect/BetTypeSelect';
import NumberField from '../../../../../components/Inputs/NumberField';
import Select from '../../../../../components/Inputs/Select/Select';
import LiabilityMatrixTable from './LiabilityMatrixTable';
import getEventLiabilityMatrixQuery from '../../../../../gql/Reports/queries/getEventLiabilityMatrixQuery';
import useDebounced from '../../../../../hooks/useDebounced';
import usePrevious from '../../../../../../hooks/usePrevious';
import ErrorWidget from '../../../../../components/ErrorWidget';
import { checkErrorsForWidget } from '../../../../../../utils/checkErrorsForWidget';

const useStyles = makeStyles({
  liabilityMatrixTab: {
    margin: 18,
  },
  liabilityMatrixInfo: {
    display: 'inline-flex',
    flexWrap: 'wrap',
  },
  liabilityMatrixInfoItem: {
    fontSize: 14,
    marginRight: 24,
    marginTop: 12,
    whiteSpace: 'nowrap',
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  liabilityMatrixInfoItemTitle: {
    fontWeight: 700,
  },
  liabilityMatrixHelperText: {
    fontSize: 14,
    color: '#8496AB',
  },
  matrixSelect: {
    '& .MuiAutocomplete-inputRoot': {
      paddingRight: '8px!important',
    },
  },
});

const MARKET_FAKE_ID = 'markets-group-fake-id';

const LiabilityMatrixTab = ({ event }) => {
  const classes = useStyles();

  const [betTypes, setBetTypes] = useState([betTypeSelectOptions.single.value]);
  const [size, setSize] = useState(5);
  const [playerId, setPlayerId] = useState(null);
  const [playerIdVariable, setPlayerIdVariable] = useState(playerId);
  const setPlayerIdVariableDebounced = useDebounced(setPlayerIdVariable, 600);

  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedMarketId, setSelectedMarketId] = useState(null);
  const previousGroupId = usePrevious(selectedGroupId);

  const { data, previousData, loading, error } = useQuery(
    getEventLiabilityMatrixQuery,
    {
      variables: {
        eventId: event.id,
        size,
        betTypes,
        playerId: playerIdVariable,
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const matrixData = useMemo(() => {
    return loading
      ? previousData?.getEventLiabilityMatrix
      : data?.getEventLiabilityMatrix;
  }, [data, previousData, loading]);

  const selectedGroup = useMemo(() => {
    return matrixData?.marketGroupsMatrices?.find(
      ({ id }) => id === selectedGroupId
    );
  }, [matrixData, selectedGroupId]);

  const selectedMarket = useMemo(() => {
    return selectedGroup?.marketsMatrices?.find(
      ({ id }) => id === selectedMarketId
    );
  }, [selectedGroup, selectedMarketId]);

  const marketGroupsOptions = useMemo(() => {
    return matrixData?.marketGroupsMatrices?.map(({ name, id }) => ({
      label: name,
      value: id,
    }));
  }, [matrixData]);

  const marketsOptions = useMemo(() => {
    const matricesOptions = selectedGroup?.marketsMatrices?.map(
      ({ name, id }) => ({
        label: name,
        value: id,
      })
    );

    return [
      ...(selectedGroup?.matrix
        ? [{ label: 'Markets group', value: MARKET_FAKE_ID }]
        : []),
      ...(matricesOptions || []),
    ];
  }, [selectedGroup]);

  const turnoverValue = useMemo(
    () => (event.turnover ? event.turnover.toFixed(2) : 0),
    [event]
  );

  const ggrValue = useMemo(
    () => (event.ggr ? event.ggr.toFixed(2) : 0),
    [event]
  );

  const marginValue = useMemo(() => {
    const marginCoef =
      +event.turnover - +event.turnoverOpen !== 0
        ? +event.ggr / (+event.turnover - +event.turnoverOpen)
        : 0;

    return Math.round(marginCoef * 100);
  }, [event]);

  const marketCoverage = useMemo(() => {
    return Math.round(
      (matrixData ? +matrixData.coverage.marketsCoverage : 0) * 100
    );
  }, [matrixData]);

  const liabilityCoverage = useMemo(() => {
    return Math.round(
      (matrixData ? +matrixData.coverage.liabilityCoverage : 0) * 100
    );
  }, [matrixData]);

  const maxValue = useMemo(() => {
    return selectedMarket
      ? Math.round(selectedMarket.maxBetSpecifiersValue)
      : null;
  }, [selectedMarket]);

  const minValue = useMemo(() => {
    return selectedMarket
      ? Math.round(selectedMarket.minBetSpecifiersValue)
      : null;
  }, [selectedMarket]);

  const activeMatrix = useMemo(() => {
    if (selectedMarketId === MARKET_FAKE_ID) {
      return selectedGroup?.matrix;
    }
    return selectedMarket?.matrix || selectedGroup?.matrix;
  }, [selectedMarketId, selectedGroup, selectedMarket]);

  useEffect(() => {
    setPlayerIdVariableDebounced(playerId || null);
  }, [playerId, setPlayerIdVariableDebounced]);

  useEffect(() => {
    if (matrixData?.marketGroupsMatrices?.length && !selectedGroupId) {
      const fullTimeGroup = matrixData.marketGroupsMatrices.find(
        ({ name }) => name === 'Fulltime'
      );

      setSelectedGroupId(
        fullTimeGroup?.id || matrixData.marketGroupsMatrices[0].id
      );
    }
  }, [matrixData, selectedGroupId, setSelectedGroupId]);

  useEffect(() => {
    const selectedGroup = matrixData?.marketGroupsMatrices?.find(
      ({ id }) => id === selectedGroupId
    );

    if (
      selectedGroup?.marketsMatrices?.length &&
      selectedGroupId !== previousGroupId
    ) {
      setSelectedMarketId(
        selectedGroup.matrix
          ? MARKET_FAKE_ID
          : selectedGroup.marketsMatrices[0].id
      );
    }
  }, [matrixData, selectedGroupId, setSelectedMarketId, previousGroupId]);

  const renderInfoItem = (label, value) => (
    <Typography className={classes.liabilityMatrixInfoItem}>
      <span className={classes.liabilityMatrixInfoItemTitle}>
        {`${label}: `}
      </span>
      &nbsp;
      <span>{value}</span>
    </Typography>
  );

  if (checkErrorsForWidget([error])) {
    return <ErrorWidget />;
  }

  return (
    <Box className={classes.liabilityMatrixTab}>
      <Grid container spacing={2}>
        <Grid item xs={2.4}>
          <NumberField
            label="Player ID"
            value={playerId}
            onChange={setPlayerId}
            useStringValue
          />
        </Grid>
        <Grid item xs={2.4}>
          <BetTypeSelect
            label={'Bet type'}
            value={betTypes}
            onChange={setBetTypes}
            className={classes.matrixSelect}
          />
        </Grid>
        <Grid item xs={2.4}>
          <Select
            label={'Markets group'}
            value={selectedGroupId}
            options={marketGroupsOptions}
            onChange={setSelectedGroupId}
            disabled={!marketGroupsOptions?.length}
            className={classes.matrixSelect}
          />
        </Grid>
        <Grid item xs={2.4}>
          <Select
            label={'Market'}
            value={selectedMarketId}
            options={marketsOptions}
            onChange={setSelectedMarketId}
            disabled={!marketsOptions?.length}
            className={classes.matrixSelect}
          />
        </Grid>
        <Grid item xs={2.4}>
          <NumberField
            label={'Score'}
            step={1}
            min={0}
            max={15}
            value={size}
            onChange={setSize}
          />
        </Grid>

        <Grid item xs={12}>
          <Box spac className={classes.liabilityMatrixInfo}>
            {renderInfoItem('Turnover', turnoverValue)}
            {renderInfoItem('GGR settled', ggrValue)}
            {renderInfoItem('Margin', `${marginValue}%`)}
            {renderInfoItem('Market coverage', `${marketCoverage}%`)}
            {renderInfoItem('Liability coverage', `${liabilityCoverage}%`)}
            {typeof maxValue === 'number' &&
              renderInfoItem('Max value', maxValue)}
            {typeof maxValue === 'number' &&
              renderInfoItem('Min value', minValue)}
          </Box>
        </Grid>
        {activeMatrix && (
          <Grid item xs={12}>
            <LiabilityMatrixTable matrix={activeMatrix} />
          </Grid>
        )}
        {activeMatrix && (
          <Grid item xs={12}>
            <Typography className={classes.liabilityMatrixHelperText}>
              Axis X – Home, axis Y – Away
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default LiabilityMatrixTab;
