import RelatedContingencyAutocomplete from './RelatedContingencyAutocomplete';
// import SPORTS_AUTOCOMPLETE_QUERY from '../../../gql/RiskManagement/queries/SPORTS_AUTOCOMPLETE_QUERY';
// TODO: remove OLD_, fix wrong autocomplete request on Related Contingencies page
import OLD_SPORTS_AUTOCOMPLETE_QUERY from '../../../gql/RiskManagement/queries/OLD_SPORTS_AUTOCOMPLETE_QUERY';

const SportAutocomplete = ({ value, onChange, ...props }) => {
  return (
    <RelatedContingencyAutocomplete
      allLabel={'All Sports'}
      label={'Select Sport'}
      gqlQuery={OLD_SPORTS_AUTOCOMPLETE_QUERY}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

export default SportAutocomplete;
