import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { useAuth } from './AuthProvider';
import { setContext } from '@apollo/client/link/context';
import { useApiHost } from '../../providers/ApiHostProvider';
import { createHttpLink } from 'apollo-link-http';
import { ApolloLink, toPromise } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { useDispatch, useSelector, useStore } from 'react-redux';
import cleanTypenameLink from '../gql/apollo-links/cleanTypenameLink';
import typePoliciesQueryFields from '../gql/type-policies/typePoliciesQueryFields';
import {
  AUTH_ENDPOINT,
  decodeToken,
  ERROR_CODES,
} from '../../components/Authorization/utils';
import refreshTokenQuery from '../gql/auth/refreshToken';
import { loginSuccess, logout } from '../../actions/authActions';
import { getUser, getUserRefreshToken } from '../../reducers/users';
import { getActiveSchemaUrl } from '../../reducers/schemaUrls';

const ApolloClientProvider = ({
  endpointOverride,
  endpoints,
  wsEndpoint,
  children,
  allowSubscription = true, // TODO: make false by default, remove "false" props from Main.js
  defaultEndpoint,
}) => {
  const clientRef = useRef(null);
  const { token } = useAuth();
  const tokenRef = useRef(token);
  const { apiBaseUrl } = useApiHost();
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);

  const { search } = useLocation();
  const locationToken = qs.parse(search, { ignoreQueryPrefix: true }).token;
  const encodedUrl = locationToken ? decodeToken(locationToken).url : null;

  const refreshToken = useSelector(getUserRefreshToken);
  const user = useSelector(getUser);
  const schemaUrl = useSelector(getActiveSchemaUrl);

  const defaultEndpointLink = endpoints[defaultEndpoint];

  const endpointsWithAuth = {
    ...endpoints,
    auth: AUTH_ENDPOINT,
  };

  useEffect(() => {
    if (!apiBaseUrl && !encodedUrl) {
      return;
    }
    const httpLink = ApolloLink.from([
      cleanTypenameLink,
      new MultiAPILink({
        endpoints: Object.keys(endpointsWithAuth).reduce((acc, key) => {
          return {
            ...acc,
            [key]: `https://${encodedUrl || apiBaseUrl}${
              endpointsWithAuth[key]
            }/`,
          };
        }, {}),
        createHttpLink: () => createHttpLink(),
        httpSuffix: '',
        defaultEndpoint,
      }),
    ]);

    const wsLink = allowSubscription
      ? new WebSocketLink({
          uri: `wss://${apiBaseUrl}${wsEndpoint || defaultEndpointLink}/`,
          options: {
            reconnect: true,
            connectionParams: {
              headers: {
                Authorization: tokenRef.current
                  ? `Bearer ${tokenRef.current}`
                  : '',
              },
            },
          },
        })
      : null;

    const splitLink =
      wsLink &&
      split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        httpLink
      );

    const client = new ApolloClient({
      link: ApolloLink.from(
        [
          setContext((_, { headers }) => {
            return {
              headers: {
                ...headers,
                authorization: tokenRef.current
                  ? `Bearer ${tokenRef.current}`
                  : '',
              },
            };
          }),
          onError(({ graphQLErrors, networkError, operation, forward }) => {
            if (
              graphQLErrors?.some(
                ({ http_status, code }) =>
                  http_status === 401 && code === ERROR_CODES.TOKEN_EXPIRED
              )
            ) {
              const authLink = new HttpLink({
                uri: `https://${encodedUrl || apiBaseUrl}${AUTH_ENDPOINT}/`,
              });

              const authClient = new ApolloClient({
                link: authLink,
                cache: new InMemoryCache(),
              });

              authClient
                .mutate({
                  mutation: refreshTokenQuery,
                  variables: {
                    token: refreshToken,
                  },
                })
                .then(({ data: { refreshToken: res } }) => {
                  const newRefreshToken = res.refresh_token;
                  const newAccessToken = res.access_token;

                  operation.setContext(({ headers }) => ({
                    headers: {
                      ...headers,
                      authorization: `Bearer ${newAccessToken}`,
                    },
                  }));

                  dispatch(
                    loginSuccess({
                      user: {
                        ...user,
                        refresh_token: newRefreshToken,
                        token: newAccessToken,
                      },
                      schemaUrl,
                    })
                  );
                  tokenRef.current = newAccessToken;

                  client.reFetchObservableQueries().catch(() => {});
                })
                .catch(() => {
                  dispatch(logout());
                });
            }
          }),
        ].concat(splitLink || httpLink)
      ),
      cache: new InMemoryCache({
        // addTypename: false,
        typePolicies: {
          Query: {
            fields: typePoliciesQueryFields,
          },
        },
      }),
    });

    clientRef.current = client;
    setInitialized(true);
    // TODO: FIX DEPS ARRAY ASAP
    // eslint-disable-next-line
  }, [
    token,
    apiBaseUrl,
    endpointOverride,
    allowSubscription,
    defaultEndpoint,
    defaultEndpointLink,
    wsEndpoint,
  ]);

  if (!initialized) {
    return null;
  }

  return <ApolloProvider client={clientRef.current}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
