import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid/Grid';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Button from '../../../../../components/Button/Button';
import FormActions from '../../../../../components/Form/FormActions';
import FormField from '../../../../../components/Form/FormField';
import FormGroup from '../../../../../components/Form/FormGroup';
import BrandSelectWithDetails from '../../../../../components/Inputs/BrandSelectWithDetails/BrandSelectWithDetails';
import DynamicSelect from '../../../../../components/Inputs/DynamicSelect/DynamicSelect';
import TextField from '../../../../../components/Inputs/TextField';
import getAllLineSettingsNames from '../../../../../gql/sportbook-config/line-settings/getAllLineSettingNames';
import getBrandsWithAssignedTemplates from '../../../../../gql/sportbook-config/line-settings/getBrandsWithAssignedTemplates';
import getCoverageNames from '../../../../../gql/sportbook-config/line-settings/getCoverageNames';
import getSourcesNames from '../../../../../gql/sportbook-config/line-settings/getSourcesNames';
import {
  ACCESS_ROLE,
  useSportsBookUserAccessRoles,
} from '../../../../../providers/SportsBookUserAccessRolesProvider';
import { SETTINGS_FORM_MODE } from '../LineSettingsCreatePage/LineSettingsCreatePage';
import ErrorWidget from '../../../../../components/ErrorWidget';
import { checkErrorsForWidget } from '../../../../../../utils/checkErrorsForWidget';

const LineSettingsForm = ({
  defaultValues,
  disabledBrands,
  onSubmit = () => {},
  formMode,
}) => {
  const pagination = useMemo(() => {
    return {
      limit: 0,
      offset: 0,
    };
  }, []);

  const { data: { data: names } = {} } = useQuery(getAllLineSettingsNames, {
    variables: pagination,
  });

  const { data, error } = useQuery(getBrandsWithAssignedTemplates, {
    fetchPolicy: 'no-cache',
  });

  const { roles } = useSportsBookUserAccessRoles();
  const isUserCanEdit =
    roles?.coverage === ACCESS_ROLE.WRITE &&
    roles?.sources === ACCESS_ROLE.WRITE;

  const brands = useMemo(() => {
    return data.data || [];
  }, [data]);

  const defaultNameRef = useRef('');

  const nameValidation = useCallback(
    value => {
      if (isEmpty(names)) {
        return true;
      }

      if (
        formMode === SETTINGS_FORM_MODE.EDIT &&
        value === defaultNameRef.current
      ) {
        return true;
      }

      return names.items.every(item => item.name !== value);
    },
    [names, formMode]
  );

  const nameSchema = yup
    .string()
    .trim()
    .test({
      name: 'unique-name',
      message: 'This name already exists!',
      test: nameValidation,
    })
    .required('Name is a required field');

  const brandIdSchema = yup.array().label('Brand select');

  const lineSettingsValidationSchema = yup.object({
    name: nameSchema,
    brandIds:
      formMode === SETTINGS_FORM_MODE.CREATE
        ? brandIdSchema.required('At least one').min(1)
        : brandIdSchema,
    marginId: yup.string().required('This field is required'),
    sourcesId: yup.string().required('This field is required'),
    coverageId: yup.string().required('This field is required'),
  });

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(lineSettingsValidationSchema),
    defaultValues,
  });
  const navigate = useNavigate();

  const {
    setValue,
    handleSubmit,
    trigger,
    formState: { isValid, isDirty },
  } = methods;

  //margin value temporary mocked
  useEffect(() => {
    defaultValues().then(values => {
      defaultNameRef.current = values.name;
      setValue('name', values.name);
      setValue('marginId', '10090');
      setValue('coverageId', values.coverageId);
      setValue('sourcesId', values.sourcesId);
      setValue('brandIds', values.brandIds);
      setValue('version', values.version);
      //before edit form are opened we need to resolve brand versions of this line settings
      if (!isEmpty(brands)) {
        const currentLineSettingsBrands = [];
        brands.map(item => {
          if (values.brandIds.includes(item.brand.id)) {
            currentLineSettingsBrands.push({
              brandId: item.brand.id,
              settings: item.settings,
            });
          }
        });
        setValue('brands', currentLineSettingsBrands);
      }
      if (formMode === SETTINGS_FORM_MODE.DUPLICATE) {
        trigger('name', { shouldFocus: true });
      }
    });
  }, [defaultValues, setValue, brands, formMode, trigger]);

  const onBrandChange = useCallback(
    (value, sub) => {
      const brands = value.map(value => ({
        brandId: value,
        settings: sub[value].settings,
      }));
      setValue('brands', brands);
    },
    [setValue]
  );

  if (checkErrorsForWidget([error])) {
    return <ErrorWidget />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormProvider {...methods}>
        <FormGroup>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormField name={'name'}>
                <TextField
                  required
                  clearButton
                  disabled={
                    formMode === SETTINGS_FORM_MODE.EDIT && !isUserCanEdit
                  }
                  label={'Line Settings Name'}
                />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              <FormField name={'brandIds'} onChange={onBrandChange}>
                <BrandSelectWithDetails
                  disabled={
                    formMode === SETTINGS_FORM_MODE.EDIT && !isUserCanEdit
                  }
                  required={formMode === SETTINGS_FORM_MODE.CREATE}
                  disabledOptions={disabledBrands}
                  label={'Operators, Brands, Template Name'}
                  multiple
                  items={brands}
                />
              </FormField>
            </Grid>
          </Grid>
        </FormGroup>
        <Box margin={'16px 0 16px 0'}>
          <FormGroup large>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormField name={'sourcesId'}>
                  <DynamicSelect
                    required
                    disabled={
                      formMode === SETTINGS_FORM_MODE.EDIT && !isUserCanEdit
                    }
                    label={'Sources template'}
                    gqlQuery={getSourcesNames}
                  />
                </FormField>
              </Grid>
              <Grid item xs={4}>
                <FormField name={'coverageId'}>
                  <DynamicSelect
                    required
                    disabled={
                      formMode === SETTINGS_FORM_MODE.EDIT && !isUserCanEdit
                    }
                    label={'Coverage template'}
                    gqlQuery={getCoverageNames}
                  />
                </FormField>
              </Grid>
              {/* margin value temporary mocked  */}
              {/* <Grid item xs={4}>
                <FormField name={'marginId'}>
                  <DynamicSelect
                    required
                    disabled
                    label={'Margin template'}
                    gqlQuery={getMarginNames}
                  />
                </FormField>
              </Grid> */}
            </Grid>
          </FormGroup>
        </Box>

        <FormActions>
          <Button
            variant={'text'}
            onClick={() => navigate('/line-settings/line')}
          >
            Cancel
          </Button>
          <Button
            disabled={!isValid || !isUserCanEdit}
            type={'submit'}
            color={'lightBlue'}
          >
            {formMode === SETTINGS_FORM_MODE.CREATE
              ? 'Create Line Settings'
              : 'Save Changes'}
          </Button>
        </FormActions>
      </FormProvider>
    </form>
  );
};

export default LineSettingsForm;
