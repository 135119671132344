import React, { useMemo, useRef } from 'react';
import useTableColumnToggle from '../../hooks/useTableColumnToggle';
import useTableGrid from '../../hooks/useTableGrid';
import useTableColumns from '../../hooks/useTableColumns';
import useTableRowsRender from '../../hooks/useTableRowsRender';
import TableHeaderRow from './TableHeaderRow';
import VirtualizedTable from './VirtualizedTable';
import FancyTable from './FancyTable';
import ExpandableTable from './ExpandableTable';

const Table = ({
  id,
  bulk,
  data = [],
  summary,
  loading,
  rowActions,
  initialToggledColumns,
  onToggleColumns,
  columnsWidth,
  minWidthConfig,
  columns,
  disabledColumns,
  order,
  configMenu = true,
  virtualized,
  expandable,
  renderExpandedRow,
  checkIfRowExpandable,
  orderBy,
  expandAll,
  onChangeOrderBy = () => {},
  onReorder = () => {},
  onChangeColumnsWidth = () => {},
  onUndo = () => {},
  scrollable,
  error,
  withoutHeader = false,
  withUndo = false,
  hideConfigMenu = false,
  adaptiveColumns = false,
}) => {
  const tableRef = useRef(null);

  const [toggledColumns, toggleColumn] = useTableColumnToggle(
    initialToggledColumns,
    onToggleColumns,
    disabledColumns
  );

  const { template, changeColumnWidth } = useTableGrid({
    columns: columnsWidth,
    configMenu,
    order,
    toggledColumns,
    bulk: Boolean(bulk),
    rowActions: Boolean(rowActions),
    onChangeColumnsWidth,
    expandable,
    withUndo,
    minWidthConfig,
    hideConfigMenu,
    ...(adaptiveColumns && {
      tableRef,
      loading,
      data,
      isColumnSizesInPixel: true,
    }),
  });

  const { availableColumns, visibleColumns } = useTableColumns({
    columns,
    order,
    toggledColumns,
  });

  const renderRow = useTableRowsRender({
    availableColumns,
    visibleColumns,
    template,
    rowActions,
    bulk,
    checkIfRowExpandable,
  });

  const TableComponent = virtualized ? VirtualizedTable : FancyTable;

  const props = {
    id: id,
    tableRef: tableRef,
    summary: summary,
    data: data,
    loading: loading,
    renderRow: renderRow,
    scrollable: scrollable,
    renderHeaderRow: !withoutHeader && (
      <TableHeaderRow
        sticky
        bulk={bulk}
        expandAllButtonVisible={expandAll}
        disabledColumns={disabledColumns}
        toggledColumns={toggledColumns}
        template={template}
        onReorder={onReorder}
        availableColumns={availableColumns}
        visibleColumns={visibleColumns}
        toggleColumn={toggleColumn}
        onChangeColumnWidth={changeColumnWidth}
        configMenu={configMenu}
        orderBy={orderBy}
        onChangeOrderBy={onChangeOrderBy}
        scrollable={scrollable}
      />
    ),
    renderExpandedRow,
    renderSummaryRow:
      summary && renderRow({ rowData: summary, isSummary: true }),
    rowHeight: 64,
    error,
    withUndo,
    onUndo,
  };

  if (expandable) {
    return (
      <ExpandableTable {...props}>
        <TableComponent />
      </ExpandableTable>
    );
  }

  return (
    <TableComponent
      {...props}
      // tableOffsetTop={tableOffsetTop}
    />
  );
};

export default Table;
