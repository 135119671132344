import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMarginInput } from '../../hooks/useMarginInput';
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { UiOptionsContext } from '../../uiComponents/Field/UiOptionsProvider';

const useStyles = makeStyles({
  marginInput: {
    '& .MuiOutlinedInput-root': {
      width: '75px',
    },
  },
});

const MarginInput = ({ displayValue, fieldKey }) => {
  const classes = useStyles();

  const { value, onChange, isMarginValid, min, max } = useMarginInput({
    displayValue,
    fieldKey,
  });

  const borderStyle = theme =>
    isMarginValid ? theme.palette.success.main : theme.palette.error.main;

  return (
    <TextField
      label={null}
      type={'number'}
      size={'small'}
      value={value}
      onChange={onChange}
      inputProps={{ step: +value % 1 === 0 ? 1 : 0.1, min, max }}
      color={isMarginValid ? 'success' : 'error'}
      className={classes.marginInput}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: isEmpty(value) ? 'inherit' : borderStyle,
          },
          '&:hover fieldset': {
            borderColor: borderStyle,
          },
        },
      }}
    />
  );
};

export default MarginInput;
