import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import React, { forwardRef, useCallback, useRef } from 'react';
import ExpandIcon from '../../../icons/controls/ExpandIcon';
import mergeRefs from '../../../utils/mergeRefs';

const useStyles = makeStyles({
  numberField: {},

  numberFieldInput: {
    height: 40,
    boxSizing: 'border-box',
    appearance: 'textfield',

    '&::-webkit-inner-spin-button': {
      appearance: 'none',
    },
    '&::-webkit-inner-outer-button': {
      appearance: 'none',
    },
  },

  numberFieldInputLabel: {
    paddingRight: 8,
  },

  numberInputControls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 36,
    color: '#1581FF',

    '& svg': {
      cursor: 'pointer',
    },
  },
});

const NumberField = forwardRef(
  (
    {
      min,
      max,
      step,
      onChange,
      onBlur,
      value,
      fullWidth = true,
      required,
      error,
      helperText,
      disabled,
      useStringValue = false,
      nextStepValue,
      prevStepValue,
      strictRules,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();

    const inputRef = useRef(null);

    const handleChange = useCallback(
      v => {
        if (!onChange) {
          return;
        }

        /* strictRules prop is used to force strict rules on user's input - only numbers and one dot,
        we also allow to enter coma and replace it by dot,
        type attribute is also changed to 'text',
        because default 'number' allows certain non-numeric characters ('e', '+', '-', ',') and silently discards others,
        also each browser has it's own inconsistent locale rules for 'number' inputs */
        if (strictRules && !/^-?\d*[.,]?\d*$/.test(v)) {
          return;
        }

        let value = v;

        if (strictRules) {
          value = v.replace(',', '.');
        }

        if (typeof min !== 'undefined' && value && value < min) {
          onChange(useStringValue ? min.toString() : min);
        } else if (typeof max !== 'undefined' && value && value > max) {
          onChange(useStringValue ? max.toString() : max);
        } else {
          onChange(
            useStringValue
              ? value.toString()
              : typeof Number(value) === 'number'
              ? value === ''
                ? ''
                : Number(value)
              : value
          );
        }
      },
      [onChange, min, max, useStringValue, strictRules]
    );

    const handleStepUp = useCallback(() => {
      if (nextStepValue) {
        handleChange(nextStepValue);
      } else if (strictRules) {
        handleChange(
          ((Number(value) || 0) + step).toFixed(1).replace(/\.0+$/, '')
        );
      } else {
        inputRef.current.stepUp();
        handleChange(inputRef.current.value);
      }
    }, [inputRef, handleChange, nextStepValue, strictRules, step, value]);

    const handleStepDown = useCallback(() => {
      if (prevStepValue) {
        handleChange(prevStepValue);
      } else if (strictRules) {
        handleChange(
          ((Number(value) || 0) - step).toFixed(1).replace(/\.0+$/, '')
        );
      } else {
        inputRef.current.stepDown();
        handleChange(inputRef.current.value);
      }
    }, [inputRef, handleChange, prevStepValue, strictRules, step, value]);

    const handleKeyDown = useCallback(
      e => {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          handleStepUp();
        } else if (e.key === 'ArrowDown') {
          e.preventDefault();
          handleStepDown();
        }
      },
      [handleStepUp, handleStepDown]
    );

    return (
      <TextField
        onKeyDown={handleKeyDown}
        inputProps={{
          min,
          max,
          step,
          ref: mergeRefs(ref, inputRef),
        }}
        classes={{
          root: classes.numberField,
        }}
        InputLabelProps={{
          style: {
            overflow: 'hidden',
          },
          classes: {
            root: step && classes.numberFieldInputLabel,
          },
        }}
        InputProps={{
          classes: {
            input: classes.numberFieldInput,
          },
          endAdornment: step && !disabled && (
            <div className={classes.numberInputControls}>
              <ExpandIcon
                expanded
                onClick={handleStepUp}
                disabled={!nextStepValue && step && max && value + step > max}
              />
              <ExpandIcon
                onClick={handleStepDown}
                disabled={!prevStepValue && step && min && Number(value) <= min}
              />
            </div>
          ),
        }}
        value={value === null || typeof value === 'undefined' ? '' : value}
        type={strictRules ? 'text' : 'number'}
        margin={'dense'}
        variant="outlined"
        fullWidth={fullWidth}
        required={required}
        error={error}
        helperText={helperText}
        disabled={disabled}
        {...props}
        onChange={e => handleChange(e.target.value)}
        onBlur={onBlur}
      />
    );
  }
);

export default NumberField;
