import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useTableMaxContentSizesInColumns from './useTableMaxContentSizesInColumns';
import { isEmpty } from 'lodash';
import { usePresets } from '../providers/PresetsProvider';
import useLocalStorageTableConfig, {
  tableConfigKeys,
} from './useLocalStorageTableConfig';

const useTableGrid = ({
  tableName,
  columns,
  order = [],
  toggledColumns,
  expandable,
  onChangeColumnsWidth,
  minWidthConfig,
  bulk,
  initialColumnWidth,
  hideConfigMenu,
  withUndo = false,
  //for adjust column sizes by maximal in cell when we need it
  tableRef,
  loading,
  data,
  isColumnSizesInPixel = false,
}) => {
  const { activePreset, savePreset } = usePresets();
  const activePresetValue = useMemo(() => {
    return activePreset || {};
  }, [activePreset]);
  const { getTableConfigValue, setTableConfigValue } =
    useLocalStorageTableConfig(tableName);
  const presetColumnsWidth = useMemo(() => {
    return activePresetValue?.data?.columns_width || {};
  }, [activePresetValue]);
  const defaultSizesInPixelsRef = useRef(false);

  const columnKeys = useMemo(() => {
    return order.filter(key => toggledColumns.indexOf(key) !== -1);
  }, [order, toggledColumns]);

  const fixedColumns = useMemo(() => {
    return (
      columns &&
      Object.keys(columns).reduce((acc, key) => {
        if (
          typeof columns[key] === 'string' ||
          (presetColumnsWidth && typeof presetColumnsWidth[key] === 'string')
        ) {
          return {
            ...acc,
            [key]: true,
          };
        }

        return acc;
      }, {})
    );
  }, [columns, presetColumnsWidth]);

  const defaultColumnWidth = useMemo(() => {
    return { ...initialColumnWidth, ...presetColumnsWidth };
  }, [initialColumnWidth, presetColumnsWidth]);

  const [columnWidth, setColumnWidth] = useState(columns);

  useEffect(() => {
    setColumnWidth({ ...columns, ...presetColumnsWidth });
  }, [columns, presetColumnsWidth]);

  useEffect(() => {
    setTableConfigValue(tableConfigKeys.columnsWidth, presetColumnsWidth);
  }, [presetColumnsWidth, setTableConfigValue]);

  useEffect(() => {
    if (activePresetValue?.id === '0') {
      defaultSizesInPixelsRef.current = true;
    }
  }, [activePresetValue?.id]);

  isColumnSizesInPixel &&
    useTableMaxContentSizesInColumns({
      tableRef,
      toggledColumns,
      loading,
      data,
      columnWidth,
      setColumnWidth,
      fixedColumns,
      initialColumnWidth: defaultColumnWidth,
    });

  //just making column and table wider
  const changeSizeByPixels = useCallback(({ current, key, diffPx }) => {
    const currentSize = current[key];

    const newWidth = currentSize + diffPx;

    const allowResize = newWidth > 0;

    return {
      ...current,
      [key]: allowResize ? newWidth : currentSize,
    };
  }, []);

  //resize by decrease neighbor column size
  const changeSizeByFr = useCallback(
    ({ current, key, diff }) => {
      const index = columnKeys.indexOf(key);
      const nextIndex = index + 1;
      const nextKey = columnKeys[nextIndex];

      const currentSize = current[key];
      const nextCurrentFr = current[nextKey];

      const frDiff = (currentSize * diff) / 100;
      const newWidth = currentSize + frDiff;
      const nextNewWidth = nextCurrentFr - frDiff;

      const allowResize = newWidth > 0 && nextNewWidth > 0;

      return {
        ...current,
        [key]: allowResize ? newWidth : currentSize,
        [nextKey]: allowResize ? nextNewWidth : nextCurrentFr,
      };
    },
    [columnKeys]
  );

  const saveColumnWidthConfig = useCallback(
    (columnKey, columnWidth) => {
      const columnSize = columnWidth[columnKey];
      const columnSizeValue = isColumnSizesInPixel
        ? columnSize + 'px'
        : columnSize;
      const currentColumnsWidth = getTableConfigValue(
        tableConfigKeys.columnsWidth
      );
      const columnsWidthConfig = {
        ...currentColumnsWidth,
        [columnKey]: columnSizeValue,
      };

      setTableConfigValue(tableConfigKeys.columnsWidth, columnsWidthConfig);

      return columnsWidthConfig;
    },
    [isColumnSizesInPixel, getTableConfigValue, setTableConfigValue]
  );

  const savePresetColumnsWidth = useCallback(
    columnsWidth => {
      if (activePresetValue?.id && activePresetValue?.id !== '0') {
        const presetData = {
          ...activePresetValue,
          data: {
            ...activePresetValue?.data,
            columns_width: columnsWidth,
          },
        };

        savePreset(activePresetValue?.id, presetData);
      }
    },
    [activePresetValue, savePreset]
  );

  const changeColumnWidth = useCallback(
    (key, diff, diffPx, isDragStopped) => {
      setColumnWidth(current => {
        const result = isColumnSizesInPixel
          ? changeSizeByPixels({ current, key, diffPx })
          : changeSizeByFr({ current, key, diff });

        onChangeColumnsWidth(result);

        const columnsWidthConfig = saveColumnWidthConfig(key, result);

        if (isDragStopped) {
          savePresetColumnsWidth(columnsWidthConfig);
        }

        return result;
      });
    },
    [
      onChangeColumnsWidth,
      isColumnSizesInPixel,
      changeSizeByFr,
      changeSizeByPixels,
      savePresetColumnsWidth,
      saveColumnWidthConfig,
    ]
  );

  const columnsTemplate = useMemo(() => {
    const newTemplate = order
      .filter(key => toggledColumns.indexOf(key) !== -1)
      .map(key => {
        const value = columnWidth[key];

        if (isColumnSizesInPixel && defaultSizesInPixelsRef.current) {
          return 'fit-content(100%)';
        }

        if (isColumnSizesInPixel) {
          return value && !isEmpty(data)
            ? columnWidth.px
              ? `${value}px`
              : `minmax(${value}px, auto)`
            : 'fit-content(100%)';
        }

        if (fixedColumns[key]) {
          return value;
        }

        if (minWidthConfig) {
          const minWidth = minWidthConfig[key] || '24px';
          return `minmax(${minWidth}, ${value}fr)`;
        }

        return `minmax(24px, ${value}fr)`;
      });
    defaultSizesInPixelsRef.current = false;

    return newTemplate;
  }, [
    data,
    order,
    columnWidth,
    toggledColumns,
    fixedColumns,
    minWidthConfig,
    isColumnSizesInPixel,
  ]);

  const resetColumnWidth = useCallback(
    key => {
      const index = columnKeys.indexOf(key);
      const nextIndex = index + 1;
      const nextKey = columnKeys[nextIndex];

      setColumnWidth(current => {
        const result = {
          ...current,
          [key]: defaultColumnWidth && defaultColumnWidth[key],
          [nextKey]: defaultColumnWidth && defaultColumnWidth[nextKey],
        };

        onChangeColumnsWidth(result);

        return result;
      });
    },
    [onChangeColumnsWidth, defaultColumnWidth, columnKeys]
  );

  return useMemo(() => {
    let template = columnsTemplate;
    let rowButtonsWidth = 0;

    if (expandable) {
      rowButtonsWidth += 34;
    }

    if (withUndo) {
      template = [...template, hideConfigMenu ? '0' : '28px'];
    }

    template = [...template, hideConfigMenu ? '0' : '28px'];

    if (bulk) {
      rowButtonsWidth += 34;
    }

    if (bulk || expandable) {
      template = [`${rowButtonsWidth}px`, ...template];
    }

    return {
      template: template.join(' '),
      changeColumnWidth,
      resetColumnWidth,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnsTemplate,
    changeColumnWidth,
    expandable,
    bulk,
    resetColumnWidth,
    withUndo,
  ]);
};

export default useTableGrid;
