import { gql } from '@apollo/client';

//TODO: hotfixed for release, need to remove this in RelatedContingencyFields and use GetTournamentsNames
const OLD_GetTournamentsNames = gql`
  query getTournamentsNames(
    $query: String!
    $limit: Int
    $offset: Int
    $sport_ids: [ID!]
    $exclude_sport_ids: Boolean
    $categories_ids: [ID!]
    $exclude_categories_ids: Boolean
    $tournaments_ids: [ID!]
  ) @api(name: rm) {
    data: getTournamentsNames(
      query: $query
      limit: $limit
      offset: $offset
      sport_ids: $sport_ids
      exclude_sport_ids: $exclude_sport_ids
      categories_ids: $categories_ids
      exclude_categories_ids: $exclude_categories_ids
      tournaments_ids: $tournaments_ids
    ) {
      label
      value
    }
  }
`;

export default OLD_GetTournamentsNames;
