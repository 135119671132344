import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Radio } from '@mui/material';
import Grid from '@mui/material/Grid/Grid';
import { isObject, unset } from 'lodash';
import Stack from '@mui/material/Stack/Stack';
import dayjs from 'dayjs';
import qs from 'qs';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import RangeDateInput from '../../../../../components/inputs/RangeDateInput';
import useNavigationPreventPrompt from '../../../../../hooks/useNavigationPreventPrompt';
import Button from '../../../../components/Button/Button';
import FormField from '../../../../components/Form/FormField';
import FormGroup from '../../../../components/Form/FormGroup';
import FormGroupLabel from '../../../../components/Form/FormGroupLabel';
import WatchFormValue from '../../../../components/Form/WatchField';
import BannerPlaceholderSelect from '../../../../components/Inputs/BannerPlaceholderSelect/PlaceholderSelect';
import FancyBrandSelect from '../../../../components/Inputs/BrandSelect/FancyBrandSelect';
import FancyOperatorSelect from '../../../../components/Inputs/OperatorSelect/FancyOperatorSelect';
import RadioGroup from '../../../../components/Inputs/RadioGroup/RadioGroup';
import TextField from '../../../../components/Inputs/TextField';
import NotificationAlert, {
  AlertVariant,
} from '../../../../components/Notifications/NotificationAlert';
import updatePriorities from '../../../../gql/Promo/mutations/updatePriorities';
import { useIsOperator } from '../../../../providers/OperatorProvider';
import { useToasts } from '../../../../providers/ToastsProvider';
import useBannerActivate from '../../hooks/useBannerActivate';
import useBannerSave from '../../hooks/useBannerSave';
import BannerPlatformSelect from '../BannerFields/BannerPlatformSelect';
import BannerPriorityList, {
  TEMP_BANNER_ID,
} from '../BannerPriorityList/BannerPriorityList';
import BannerRestrictions from '../BannerRestrictions/BannerRestrictions';
import BannerTypeSelect from '../BannerTypeSelect/BannerTypeSelect';
import BannerViewSelect from '../BannerViewSelect/BannerViewSelect';
import BannerSubform from './BannerSubform';
import BannerValidationSchema, {
  ActivatePeriod,
  BannerState,
  ViewsByType,
} from './BannerValidationSchema';
import ErrorWidget from '../../../../components/ErrorWidget';
import { checkErrorsForWidget } from '../../../../../utils/checkErrorsForWidget';

const removeRedundantValuesFromBannerPayload = object => {
  return {
    ...object,
    line_banner: {
      ...object.line_banner,
      boosted_odds_view: {
        ...object.line_banner.boosted_odds_view,
        locales: object.line_banner.boosted_odds_view.locales.map(l => {
          const details = l.details;

          unset(details, 'initial_odd');
          unset(details, 'boosted_odd');

          return {
            ...l,
            details: details,
          };
        }),
      },
    },
  };
};

const BannerForm = memo(
  ({
    bannerId,
    defaultValues,
    onSubmit,
    checkDirty = true,
    isDuplicate = false,
  }) => {
    const [active, setActive] = useState(false);
    const [prioritiesInput, setPrioritiesInput] = useState(null);
    const [pendingPrioritiesInput, setPendingPrioritiesInput] = useState(null);
    const isOperator = useIsOperator();
    const contextRef = useRef({
      isOperator,
      activate: false,
      currentDate: dayjs().unix(),
      isSubmitProcess: false,
    });
    const { search } = useLocation();
    const isValidateOnLoadRef = useRef(
      qs.parse(search, { ignoreQueryPrefix: true }).validate
    );

    const [
      changePriorities,
      { loading: prioritiesUpdating, error: updatePrioritiesError },
    ] = useMutation(updatePriorities);

    const isActivateOnSave = active && !isDuplicate;

    useEffect(() => {
      defaultValues().then(values => {
        setActive(
          values.state === BannerState.ACTIVE ||
            values.state === BannerState.SCHEDULED ||
            values.state === BannerState.ENDED
        );
      });
    }, [defaultValues]);
    const form = useForm({
      resolver: yupResolver(BannerValidationSchema, {
        // stripUnknown: true,
      }),
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues,
      context: contextRef.current,
      resetOptions: {
        keepDirtyValues: false,
      },
    });

    const {
      handleSubmit,
      watch,
      formState: { isDirty, isLoading, errors },
      reset,
      trigger,
    } = form;

    useEffect(() => {
      if (!isLoading && isValidateOnLoadRef.current) {
        const prevActivateValue = contextRef.current.activate;
        contextRef.current.activate = true;
        trigger().then(() => {
          contextRef.current.activate = prevActivateValue;
        });
      }
    }, [isLoading, trigger]);

    useNavigationPreventPrompt({
      prevent: isDirty && !contextRef.current.isSubmitProcess,
    });

    const {
      saveBanner,
      loading: isSaving,
      error: saveError,
    } = useBannerSave(bannerId);
    const {
      activateBanner,
      loading: isActivating,
      error: activateError,
    } = useBannerActivate(bannerId);
    const { showToast } = useToasts();

    const navigate = useNavigate();

    const onSaveDraft = useCallback(
      async data => {
        let payload = data;

        if (
          payload.banner_type === 'line_banner' &&
          isObject(payload.line_banner.boosted_odds_view)
        ) {
          payload = removeRedundantValuesFromBannerPayload(payload);
        }

        try {
          if (isDirty || isDuplicate) {
            const res = await saveBanner(payload);
            reset(data);

            if (res.data && res.data.id && res.data.id !== bannerId) {
              if (prioritiesInput) {
                // remove draft banner id from request
                const requestInput = {
                  ...prioritiesInput,
                  items: prioritiesInput.items.filter(
                    id => id !== bannerId && id !== TEMP_BANNER_ID
                  ),
                };
                await changePriorities({
                  variables: {
                    input: requestInput,
                  },
                });
                //save priorities input with new id in case user uses activate without changing any values
                const pendingInput = {
                  ...prioritiesInput,
                  items: prioritiesInput.items.map(id =>
                    id === bannerId || id === TEMP_BANNER_ID
                      ? res.data && res.data.id
                      : id
                  ),
                };

                setPendingPrioritiesInput(pendingInput);
                setPrioritiesInput(null);
              }
              navigate('/banners', { replace: true }); // replace because prev bannerId became invalid
            } else {
              navigate('/banners');
            }

            showToast(
              <NotificationAlert variant={AlertVariant.SUCCESS}>
                Banner {bannerId ? 'updated' : 'created'} successfully
              </NotificationAlert>
            );
          } else if (prioritiesInput) {
            // remove draft banner id from request
            const requestInput = {
              ...prioritiesInput,
              items: prioritiesInput.items.filter(id => id !== bannerId),
            };
            await changePriorities({
              variables: {
                input: requestInput,
              },
            });
            //save priorities input in case user uses activate without changing any values
            setPendingPrioritiesInput(prioritiesInput);
            setPrioritiesInput(null);

            showToast(
              <NotificationAlert variant={AlertVariant.SUCCESS}>
                Banner updated successfully
              </NotificationAlert>
            );

            navigate('/banners');
          }
        } catch (e) {
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR}>
              An error occurred while saving the banner
            </NotificationAlert>
          );
        }
      },
      [
        isDirty,
        isDuplicate,
        saveBanner,
        showToast,
        bannerId,
        navigate,
        reset,
        prioritiesInput,
        changePriorities,
      ]
    );

    const onActivate = useCallback(
      async data => {
        let payload = data;

        if (
          payload.banner_type === 'line_banner' &&
          isObject(payload.line_banner.boosted_odds_view)
        ) {
          payload = removeRedundantValuesFromBannerPayload(payload);
        }

        try {
          if (!active || isDirty) {
            const res = await activateBanner(payload);
            reset(data);

            if (res.data && res.data.id && res.data.id !== bannerId) {
              if (prioritiesInput || pendingPrioritiesInput) {
                // replace old banner id with new
                const requestInput = {
                  ...(prioritiesInput || pendingPrioritiesInput),
                  items: (prioritiesInput || pendingPrioritiesInput).items.map(
                    id =>
                      id === bannerId || id === TEMP_BANNER_ID
                        ? res.data && res.data.id
                        : id
                  ),
                };
                await changePriorities({
                  variables: {
                    input: requestInput,
                  },
                });
                setPendingPrioritiesInput(null);
                setPrioritiesInput(null);
              }
              navigate('/banners', { replace: true }); // replace because prev bannerId became invalid
            } else {
              navigate('/banners');
            }

            showToast(
              <NotificationAlert variant={AlertVariant.SUCCESS}>
                Banner {active ? 'updated' : 'activated'} successfully
              </NotificationAlert>
            );
          } else if (prioritiesInput) {
            await changePriorities({
              variables: {
                input: prioritiesInput,
              },
            });
            setPrioritiesInput(null);

            showToast(
              <NotificationAlert variant={AlertVariant.SUCCESS}>
                Banner updated successfully
              </NotificationAlert>
            );

            navigate('/banners');
          }
        } catch (e) {
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR}>
              An error occurred while {active ? 'updating' : 'activating'} the
              banner
            </NotificationAlert>
          );
        }
      },
      [
        isDirty,
        activateBanner,
        showToast,
        bannerId,
        navigate,
        reset,
        active,
        prioritiesInput,
        pendingPrioritiesInput,
        changePriorities,
      ]
    );

    const selectedBrand = watch('brand');

    if (
      checkErrorsForWidget([saveError, activateError, updatePrioritiesError])
    ) {
      return <ErrorWidget />;
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormProvider {...form}>
          <WatchFormValue name={'banner_type'}>
            {({ banner_type: bannerType }) => (
              <Grid container spacing={2} key={bannerType}>
                <Grid item xs={12}>
                  <FormGroup large>
                    <Grid container spacing={2} direction={'column'}>
                      <Grid item xs={12}>
                        <FormGroupLabel>Banner details</FormGroupLabel>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <FormField name={'name'}>
                              <TextField required label={'Name'} />
                            </FormField>
                          </Grid>

                          {!isOperator && (
                            <Grid item xs={4}>
                              <FormField name={'operator'}>
                                <FancyOperatorSelect required />
                              </FormField>
                            </Grid>
                          )}

                          <Grid item xs={4}>
                            <FormField
                              name={'brand'}
                              deps={{ operator: 'operator' }}
                            >
                              {({ operator }) => (
                                <FancyBrandSelect
                                  required
                                  disabled={
                                    (!operator || !operator.id) && !isOperator
                                  }
                                  withOperator={!isOperator}
                                  operatorIds={operator ? [operator.id] : []}
                                  checkValueOptions={false}
                                />
                              )}
                            </FormField>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup large>
                    <Grid container spacing={2} direction={'column'}>
                      <Grid item xs={12}>
                        <FormGroupLabel
                          helpText={
                            "Select 'Permanently' if you want to show the banner that has no end date or choose a date period if availability of the banner should be restricted by time"
                          }
                        >
                          Banner will be shown
                        </FormGroupLabel>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container alignItems={'center'} spacing={2}>
                          <Grid item xs={4}>
                            <FormField name={'activate.type'}>
                              <RadioGroup
                                row
                                sx={{
                                  height: 40,
                                }}
                              >
                                <FormControlLabel
                                  value={ActivatePeriod.PERMANENTLY}
                                  control={<Radio color={'primary'} />}
                                  label="Permanently"
                                />

                                <FormControlLabel
                                  value={ActivatePeriod.PERIOD}
                                  control={<Radio color={'primary'} />}
                                  label="Period"
                                />
                              </RadioGroup>
                            </FormField>
                          </Grid>

                          <Grid item xs={4}>
                            <FormField
                              name={'activate.period'}
                              deps={{ activateType: 'activate.type' }}
                            >
                              {({ activateType }) => {
                                return activateType ===
                                  ActivatePeriod.PERIOD ? (
                                  <RangeDateInput
                                    required
                                    fullWidth
                                    label={'Duration'}
                                    rangeFromKey={'range_from'}
                                    rangeToKey={'range_to'}
                                    errorText={
                                      errors.activate &&
                                      (errors.activate.period?.range_from
                                        ?.message ||
                                        errors.activate.period?.range_to
                                          ?.message)
                                    }
                                  />
                                ) : null;
                              }}
                            </FormField>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction={'column'} spacing={0.5}>
                    <FormGroup large>
                      <Grid container spacing={2} direction={'column'}>
                        <Grid item xs={12}>
                          <FormGroupLabel>Banner type</FormGroupLabel>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            maxWidth: '100%!important',
                            // forced because placeholder input can become very long and mess up the grid
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <FormField name={'banner_type'}>
                                <BannerTypeSelect required />
                              </FormField>
                            </Grid>
                            <Grid item xs={4}>
                              {bannerType && (
                                <FormField
                                  key={bannerType}
                                  name={`${bannerType}.placeholders`}
                                  deps={{
                                    brand: 'brand',
                                  }}
                                >
                                  {({ brand }) => (
                                    <BannerPlaceholderSelect
                                      disabled={
                                        !bannerType || !(brand && brand.id)
                                      }
                                      multiple
                                      bannerType={bannerType}
                                      brandId={brand && brand.id}
                                    />
                                  )}
                                </FormField>
                              )}
                            </Grid>
                            <Grid item xs={4}>
                              <FormField name={'display_platform'}>
                                <BannerPlatformSelect />
                              </FormField>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </FormGroup>

                    <BannerRestrictions bannerType={bannerType} />

                    {bannerType && ViewsByType[bannerType] && (
                      <FormGroup>
                        <Stack spacing={2} direction={'row'}>
                          <FormField
                            name={`${bannerType}.view_type`}
                            key={bannerType}
                          >
                            <BannerViewSelect
                              options={ViewsByType[bannerType]}
                            />
                          </FormField>
                        </Stack>
                      </FormGroup>
                    )}
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <BannerPriorityList
                    bannerId={bannerId}
                    onChangePriorities={setPrioritiesInput}
                  />
                </Grid>

                <Grid item xs={12}>
                  <BannerSubform
                    extraParams={
                      selectedBrand ? { brand_id: selectedBrand.id } : {}
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <Stack spacing={2} direction={'row-reverse'}>
                      {!active && (
                        <Button
                          type={'submit'}
                          color={'lightBlue'}
                          onClick={e => {
                            contextRef.current.isSubmitProcess = true;
                            contextRef.current.activate = true;
                            contextRef.current.currentDate = dayjs().unix();
                            handleSubmit(onActivate)(e);
                          }}
                          disabled={
                            isActivating || isSaving || prioritiesUpdating
                          }
                          loading={isActivating}
                        >
                          Activate
                        </Button>
                      )}

                      <Button
                        type={'submit'}
                        color={'lightBlue'}
                        disabled={
                          isActivating ||
                          isSaving ||
                          (!isDirty && checkDirty && !prioritiesInput) ||
                          prioritiesUpdating
                        }
                        onClick={e => {
                          contextRef.current.isSubmitProcess = true;
                          contextRef.current.activate = isActivateOnSave;
                          contextRef.current.currentDate = dayjs().unix();
                          handleSubmit(
                            isActivateOnSave ? onActivate : onSaveDraft
                          )(e);
                        }}
                        loading={isActivateOnSave ? isActivating : isSaving}
                      >
                        Save
                      </Button>
                      <Button
                        variant={'text'}
                        color={'error'}
                        onClick={() => navigate('/banners')}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </FormGroup>
                </Grid>
              </Grid>
            )}
          </WatchFormValue>
        </FormProvider>
      </form>
    );
  }
);

export default BannerForm;
