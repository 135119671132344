import React, { useCallback } from 'react';
import TableHeaderRow from '../../components/Table/TableHeaderRow';
import FancyTable from '../../components/Table/FancyTable';
import useTableGrid from '../../hooks/useTableGrid';
import useTableColumns from '../../hooks/useTableColumns';
import LinkField from '../../components/Fields/LinkField/LinkField';
import StringField from '../../components/Fields/StringField/StringField';
import Select from '../../components/Inputs/Select/Select';
import { omit } from 'lodash';
import Button from '@mui/material/Button';
import TextField from '../../components/Inputs/TextField';
import { allOptions } from './NewOutcomesTable';
import TableRow from '../../components/Table/TableRow';
import TableCell from '../../components/Table/TableCell';
import useTableColumnsReorder from '../../hooks/useTableColumnsReorder';
import { useQuery } from '@apollo/client';
import RECEIVED_COMMANDS_QUERY from '../../gql/Settlements/new/RECEIVED_COMMANDS';
import APPLIED_COMMANDS_QUERY from '../../gql/Settlements/new/APPLIED_COMMANDS';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import DateField from '../../components/Fields/DateField/DateField';

const commonCellStyle = {
  padding: '0px 4px',
};

export const COLUMN = {
  TIME: 'time',
  PROVIDER: 'provider',
  TYPE: 'type',
  RESULT: 'result',
  VOID_FACTOR: 'void_factor',
};

export const columnsWidthConfig = {
  [COLUMN.TIME]: 1,
  [COLUMN.PROVIDER]: 1,
  [COLUMN.TYPE]: 1,
  [COLUMN.RESULT]: 1,
  [COLUMN.VOID_FACTOR]: 1,
};

export const columnsOrderConfig = [
  COLUMN.TIME,
  COLUMN.PROVIDER,
  COLUMN.TYPE,
  COLUMN.RESULT,
  COLUMN.VOID_FACTOR,
];

export const initialToggledColumns = [
  COLUMN.TIME,
  COLUMN.PROVIDER,
  COLUMN.TYPE,
  COLUMN.RESULT,
  COLUMN.VOID_FACTOR,
];

const CommandsTable = ({ type, filters }) => {
  const ROW_HEIGHT = 38;

  const { data, loading } = useQuery(
    type === 'applied' ? APPLIED_COMMANDS_QUERY : RECEIVED_COMMANDS_QUERY,
    {
      variables: {
        filters: {
          ...filters,
          limit: -1,
          offset: 0,
        },
      },
    }
  );

  const [columnsOrder] = useTableColumnsReorder(columnsOrderConfig);

  const { template, changeColumnWidth } = useTableGrid({
    columns: columnsWidthConfig,
    toggledColumns: initialToggledColumns,
    onChangeColumnsWidth: () => {},
    expandable: false,
    rowActions: false,
    order: columnsOrder,
  });
  console.log(initialToggledColumns);
  const { visibleColumns } = useTableColumns({
    columns: [
      {
        style: commonCellStyle,
        label: 'Time',
        key: COLUMN.TIME,
        render: data => {
          return (
            <StringField>
              <DateField date={data.timestamp} format={'YYYY/MM/DD HH:mm:ss'} />
            </StringField>
          );
        },
        resizable: true,
      },
      {
        style: commonCellStyle,
        label: 'Provider',
        key: COLUMN.PROVIDER,
        render: data => {
          return <StringField>{data.provider}</StringField>;
        },
        resizable: true,
      },
      {
        style: commonCellStyle,
        label: 'Type',
        key: COLUMN.TYPE,
        render: data => {
          return <StringField>{data.type}</StringField>;
        },
        resizable: true,
      },
      {
        style: commonCellStyle,
        label: 'Result',
        key: COLUMN.RESULT,
        render: data => {
          return <StringField>{data.result}</StringField>;
        },
        resizable: true,
      },
      {
        style: commonCellStyle,
        label: 'Void factor',
        key: COLUMN.VOID_FACTOR,
        render: data => {
          return <StringField>{data.void_factor || '-'}</StringField>;
        },
        resizable: true,
      },
    ],
    order: columnsOrderConfig,
    toggledColumns: initialToggledColumns,
  });

  const renderCells = useCallback(
    rowData => {
      return visibleColumns.map((column, index) => {
        return (
          <TableCell
            isLast={index === visibleColumns.length - 1}
            key={column.key}
            deps={column.deps}
            rowData={rowData}
            cellClassName={column.cellClassName}
            render={column.render}
            style={column.style}
            keepMinWidth={column.keepMinWidth}
          />
        );
      });
    },
    [visibleColumns]
  );

  const renderRow = useCallback(
    ({ rowIndex, rowData, rowId, isVisible }) => {
      return (
        <TableRow
          gridTemplate={template}
          rowId={rowId}
          rowIndex={rowIndex}
          rowData={rowData}
          // className={getRowClassName(rowData)}
          renderCells={renderCells}
          isVisible={isVisible}
          bulk={false}
        />
      );
    },
    [template, renderCells]
  );

  return (
    <Box
      sx={{
        overflowY: 'auto',
        height: '260px',
      }}
    >
      <FancyTable
        loading={loading}
        id={COLUMN.TIME}
        data={data?.data?.items || []}
        renderRow={renderRow}
        headerRowStyles={{
          top: 0,
        }}
        renderHeaderRow={
          <TableHeaderRow
            onChangeColumnWidth={changeColumnWidth}
            configMenu={false}
            template={template}
            visibleColumns={visibleColumns}
          />
        }
        rowHeight={ROW_HEIGHT}
      />
    </Box>
  );
};

export default CommandsTable;
