import { ERROR_CODES } from '../components/Authorization/utils';

export const isNoGqlAuthError = error => {
  if (error?.graphQLErrors) {
    return error?.graphQLErrors?.every(
      value =>
        value.http_status !== 401 || value.code !== ERROR_CODES.TOKEN_EXPIRED
    );
  } else {
    return (
      error?.http_status !== 401 || error?.code !== ERROR_CODES.TOKEN_EXPIRED
    );
  }
};

export const checkErrorsForWidget = errors => {
  return errors.every(error => error && isNoGqlAuthError(error));
};
