import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import TimeNumberField from '../../../../../components/DatePicker/TimeNumberField';
import Button from '../../../../../components/Button/Button';
import StartIcon from '../../../../../icons/ic-start.svg';
import StopIcon from '../../../../../icons/ic-stop.svg';

const useStyles = makeStyles({
  tradingEventTimer: {
    display: 'flex',
    alignItems: 'center',
  },
  timeDivider: {
    margin: '0 4px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    alignItems: 'center',
    letterSpacing: '0.2px',
  },
  timerButton: {
    marginLeft: 8,
    width: 58,
    minWidth: 58,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 24,
    backgroundPosition: 'center',
  },
  timerStartButton: {
    backgroundImage: `url(${StartIcon})`,
  },
  timerStopButton: {
    backgroundImage: `url(${StopIcon})`,
  },
});

const TradingEventTimer = ({
  className,
  value,
  onChange,
  onStart,
  onStop,
  disabled,
  isRunning,
  isShowSetButton,
  onSetButtonClick,
}) => {
  const classes = useStyles();

  const minutesValue = Math.floor(value / 60);
  const secondsValue = value - minutesValue * 60;

  return (
    <div className={clsx(classes.tradingEventTimer, className)}>
      <TimeNumberField
        value={minutesValue}
        min={0}
        formatedValue={minutesValue}
        onChange={minutes => {
          onChange(parseInt(minutes) * 60 + secondsValue);
        }}
        disabled={disabled}
        inactive={isRunning}
      />
      <div className={classes.timeDivider}>:</div>
      <TimeNumberField
        value={secondsValue}
        min={0}
        max={60}
        formatedValue={secondsValue}
        onChange={seconds => {
          onChange(minutesValue * 60 + parseInt(seconds));
        }}
        disabled={disabled}
        inactive={isRunning}
      />
      <Button
        color={isRunning ? 'redButton' : 'primary'}
        className={clsx(classes.timerButton, {
          [classes.timerStartButton]: !isRunning,
          [classes.timerStopButton]: isRunning,
        })}
        onClick={isRunning ? onStop : onStart}
        disabled={disabled}
      />
      {isShowSetButton && (
        <Button
          color="lightBlue"
          className={classes.timerButton}
          onClick={() => onSetButtonClick(value)}
          disabled={disabled}
        >
          Set
        </Button>
      )}
    </div>
  );
};

export default TradingEventTimer;
