import { useCallback, useContext, useEffect, useState } from 'react';
import { DataContext } from '../providers/DataProvider';
import { UiOptionsContext } from '../uiComponents/Field/UiOptionsProvider';
import { useTableApplyContext } from '../providers/TableApplyProvider';
import { get, omitBy, pickBy } from 'lodash';
import { SchemaContext } from '../providers/SchemaProvider';

export const checkMarginValid = (value, min, max) => {
  const numberValue = +value;

  return (numberValue >= min && numberValue <= max) || value === '';
};

export const useMarginInput = ({ displayValue, fieldKey }) => {
  const { rowData } = useContext(UiOptionsContext);
  const { tableChanges, setTableChanges } = useTableApplyContext();
  const schema = useContext(SchemaContext);
  const propertiesSchema = get(schema, 'properties.items', schema);
  const columnsProperties = propertiesSchema?.items?.properties || {};
  const fieldProperties = columnsProperties[fieldKey] || {};
  const inputsProperties = pickBy(
    columnsProperties,
    value => value.minimum || value.maximum
  );
  const min = fieldProperties?.minimum || 1;
  const max = fieldProperties?.maximum || 30;
  const rowPayloadValue = tableChanges.data[rowData.id];
  const prevValue = rowPayloadValue || rowData;
  const marginValue = prevValue[fieldKey];

  const patchApplyPayloadValue = marginValue => {
    setTableChanges(currentValue => {
      const tableFieldValue = {
        ...prevValue,
        [fieldKey]: marginValue,
      };

      const data = {
        ...currentValue.data,
        [rowData.id]: tableFieldValue,
      };
      const prevInputsValues = Object.keys(inputsProperties).reduce(
        (acc, curr) => {
          const isValid = checkMarginValid(
            tableFieldValue[curr],
            inputsProperties?.[curr]?.minimum || 1,
            inputsProperties?.[curr]?.maximum || 30
          );
          const value = isValid ? +tableFieldValue[curr] || null : undefined;

          return {
            ...acc,
            [curr]: value,
          };
        },
        {}
      );
      console.log({ inputsProperties, prevInputsValues });

      const payload = checkMarginValid(marginValue, min, max)
        ? {
            ...currentValue.payload,
            [rowData.id]: {
              ...prevValue,
              ...prevInputsValues,
              [fieldKey]: +marginValue || null,
            },
          }
        : { ...omitBy(currentValue.payload, value => value.id === rowData.id) };

      return {
        data,
        payload,
      };
    });
  };

  const [isMarginValid, setIsMarginValid] = useState(
    checkMarginValid(displayValue, min, max)
  );

  useEffect(() => {
    const numberValue = +marginValue;

    const isValid = checkMarginValid(numberValue, min, max);
    setIsMarginValid(isValid);
  }, [marginValue, min, max]);

  const onChange = e => {
    let inlineValue = e.target.value;

    // TODO: in some way after enter second digit after point '.' becomes ','. But this works fine
    inlineValue = inlineValue.replace(',', '.');

    const formattedValue = inlineValue.includes('.')
      ? inlineValue.split('.')[0] + '.' + inlineValue.split('.')[1][0]
      : inlineValue;

    patchApplyPayloadValue(formattedValue);
  };

  return {
    value: marginValue,
    onChange,
    isMarginValid,
    min,
    max,
  };
};
