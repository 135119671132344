import { gql } from '@apollo/client';

const APPLIED_COMMANDS_QUERY = gql`
  query getEventSettlementsAppliedCommands(
    $filters: SettlementsCommandsFilters!
  ) {
    data: getEventSettlementsAppliedCommands(filters: $filters) {
      items {
        timestamp
        provider
        type
        result
        void_factor
      }
      total
    }
  }
`;

export default APPLIED_COMMANDS_QUERY;
