import GamingActivityTable from './GamingActivityTable';
import { useQuery } from '@apollo/client';
import getGamingActivityList from '../../../../../gql/Reports/queries/getGamingActivityList';
import useTableOrderByChange from '../../../../../hooks/useTableOrderByChange';
import getOperatorGamingActivityList from '../../../../../gql/Reports/queries/getOperatorGamingActivityList';
import useIsOpen from '../../../../../../hooks/useIsOpen';
import { useIsOperator } from '../../../../../providers/OperatorProvider';
import ErrorWidget from '../../../../../components/ErrorWidget';
import React from 'react';
import { checkErrorsForWidget } from '../../../../../../utils/checkErrorsForWidget';

const GamingActivityL2 = ({ filters }) => {
  const isOperator = useIsOperator();
  const { orderBy, orderByAscDesc, onChangeOrderBy } = useTableOrderByChange();

  const {
    data: { data: gamingActivityData = {} } = {},
    loading,
    error,
  } = useQuery(
    isOperator ? getOperatorGamingActivityList : getGamingActivityList,
    {
      fetchPolicy: 'no-cache',
      variables: {
        inputFilters: filters,
        orderBy,
        orderByAscDesc,
      },
    }
  );

  if (checkErrorsForWidget([error])) {
    return <ErrorWidget />;
  }

  return (
    <GamingActivityTable
      filters={filters}
      data={gamingActivityData.items}
      orderBy={orderBy}
      onChangeOrderBy={onChangeOrderBy}
      summary={gamingActivityData.results && gamingActivityData.results[0]}
      loading={loading}
    />
  );
};

export default GamingActivityL2;
