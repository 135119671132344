import { useMutation, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import toNumber from 'lodash/toNumber';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useResizeObserver from 'use-resize-observer';
import useSearch from '../../../hooks/useSearch';
import getQuery from '../../../utils/getQuery';
import FancyAutocomplete from '../../components/Inputs/FancyAutocomplete/FancyAutocomplete';
import { MARKETS_QUERY } from '../../components/Inputs/RelatedContingencyAutocomplete/MarketAutocomplete';
import Select from '../../components/Inputs/Select/Select';
import Page from '../../components/Page/Page';
import { OrderDirection } from '../../components/Table/TableHeaderColumn';
import TableActionCards from '../../components/TableActionCards/TableActionCards';
import TablePagination from '../../components/TablePagination/TablePagination';
import UPDATE_EVENT_OUTCOMES from '../../gql/Settlements/mutations/UPDATE_EVENT_OUTCOMES';
import OUTCOMES_QUERY from '../../gql/Settlements/new/OUTCOMES_QUERY';
import useFilters from '../../hooks/useFilters';
import { withoutEmpty } from '../../hooks/usePresetFilters';
import useTableOrderByChange from '../../hooks/useTableOrderByChange';
import useTablePagination from '../../hooks/useTablePagination';
import ApolloClientProvider from '../../providers/ApolloClientProvider';
// import OutcomesTable, { allOptions } from './OutcomesTable';
import NewOutcomesTable, { allOptions } from './NewOutcomesTable';
import { tableNames } from '../../hooks/useLocalStorageTableConfig';
import ErrorWidget from '../../components/ErrorWidget';
import { checkErrorsForWidget } from '../../../utils/checkErrorsForWidget';
import useTableAnimatedRow from '../../hooks/useTableAnimatedRow';
import useTableExpandRows from '../../hooks/useTableExpandRows';
import TableExpandRowProvider from '../../providers/TableExpandRowProvider';
import TableAnimatedRowProvider from '../../providers/TableAnimatedRowProvider';

const defaultFilters = {
  markets_id: [],
  result: null,
};

const OutcomesPage = ({}) => {
  const { eventId } = useParams();

  const search = useSearch();
  const jsonQuery = getQuery(search);
  const { eventName, sportName } = jsonQuery.eventDetails;

  const { limit, setLimit, offset, setOffset } = useTablePagination({
    tableName: tableNames.Outcomes,
    onChangeLimit: () => {},
  });

  const { orderBy, orderByAscDesc, onChangeOrderBy } = useTableOrderByChange();

  const refetchOutcomesRef = useRef(() => {});
  const actionCardsRef = useRef(null);
  const tableRef = useRef(null);

  useResizeObserver({
    ref: actionCardsRef,
    onResize: rect => {
      setTableOffsetTop(rect.height);
    },
  });

  const refetchOutcomes = useCallback(() => {
    return refetchOutcomesRef.current();
  }, []);

  const handleResubmit = useCallback(() => {
    refetchOutcomes();
  }, [refetchOutcomes]);

  const { filtersCard, filters } = useFilters({
    tableName: tableNames.Outcomes,
    resetOffset: setOffset,
    onResubmit: handleResubmit,
    defaultValues: defaultFilters,
    filters: [
      {
        name: 'markets_id',
        label: 'Market',
        fullWidth: false,
        component: (
          <FancyAutocomplete
            gqlQuery={MARKETS_QUERY}
            multiple={true}
            proxyValue
          />
        ),
        toggled: true,
      },
      {
        name: 'result',
        label: 'Result',
        fullWidth: false,
        component: <Select options={allOptions} />,
        toggled: true,
      },
    ],
  });

  const [tableOffsetTop, setTableOffsetTop] = useState(0);

  const queryFilters = useMemo(() => {
    return {
      ...withoutEmpty(filters),
      limit,
      offset,
      order_by: orderBy,
      order_by_asc_desc: orderByAscDesc,
    };
  }, [filters, limit, offset, orderBy, orderByAscDesc]);

  const { data, loading, refetch, error } = useQuery(OUTCOMES_QUERY, {
    variables: {
      eventId,
      filters: queryFilters,
    },
  });

  const items = useMemo(() => {
    return (data && data.data && data.data.items) || [];
  }, [data]);

  const [update, { loading: formLoading, error: updateError }] = useMutation(
    UPDATE_EVENT_OUTCOMES
  );

  const onUpdate = useCallback(
    (
      { event_id, market_id, outcome_id, specifiers, result, dead_heat_factor },
      callback
    ) => {
      return update({
        variables: {
          input: {
            event_id,
            market_id,
            outcome_id,
            specifiers,
            result,
            ...(dead_heat_factor
              ? { dead_heat_factor: toNumber(dead_heat_factor) }
              : {}),
          },
        },
      }).then(callback);
    },
    [update]
  );

  refetchOutcomesRef.current = refetch;

  const total = useMemo(() => {
    return (data && data.data && data.data.total) || [];
  }, [data]);

  const actions = useMemo(() => {
    return [filtersCard];
  }, [filtersCard]);

  if (checkErrorsForWidget([error, updateError])) {
    return <ErrorWidget />;
  }

  const animateRows = useTableAnimatedRow();
  const tableExpandRows = useTableExpandRows();

  return (
    <Page title={'Outcomes'}>
      <ApolloClientProvider
        endpoints={{
          rm: '/api/v2/risk-management',
        }}
        defaultEndpoint={'rm'}
        key={'outcomePageTableActions'}
        allowSubscription={false}
      >
        <TableActionCards animated actions={actions} ref={actionCardsRef} />
      </ApolloClientProvider>

      <Box mt={2}>
        <TableAnimatedRowProvider value={animateRows}>
          <TableExpandRowProvider value={tableExpandRows}>
            <NewOutcomesTable
              ref={tableRef}
              loading={loading}
              items={items}
              tableOffsetTop={tableOffsetTop}
              eventName={eventName}
              sportName={sportName}
              onUpdate={onUpdate}
              initialOrderDirection={OrderDirection.DESC}
              orderBy={orderBy}
              onChangeOrderBy={onChangeOrderBy}
            />
          </TableExpandRowProvider>
        </TableAnimatedRowProvider>
        {/*<OutcomesTable*/}
        {/*  loading={loading}*/}
        {/*  items={items}*/}
        {/*  tableOffsetTop={tableOffsetTop}*/}
        {/*  eventName={eventName}*/}
        {/*  sportName={sportName}*/}
        {/*  onUpdate={onUpdate}*/}
        {/*  initialOrderDirection={OrderDirection.DESC}*/}
        {/*  orderBy={orderBy}*/}
        {/*  onChangeOrderBy={onChangeOrderBy}*/}
        {/*/>*/}
      </Box>
      <TablePagination
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        totalRows={total}
      />
    </Page>
  );
};

export default OutcomesPage;
